import { Type } from "@sinclair/typebox";
import { ElphiEntityType, RuleNodeType, ValueNodeType } from "../entities";
import { BaseEntitySchema } from "./baseEntity.typebox";
import { OmitUpdateSchema } from "./service.typebox";
import {
  ArrayField,
  EnumField,
  StringField,
  TrueFalseField
} from "./utils.typebox";

enum AndOr {
  And = "and",
  Or = "or"
}

enum Operation {
  Equal = "==",
  LessThanEqual = "<=",
  LessThan = "<",
  GreaterThan = ">",
  GreaterThanEqual = ">=",
  NotEqual = "!=",
  Includes = "includes",
  IncludesAny = "includes-any",
  NotIncludes = "not-includes"
}

const RightLeftNodeFieldsSchema = Type.Object(
  {
    type: EnumField(RuleNodeType),
    valueType: EnumField(ValueNodeType),
    value: StringField,
    path: Type.Optional(StringField),
    entityType: Type.Optional(EnumField(ElphiEntityType))
  },
  {
    default: {
      type: RuleNodeType.Value,
      valueType: ValueNodeType.String,
      value: "value_string",
      path: "path_string",
      entityType: ElphiEntityType.deal
    }
  }
);

const RuleNodeFieldsSchema = Type.Object({
  type: EnumField(RuleNodeType),
  condition: EnumField(AndOr),
  operation: EnumField(Operation),
  right: RightLeftNodeFieldsSchema,
  left: RightLeftNodeFieldsSchema,
  children: ArrayField(
    Type.Object(
      {
        type: EnumField(RuleNodeType),
        operation: EnumField(Operation),
        left: RightLeftNodeFieldsSchema,
        right: RightLeftNodeFieldsSchema
      },
      {
        default: {
          type: RuleNodeType.Value,
          operation: Operation.Equal,
          left: RightLeftNodeFieldsSchema.default,
          right: RightLeftNodeFieldsSchema.default
        }
      }
    )
  )
});

const IncludeEntitiesSchema = Type.Object({
  deal: TrueFalseField,
  property: TrueFalseField,
  party: TrueFalseField,
  dealProperty: TrueFalseField,
  dealParty: TrueFalseField,
  partyRelation: TrueFalseField
});

const RuleTemplateFieldsSchema = Type.Object({
  ruleName: StringField,
  rule: RuleNodeFieldsSchema,
  includeEntities: IncludeEntitiesSchema
});

export const RuleTemplateUpdate = Type.Intersect([
  OmitUpdateSchema(BaseEntitySchema),
  Type.Required(RuleTemplateFieldsSchema)
]);
