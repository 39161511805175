export enum PropertyType {
  SingleFamily = "Single Family",
  Townhome = "Townhome",
  Condominium = "Condominium",
  N24Unit = "2-4 Unit",
  N59UnitMultifamily = "5-9 Unit Multifamily"
}

export enum PropertyLoanPurpose {
  Purchase = "Purchase",
  Refinance = "Refinance",
  DelayedPurchase = "Delayed Purchase"
}

export enum LeaseStatusType {
  Vacant = "Vacant",
  StableLeased = "Stable Leased",
  UnstableLeased = "Unstable Leased"
}

export enum AppraisalValueDerivationType {
  OneSubjectToValuationWithTwoValues = "One Subject To Valuation With Two Values",
  OneSubjectToValuationWithStandaloneAsIsValuation = "One Subject To Valuation With Standalone As Is Valuation",
  OneAsIsValuation = "One As Is Valuation",
  HighValuePropertyWithTwoValuations = "High Value Property With Two Valuations"
}

export enum LeaseStrategyType {
  ShortTerm = "Short Term",
  Permanent = "Permanent"
}

export enum ConstructionBudgetAssessmentType {
  StandardRehab = "Standard Rehab",
  HighRiskProject = "High Risk Project",
  NewConstruction = "New Construction",
  MidConstruction = "Mid Construction"
}

export enum QualityRatingType {
  Q1 = "Q1",
  Q2 = "Q2",
  Q3 = "Q3",
  Q4 = "Q4",
  Q5 = "Q5",
  Q6 = "Q6",
  Average = "Average",
  CBBrkShingle = "CBBrk/Shingle",
  CBFrmMetal = "CBFrm/Metal"
}

export enum ConditionRatingType {
  C1 = "C1",
  C2 = "C2",
  C3 = "C3",
  C4 = "C4",
  C5 = "C5",
  Poor = "Poor",
  Fair = "Fair",
  Average = "Average",
  Good = "Good",
  Excellent = "Excellent"
}

export enum AppraisalType {
  N1004D = "1004D",
  N1004 = "1004",
  N1025 = "1025",
  N1073 = "1073",
  N2055 = "2055",
  N5UnitNarrative = "5+ Unit Narrative",
  HybridExterior = "Hybrid Exterior",
  HybridExteriorWithARV = "Hybrid Exterior With ARV",
  HybridExteriorWithRental = "Hybrid Exterior With Rental",
  HybridInterior = "Hybrid Interior",
  HybridInteriorWithARV = "Hybrid Interior With ARV",
  HybridInteriorWithRental = "Hybrid Interior With Rental",
  LimaOneInternalValuation = "Lima One Internal Valuation"
}

export enum PropertyZoningComplianceRatingType {
  Legal = "Legal",
  LegalNonconformingGrandfatheredUse = "Legal Nonconforming (Grandfathered Use)",
  NoZoning = "No Zoning",
  Illegal = "Illegal"
}

export enum FloodCertificationActionType {
  LifeOfLoanDeterminationWithHMDAInformation = "Life Of Loan Determination With HMDA Information"
}

export enum FloodInsuranceRequiredStatusType {
  Required = "Required",
  NotRequired = "Not Required",
  Indeterminate = "Indeterminate"
}

export enum FloodZoneDesignationType {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  V = "V",
  X = "X",
  Y = "Y",
  XShaded = "XShaded",
  AE = "AE",
  A1 = "A1",
  A2 = "A2",
  A3 = "A3",
  A4 = "A4",
  A5 = "A5",
  A6 = "A6",
  A7 = "A7",
  A8 = "A8",
  A9 = "A9",
  A10 = "A10",
  A11 = "A11",
  A12 = "A12",
  A13 = "A13",
  A14 = "A14",
  A15 = "A15",
  A16 = "A16",
  A17 = "A17",
  A18 = "A18",
  A19 = "A19",
  A20 = "A20",
  A21 = "A21",
  A22 = "A22",
  A23 = "A23",
  A24 = "A24",
  A25 = "A25",
  A26 = "A26",
  A27 = "A27",
  A28 = "A28",
  A29 = "A29",
  A30 = "A30",
  AH = "AH",
  AO = "AO",
  AR = "AR",
  A99 = "A99",
  VE = "VE",
  V1 = "V1",
  V2 = "V2",
  V3 = "V3",
  V4 = "V4",
  V5 = "V5",
  V6 = "V6",
  V7 = "V7",
  V8 = "V8",
  V9 = "V9",
  V10 = "V10",
  V11 = "V11",
  V12 = "V12",
  V13 = "V13",
  V14 = "V14",
  V15 = "V15",
  V16 = "V16",
  V17 = "V17",
  V18 = "V18",
  V19 = "V19",
  V20 = "V20",
  V21 = "V21",
  V22 = "V22",
  V23 = "V23",
  V24 = "V24",
  V25 = "V25",
  V26 = "V26",
  V27 = "V27",
  V28 = "V28",
  V29 = "V29",
  V30 = "V30"
}

export enum InspectionInitialDelayReasonStatusType {
  AwaitingApproval = "Awaiting Approval",
  AwaitingDocuments = "Awaiting Documents",
  ChangingSOW = "Changing SOW",
  IncompleteMissingBudgetItems = "Incomplete/Missing Budget Items",
  InspectionDelay = "Inspection Delay",
  AccessIssues = "Access Issues",
  RevisionsWithClient = "Revisions With Client",
  StructuralReportNeeded = "Structural Report Needed",
  WellSepticReportNeeded = "Well/Septic Report Needed"
}

export enum PropertyRightsOwnershipType {
  FeeSimple = "Fee Simple",
  Leasehold = "Leasehold"
}

export enum BillingFrequencyType {
  Annual = "Annual",
  Semiannual = "Semiannual",
  Quarterly = "Quarterly",
  Other = "Other"
}

export enum InspectionOrderType {
  DrawInspection = "Draw Inspection",
  InitialInspection = "Initial Inspection",
  MarkCompleteModeWorkorder = "Mark Complete Mode Workorder",
  MFBudgetReview = "MF-Budget Review",
  PostCrisisInspection = "Post - Crisis Inspection",
  SelfInspectionDrawRequest = "Self Inspection - Draw Request",
  StatusInspection = "Status Inspection",
  ValuationPropertyConditionReport = "Valuation Property Condition Report"
}

export enum AppraisalCoordinatorType {
  AlyssaBuker = "Alyssa Buker",
  AmberBeenken = "Amber Beenken",
  KalynDixon = "Kalyn Dixon",
  KeaneHarris = "Keane Harris",
  PeytonLemen = "Peyton Lemen"
}

export enum PropertyInsurancePremiumPaymentType {
  PaidOutsideClosing = "Paid Outside Closing",
  PaidAtClosing = "Paid at Closing"
}

export enum FloodInsurancePremiumPaymentType {
  PaidInFull = "Paid in Full",
  PaidAfterClosing = "New Construction - Paid After Closing"
}
export enum ServiceLinkOrderStatusType {
  Completed = "Completed",
  Open = "Open",
  A1 = "A1",
  A2 = "A2",
  A3 = "A3",
  NoHit = "NoHit"
}
export enum ServiceLinkOrderStatusShortType {
  Completed = "C",
  Open = "O",
  A1 = "A1",
  A2 = "A2",
  A3 = "A3",
  NoHit = "NH"
}
export enum ServiceLinkOrderStatusToLabel {
  C = "Completed",
  O = "Open",
  A1 = "A1",
  A2 = "A2",
  A3 = "A3",
  NH = "NoHit"
}

export enum FloodCheckStatus {
  Yes = "Yes",
  No = "No",
  Indeterminate = "Indeterminate"
}
export enum FloodCheckStatusShortType {
  Yes = "Y",
  No = "N",
  Indeterminate = "U"
}
