import { InsurancePolicyCoverages, InsurancePolicyView } from "@elphi/types";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../../config/appConfig";
import { serviceBuilder } from "../../builders/api.builder";
import { insurancePolicyViewAdapter } from "./insurancePolicyView.adapter";

type InsurancePolicyViewRequest = {
  entityId?: string;
  dealId?: string;
};

type InsurancePolicyViewResponse = {
  result: InsurancePolicyView<InsurancePolicyCoverages>[];
};

const baseInsurancePolicyViewApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "insurancePolicyViewApi",
  tagTypes: ["insurancePolicy", "Paginate", "View"],
  baseQuery: serviceBuilder.baseQuery({
    route: "insurance-policy"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: insurancePolicyViewAdapter
  })
});

export const insurancePolicyViewApi =
  baseInsurancePolicyViewApi.injectEndpoints({
    endpoints: (builder) => ({
      getAll: builder.query<
        InsurancePolicyViewResponse,
        InsurancePolicyViewRequest
      >({
        query: (r) => {
          const params: string[] = [];
          r.entityId && params.push(`entityId=${r.entityId}`);
          r.dealId && params.push(`dealId=${r.dealId}`);
          const url = `/views?${params.join("&")}`;
          return {
            url,
            method: "GET"
          };
        }
      })
    })
  });
