import {
  BranchServiceProvider,
  BranchServiceProviderFields,
  CompanyServiceProvider,
  CompanyServiceProviderFields,
  RepresentativeServiceProvider,
  RepresentativeServiceProviderFields,
  RolodexBranchRepresentativeRelation,
  RolodexEntityRelation,
  RolodexServiceProvider,
  ServiceProviderEntityType
} from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../../config/appConfig";
import { EMPTY } from "../../../../constants/common";
import { serviceBuilder } from "../../builders/api.builder";
import { branchRepRelationAdapter } from "../branch-rep-relation/branchRepRelation.adapter";
import { serviceProviderAdapter } from "./serviceProvider.adapter";

export type SearchProviderRequest<Params extends object> = {
  query: string;
  cursor?: string;
} & Params;

export type SearchProviderResponse<Params extends object> = {
  results: EntityState<RolodexServiceProvider>;
  hasMore: boolean;
  nextCursor: string | null;
  query: string;
} & Params;

export type CompanySearchRequestParams = Pick<
  CompanyServiceProvider,
  "domainConfigurationId"
>;

export type BranchSearchRequestParams = Pick<
  BranchServiceProvider,
  "companyId"
>;

export type RepSearchRequestParams = Pick<
  RepresentativeServiceProvider,
  "companyId"
>;

export type CompanyServiceProviderRequest = Partial<
  Pick<CompanyServiceProvider, "id"> & CompanyServiceProviderFields
>;

export type BranchServiceProviderRequest = Partial<
  Pick<BranchServiceProvider, "id" | "companyId"> & BranchServiceProviderFields
>;

export type RepServiceProviderRequest = Partial<
  Pick<RepresentativeServiceProvider, "id" | "companyId" | "skipped"> &
    RepresentativeServiceProviderFields
>;

export type RolodexServiceProviderRequest = {
  dealId: string;
  partyId?: string;
  company: CompanyServiceProviderRequest;
  branch: BranchServiceProviderRequest;
  rep: RepServiceProviderRequest;
  domainConfigurationId?: string;
  entityType: ServiceProviderEntityType;
} & RolodexEntityRelation;

export type CopyValuesToSystemRequest = {
  domainConfigurationId: string;
  companyId: string;
  branchId: string;
  representativeId: string;
  dealId: string;
};

export const baseServiceProviderApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "serviceProviderApi",
  tagTypes: ["ServiceProvider"],
  baseQuery: serviceBuilder.baseQuery({
    route: "rolodex"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: serviceProviderAdapter
  })
});

export const serviceProviderApi = baseServiceProviderApi.injectEndpoints({
  endpoints: (builder) => ({
    upsert: builder.mutation<
      { companyId: string; branchId: string; representativeId: string },
      Partial<RolodexServiceProviderRequest>
    >({
      query: (r) => {
        return {
          url: `/upsert`,
          method: "POST",
          body: r
        };
      }
    }),
    searchCompany: builder.query<
      SearchProviderResponse<CompanySearchRequestParams>,
      SearchProviderRequest<CompanySearchRequestParams>
    >({
      query: (r) => {
        return {
          url: `/searchCompany?query=${r.query}&domainConfigurationId=${
            r.domainConfigurationId
          }${r.cursor ? `&cursor=${r.cursor}` : EMPTY}`,
          method: "GET"
        };
      },
      transformResponse: (
        response: {
          results: RolodexServiceProvider[];
          hasMore: boolean;
          nextCursor: string | null;
        },
        _,
        arg
      ) => {
        {
          const adapter = serviceProviderAdapter.addMany(
            serviceProviderAdapter.getInitialState(),
            response.results
          );
          return {
            domainConfigurationId: arg.domainConfigurationId,
            query: arg.query,
            results: adapter,
            hasMore: response.hasMore,
            nextCursor: response.nextCursor
          };
        }
      }
    }),
    searchBranch: builder.query<
      SearchProviderResponse<BranchSearchRequestParams>,
      SearchProviderRequest<BranchSearchRequestParams>
    >({
      query: (r) => {
        return {
          url: `/searchBranch?query=${r.query}&companyId=${r.companyId}${
            r.cursor ? `&cursor=${r.cursor}` : EMPTY
          }`,
          method: "GET"
        };
      },
      transformResponse: (
        response: {
          results: RolodexServiceProvider[];
          hasMore: boolean;
          nextCursor: string | null;
        },
        _,
        arg
      ) => {
        {
          const adapter = serviceProviderAdapter.addMany(
            serviceProviderAdapter.getInitialState(),
            response.results
          );
          return {
            companyId: arg.companyId,
            query: arg.query,
            results: adapter,
            hasMore: response.hasMore,
            nextCursor: response.nextCursor
          };
        }
      }
    }),
    searchRep: builder.query<
      SearchProviderResponse<RepSearchRequestParams>,
      SearchProviderRequest<RepSearchRequestParams>
    >({
      query: (r) => {
        return {
          url: `/searchRep?query=${r.query}&companyId=${r.companyId}${
            r.cursor ? `&cursor=${r.cursor}` : EMPTY
          }`,
          method: "GET"
        };
      },
      transformResponse: (
        response: {
          results: RolodexServiceProvider[];
          hasMore: boolean;
          nextCursor: string | null;
        },
        _,
        arg
      ) => {
        {
          const adapter = serviceProviderAdapter.addMany(
            serviceProviderAdapter.getInitialState(),
            response.results
          );
          return {
            companyId: arg.companyId,
            query: arg.query,
            results: adapter,
            hasMore: response.hasMore,
            nextCursor: response.nextCursor
          };
        }
      }
    }),
    removeDealRelation: builder.mutation<
      { id: string },
      {
        companyId: string;
        branchId: string;
        representativeId: string;
        dealId: string;
      }
    >({
      query: (r) => {
        return {
          url: `/remove-deal-relation`,
          method: "POST",
          body: r
        };
      }
    }),
    removePartyRelationMutation: builder.mutation<
      { id: string },
      {
        companyId: string;
        branchId: string;
        representativeId: string;
        partyId: string;
      }
    >({
      query: (r) => {
        return {
          url: `/remove-party-relation`,
          method: "POST",
          body: r
        };
      }
    }),
    getBatch: builder.query<
      { batch: EntityState<RolodexServiceProvider> },
      string[]
    >({
      query: (ids) => {
        return {
          url: `/get-batch`,
          method: "POST",
          body: { ids }
        };
      },
      transformResponse: (response: { results: RolodexServiceProvider[] }) => {
        const adapter = serviceProviderAdapter.addMany(
          serviceProviderAdapter.getInitialState(),
          response.results
        );

        return { batch: adapter };
      }
    }),
    batchUpdate: builder.mutation<
      { batch: string[] },
      { providers: ({ id: string } & Partial<RolodexServiceProvider>)[] }
    >({
      query: (r) => {
        return {
          url: `/update-batch`,
          method: "PUT",
          body: r
        };
      }
    }),
    search: builder.query<
      {
        results: EntityState<RolodexServiceProvider>;
        hasMore: boolean;
        nextCursor: string | null;
        query: string;
      },
      { query: string; cursor?: string }
    >({
      query: (r) => {
        return {
          url: `/search?q=${r.query}${r.cursor ? `&cursor=${r.cursor}` : ""}`,
          method: "GET"
        };
      },
      transformResponse: (
        response: {
          results: RolodexServiceProvider[];
          hasMore: boolean;
          nextCursor: string | null;
        },
        _,
        arg
      ) => {
        {
          const adapter = serviceProviderAdapter.addMany(
            serviceProviderAdapter.getInitialState(),
            response.results
          );
          return {
            query: arg.query,
            results: adapter,
            hasMore: response.hasMore,
            nextCursor: response.nextCursor
          };
        }
      }
    }),
    getTree: builder.query<
      {
        serviceProviders: EntityState<RolodexServiceProvider>;
        relations: EntityState<RolodexBranchRepresentativeRelation>;
      },
      { id: string }
    >({
      query: ({ id }) => {
        return {
          url: `/get-tree?id=${id}`,
          method: "GET"
        };
      },
      transformResponse: (response: {
        serviceProviders: RolodexServiceProvider[];
        relations: RolodexBranchRepresentativeRelation[];
      }) => {
        const adapter = serviceProviderAdapter.addMany(
          serviceProviderAdapter.getInitialState(),
          response.serviceProviders
        );
        const relationAdapter = branchRepRelationAdapter.addMany(
          branchRepRelationAdapter.getInitialState(),
          response.relations
        );
        return { serviceProviders: adapter, relations: relationAdapter };
      }
    }),
    cascadeDelete: builder.query<
      {
        serviceProviders: EntityState<RolodexServiceProvider>;
        relations: EntityState<RolodexBranchRepresentativeRelation>;
      },
      { id: string; preprocess: boolean }
    >({
      query: (r) => {
        return {
          url: `/cascade-delete`,
          method: "POST",
          body: r
        };
      },
      transformResponse: (response: {
        serviceProviders: RolodexServiceProvider[];
        relations: RolodexBranchRepresentativeRelation[];
      }) => {
        const serviceProvidersAdapter = serviceProviderAdapter.addMany(
          serviceProviderAdapter.getInitialState(),
          response.serviceProviders
        );
        const relationAdapter = branchRepRelationAdapter.addMany(
          branchRepRelationAdapter.getInitialState(),
          response.relations
        );
        return {
          relations: relationAdapter,
          serviceProviders: serviceProvidersAdapter
        };
      }
    }),
    copyValuesToSystem: builder.mutation<
      { dealId: string },
      CopyValuesToSystemRequest
    >({
      query: (r) => {
        return {
          url: `/system-values`,
          method: "PUT",
          body: r
        };
      }
    })
  })
});
