import Docxtemplater from "docxtemplater";
import { Deal } from "./deal.types";
import { EntityParty, IndividualParty } from "./party.types";
import { Property } from "./property.types";

export enum DocxtemplaterType {
  MainTemplate = "main-template",
  SubTemplate = "sub-template"
}

export type DocumentGenerationPayloadFields = {
  Deal: DocumentDeal;
  Properties: DocumentProperty[];
  PropertyAddresses: string;
  IndividualParties: IndividualParty[];
  EntityParties: EntityParty[];
  IndividualNames: string;
  IndividualAddresses: string;
  EntityNames: string;
  IndividualSignatureBlock: IndividualSignatureBlock[];
  SignatureLine: string;
  MortgageeClauseSection: Docxtemplater;
  NotarySignatureSection: Docxtemplater;
  SelectedProperty: DocumentProperty;
  SelectedIndividual: IndividualDocumentParty;
  SelectedEntity: EntityDocumentParty;
  AssignedUsers: string;
  AssignedUserEmails: string;
  PrimaryBorrowers: DocumentParty[];
  Borrowers: DocumentParty[];
  PrimarySponsors: DocumentParty[];
  Sponsors: DocumentParty[];
  Guarantors: DocumentParty[];
  Signatories: DocumentParty[];
  Applicants: DocumentParty[];
  GuarantorSignatureBlock: EntitySignatureBlock[];
  SignatorySignatureBlock: EntitySignatureBlock[];
  GuarantorNames: string;
  GuarantorAddresses: string;
  BorrowerNames: string;
  BorrowerNamesWithStateEntityType: string;
  BorrowerAddresses: string;
  StateCountyProperties: DocumentProperty[];
  StateCountyPropertyAddresses: string;
  TodayDate: string;
  PropertyParcelNumbers: string;
  StateCountyPropertyParcelNumbers: string;
};

export type DocumentGenerationPayload =
  Partial<DocumentGenerationPayloadFields>;

export type IndividualSignatureBlock = {
  IndividualName: string;
  SignatureLine: string;
};

export type EntitySignatureBlock = {
  EntityId: string;
  EntityName: string;
  EntityNameAndType: string;
  SignatureLine: string;
  IndividualName: string;
  IndividualNameAndTitle: string;
};

export enum MortageOrDeedOfTrust {
  Mortgage = "Mortgage",
  DeedOfTrust = "Deed of Trust"
}

export type DocumentProperty = Omit<Property, "aggregations"> & {
  MortgageOrDeedOfTrust: MortageOrDeedOfTrust;
  aggregations?: object;
};

export type DocumentDeal = Omit<Deal, "aggregations" | "quote"> & {
  SimpleInterestPaymentAmount: string;
  PerDiemChargeAmount: string;
  PrepaymentPenaltyText: string;
  ClosingAnalyst: string;
  ClosingAnalystTitle: string;
  ClosingAnalystEmail: string;
  Notary: string;
  NotaryTitle: string;
  NotaryEmail: string;
  aggregations: object;
  quote: { aggregations: object };
  Lender: {
    ReturnAddresseeName: string;
    ReturnCO: string;
    ReturnMS: string;
    ReturnFullAddressName: string;
  };
};

export type EntityDocumentParty = Omit<EntityParty, "aggregations"> & {
  Title: string;
  aggregations?: object;
};
export type IndividualDocumentParty = Omit<IndividualParty, "aggregations"> & {
  Title: string;
  aggregations?: object;
};
export type DocumentParty = EntityDocumentParty | IndividualDocumentParty;

export enum DocumentGenerationTemplate {
  ConnecticutNotary = "Notary - Connecticut",
  FloridaNotary = "Notary - Florida",
  GeorgiaNotary = "Notary - Georgia",
  LouisianaNotary = "Notary - Louisiana",
  MaineNotary = "Notary - Maine",
  MarylandNotary = "Notary - Maryland",
  MichiganNotary = "Notary - Michigan",
  MinnesotaNotary = "Notary - Minnesota",
  NewJerseyNotary = "Notary - New Jersey",
  OhioNotary = "Notary - Ohio",
  OtherStatesNotary = "Notary - All Other States",
  SouthCarolinaNotary = "Notary - South Carolina",
  VirginiaNotary = "Notary - Virginia",
  Rental30LRF = "Rental30 LRF",
  Rental30ClosingInstructions = "Rental30 Closing Instructions",
  ShortTermClosingInstructions = "Short Term Closing Instructions",
  ShortTermLRF = "Short Term LRF",
  Demo = "Demo",
  ACHPaymentForm = "ACH Payment Form",
  FixNFlipPortfolioAffidavitOfOccupancy = "FixNFlip Portfolio Affidavit Of Occupancy",
  ShortTermCollateralizationAgreement = "Short Term Cross Collateralization Agreement",
  AffidavitOfTitlePortfolio = "Affidavit of Title",
  CommercialGuaranty = "Commercial Guaranty",
  ShortTermCashoutCommercialPromissoryNote = "Short Term Commercial Promissory Note - Cashout",
  GeorgiaDeedOfTrust = "Short Term Deed to Secure Debt - GA",
  ShortTermDeedOfTrust = "Short Term Deed of Trust",
  DocumentReExecution = "Document Re-Execution Agreement",
  EntityCertification = "Entity Certification",
  ShortTermLenderAffidavit = "Short Term Lender Affidavit - Maryland",
  ShortTermNonCommitmentPortfolioLoanAgreement = "Short Term Loan Agreement - Non-Commitment",
  ShortTermMortgage = "Short Term Mortgage",
  NewYorkTaxAffidavit = "New York Tax Affidavit",
  PostClosingLetter = "Post Closing Letter",
  Rental30SingleAssetAffidavitOfOccupancy = "Rental30 Single Asset Affidavit Of Occupancy",
  Rental30PortfolioAffidavitOfTitle = "Rental30 Portfolio Affidavit Of Title",
  Rental30PortfolioAffidavitOfOccupancy = "Rental30 Affidavit of Occupancy",
  Rental30PortfolioBusinessPurposeAffidavit = "Rental30 Business Purpose Affidavit",
  Rental30AssignmentOfLeasesAndRents = "Rental30 Assignment Of Leases And Rents",
  Rental30BusinessPurposeAffidavit = "Rental30 Business Purpose Affidavit",
  ShortTermCertificateOfBorrowerAndGuarantor = "Short Term Certificate of Borrower and Guarantor",
  Rental30CertificateOfCommercialLoan = "Rental30 Certificate Of Commercial Loan",
  Rental30ReExecutionAgreement = "Rental30 Re-Execution Agreement",
  Rental30EnvironmentalIndemnity = "Rental30 Environmental Indemnity",
  Rental30Guaranty = "Rental30 Guaranty",
  Rental30LoanAgreement = "Rental30 Loan Agreement",
  Rental30N51ARM101ARMPromissoryNote = "Rental30 5/1 and 10/1 ARM Promissory Note",
  Rental30N5IO25ARMPromissoryNote = "Rental30 5 IO - 25 ARM Promissory Note",
  Rental30FixedPromissoryNote = "Rental30 Fixed Promissory Note",
  Rental30GeorgiaPortfolioSecurityInstrument = "Rental30 Deed to Secure Debt - GA",
  Rental30Mortgage = "Rental30 Mortgage",
  Rental30DeedOfTrust = "Rental30 Deed of Trust",
  ShortTermBusinessPurposeAffidavit = "Short Term Business Purpose Affidavit",
  ShortTermLoanAssignmentOfLeasesAndRents = "Short Term Assignment of Leases and Rents",
  ShortTermAssignmentOfLicenses = "Short Term Assignment of Licenses",
  TermLoanCertificationOfCommercialLoan = "Term Loan Certification Of Commercial Loan",
  ShortTermEnvironmentalIndemnity = "Short Term Environmental Indemnity",
  UnanimousWrittenConsent = "Unanimous Written Consent",
  Rental30MortgageMI = "Rental30 Mortgage - MI",
  Rental30DeedOfTrustTN = "Rental30 Deed of Trust - TN"
}
