import {
  LoanpassConfigurationsResponse,
  MappingRequest,
  MappingResponse,
  PipelineRecordSearchPaginationResponse
} from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { keyBy, keys } from "lodash";
import { AppConfig } from "../../../../config/appConfig";
import { serviceBuilder } from "../../builders/api.builder";

export const loanpassApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "loanpassApi",
  tagTypes: ["Loanpass", "Integration"],
  baseQuery: serviceBuilder.baseQuery({
    route: "/integration/pricing/loanpass"
  }),
  endpoints: (builder) => ({
    getConfigurations: builder.query<LoanpassConfigurationsResponse, void>({
      query: () => {
        return {
          url: `/configurations`,
          method: "GET"
        };
      }
    }),
    getPipelineRecords: builder.query<LoanpassConfigurationsResponse, void>({
      query: () => {
        return {
          url: `/pipeline-records`,
          method: "GET"
        };
      }
    }),
    search: builder.query<
      {
        query: string;
        hasMore: boolean;
        nextCursor: string;
        results: EntityState<{ name: string; id: string }>;
      },
      { query: string; cursor?: string }
    >({
      query: (r) => {
        return {
          url: `/pipeline-records/search?query=${r.query}${
            r.cursor ? `&cursor=${r.cursor}` : ""
          }`,
          method: "GET"
        };
      },
      transformResponse: (
        response: PipelineRecordSearchPaginationResponse,
        _,
        arg
      ): {
        query: string;
        hasMore: boolean;
        nextCursor: string;
        results: EntityState<{ name: string; id: string }>;
      } => {
        const entities = keyBy(response.results, "id");
        const ids = keys(entities);
        const results: EntityState<{ name: string; id: string }> = {
          ids,
          entities
        };

        return {
          query: arg.query,
          results,
          nextCursor: response.nextCursor,
          hasMore: response.hasMore
        };
      }
    }),
    mapFields: builder.query<MappingResponse, MappingRequest>({
      query: (r) => {
        return {
          url: `/mappings/mapper`,
          method: "POST",
          body: r
        };
      }
    })
  })
});
