import { Property } from "@elphi/types";
import { Dispatch, EntityId, Update } from "@reduxjs/toolkit";
import { propertySlice } from "./property.slice";

import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";

export const propertyAction: ActionDispatcher<Property> = (
  dispatch: Dispatch,
  callback?: {
    add?: (r: Property[]) => void;
    update?: (r: Update<Property>[]) => void;
    remove?: (r: EntityId[]) => void;
  }
) => {
  const slice = propertySlice;
  const actionDispatcher = buildAction(slice);
  return actionDispatcher(dispatch, callback);
};
