import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure
} from "@chakra-ui/react";
import {
  Deal,
  DocumentConfiguration,
  DocumentEntityType,
  DocumentRuleTemplate,
  OrderItems,
  Party,
  Property,
  RulesEngineDecision
} from "@elphi/types";
import { WritableDraft } from "immer/dist/internal";
import { concat, intersection, orderBy } from "lodash";
import { useMemo } from "react";
import { elphiTheme } from "../../assets/themes/elphi.theme.default";
import { ButtonIconViewSummary } from "../../components/button-icon/ButtonIcon";
import { useDealHooks } from "../../hooks/deal.hooks";
import { useDocumentConfigurationHooks } from "../../hooks/documentConfiguration.hooks";
import { useDocumentPackageOrderHooks } from "../../hooks/documentPackageOrder.hooks";
import { useDocumentRuleHooks } from "../../hooks/documentRule.hooks";
import { usePartyHooks } from "../../hooks/party.hooks";
import { usePropertyHooks } from "../../hooks/property.hooks";
import useWindowDimensions from "../../hooks/windowDimensions";
import { propertyStateCounty } from "../property/utils/printUtils";
import { ElphiTable } from "../table/ElphiTableComponent";
import { FilterCellComponent } from "../table/FilterCellComponent";
import {
  CellFilterField,
  CellsFilterStateFlat,
  useFilterHook
} from "../table/hooks/filter.hook";
import { ElphiCellType, ElphiTableProps } from "../table/table.types";
import { getPartyName, getPropertyAddress } from "../task/taskPrint.utils";

const statusToColor = {
  Passed: "green.200",
  [RulesEngineDecision.Match]: "green.200",
  [RulesEngineDecision.NoMatch]: "orange.300",
  [RulesEngineDecision.Failed]: "red.300"
};

const getEntityText = (rowData: OrderItemView): string | null => {
  let entityText: string | null = null;
  const documentEntity = rowData?.documentEntity;
  if (!documentEntity) {
    return null;
  }
  if (documentEntity === DocumentEntityType.Property && !!rowData?.property) {
    entityText = getPropertyAddress(rowData?.property);
  } else if (documentEntity === DocumentEntityType.Party && !!rowData?.party) {
    entityText = getPartyName(rowData?.party);
  } else if (documentEntity === DocumentEntityType.Deal && !!rowData?.deal) {
    entityText = `Deal: ${rowData?.deal.LoanIdentifier}`;
  } else if (
    documentEntity === DocumentEntityType.PropertyStateCounty &&
    !!rowData?.property
  ) {
    entityText = propertyStateCounty(rowData?.property);
  }
  return entityText;
};

const EntityColumn = (props: { rowData: OrderItemView }) => {
  const { rowData } = props;
  return (
    <Box>
      {rowData?.documentEntity === DocumentEntityType.Property &&
        rowData?.property && (
          <Text align="center" whiteSpace={"normal"}>
            {getEntityText(rowData)}
          </Text>
        )}
      {rowData?.documentEntity === DocumentEntityType.Party &&
        rowData?.party && (
          <Text align="center" whiteSpace={"normal"}>
            {getEntityText(rowData)}
          </Text>
        )}
      {rowData?.documentEntity === DocumentEntityType.Deal && rowData?.deal && (
        <Text align="center" whiteSpace={"normal"}>
          {getEntityText(rowData)}
        </Text>
      )}
      {rowData?.documentEntity === DocumentEntityType.PropertyStateCounty &&
        rowData?.property && (
          <Text align="center" whiteSpace={"normal"}>
            {getEntityText(rowData)}
          </Text>
        )}
    </Box>
  );
};
export type OrderItemView = {
  documentConfiguration: WritableDraft<DocumentConfiguration> | undefined;
  documentRule: WritableDraft<DocumentRuleTemplate> | undefined;
  order: {
    rulesEngineDecision: RulesEngineDecision;
    documentName: string;
  };
  documentEntity: DocumentEntityType;
  deal: WritableDraft<Deal> | undefined;
  property: WritableDraft<Property> | undefined;
  party: WritableDraft<Party> | undefined;
};
const SelectedDocumentPackageOrderTable = (props: {
  orderItems: OrderItems;
}) => {
  const { documentRuleState } = useDocumentRuleHooks();
  const { documentConfigurationState } = useDocumentConfigurationHooks();
  const { partyState } = usePartyHooks();
  const { propertyState } = usePropertyHooks();
  const { dealState } = useDealHooks();

  const isLoading = useMemo(() => {
    return false;
  }, []);

  const {
    setStateDebouncer: tableFilterSetter,
    state: tableFilterState,
    selectedOrderState: selectedOrderState,
    setSelectedOrderState: setSelectedOrderState
  } = useFilterHook<OrderItemView, CellsFilterStateFlat<OrderItemView>>({
    state: {
      requestedDocumentName: {
        column: "Requested Document Name",
        orderBy: "asc",
        query: [],
        adapter: (orderItemView) => {
          return orderItemView.documentConfiguration?.name ?? "N/A";
        }
      },
      documentEntity: {
        column: "Document Entity",
        orderBy: "asc",
        query: [],
        adapter: (orderItemView) => {
          return getEntityText(orderItemView) ?? "N/A";
        }
      },
      ruleName: {
        column: "Rule Name",
        orderBy: "asc",
        query: [],
        adapter: (orderItemView) => {
          return orderItemView.documentRule?.ruleName ?? "N/A";
        }
      },
      autoGenerate: {
        column: "Auto Generate",
        orderBy: "asc",
        query: [],
        adapter: (orderItemView) => {
          return orderItemView.documentConfiguration?.autoGenerate ?? "N/A";
        }
      },
      rulesEngine: {
        column: "Rules Engine",
        orderBy: "asc",
        query: [],
        adapter: (orderItemView) => {
          return orderItemView.order.rulesEngineDecision ?? "N/A";
        }
      }
    },
    options: {
      debounceRate: 100
    }
  });

  const orderItemsMemo = useMemo(() => {
    const orderItemsFlat = Object.values(props.orderItems)
      .map((v) => {
        const dealOrderItems = Object.values(v.deal || {}).flat();
        const partyOrderItems = Object.values(v.party || {}).flat();
        const propertyOrderItems = Object.values(v.property || {}).flat();
        const orderItems = concat(
          dealOrderItems,
          partyOrderItems,
          propertyOrderItems
        );
        return orderItems;
      })
      .flat();
    const data = orderItemsFlat.map((orderItem) => {
      let documentConfiguration:
        | WritableDraft<DocumentConfiguration>
        | undefined;
      let documentRule: WritableDraft<DocumentRuleTemplate> | undefined;
      let deal: WritableDraft<Deal> | undefined;
      let party: WritableDraft<Party> | undefined;
      let property: WritableDraft<Property> | undefined;
      const {
        configurationId,
        ruleTemplateId,
        documentEntity,
        entityId,
        ...rest
      } = orderItem;
      if (configurationId) {
        documentConfiguration =
          documentConfigurationState.entities[configurationId];
      }
      if (ruleTemplateId) {
        documentRule = documentRuleState.entities[orderItem.ruleTemplateId];
      }
      if (entityId) {
        if (documentEntity === DocumentEntityType.Deal) {
          deal = dealState.entities[entityId];
        } else if (
          documentEntity === DocumentEntityType.Property ||
          documentEntity === DocumentEntityType.PropertyStateCounty
        ) {
          property = propertyState.entities[entityId];
        } else if (documentEntity === DocumentEntityType.Party) {
          party = partyState.entities[entityId];
        }
      }
      const orderItemView: OrderItemView = {
        documentConfiguration: documentConfiguration,
        documentRule: documentRule,
        order: rest,
        documentEntity: documentEntity,
        deal: deal,
        property: property,
        party: party
      };
      return orderItemView;
    });
    return data;
  }, [props.orderItems, documentConfigurationState, documentRuleState]);

  const tableRows: ElphiTableProps["rows"] = useMemo(() => {
    const filteredData = orderItemsMemo.filter((d) =>
      Object.entries(tableFilterState).every(([key, filter]) => {
        if (!filter.query.length) return true;

        let fieldData = d[key];
        fieldData = filter.adapter ? filter.adapter(d) : fieldData;
        return !Array.isArray(fieldData)
          ? filter.query.includes(fieldData)
          : intersection(filter.query, fieldData).length > 0;
      })
    );

    let orderByColumn: [string, CellFilterField<OrderItemView>] | undefined =
      undefined;
    if (selectedOrderState?.selectedKey) {
      const columnKey = selectedOrderState.selectedKey;
      orderByColumn = [columnKey, tableFilterState[columnKey]];
    }

    let sortedData = filteredData;
    if (orderByColumn) {
      const [columnName, cellFilterField] = orderByColumn;
      const sortOrder = cellFilterField.orderBy ?? "asc";

      const adaptedData = filteredData.map((d) => {
        const adapter = cellFilterField.adapter;
        let value = d[columnName];
        if (adapter) value = adapter(d);
        if (Array.isArray(value)) value = value.join(",");
        return {
          ...d,
          [columnName]: typeof value === "string" ? value.trim() : value
        };
      });

      const sortedAdaptedData = orderBy(adaptedData, columnName, sortOrder);
      sortedData = filteredData.sort((a, b) => {
        const aIndex = sortedAdaptedData.findIndex(
          (x) =>
            x?.documentConfiguration?.name === a?.documentConfiguration?.name
        );
        const bIndex = sortedAdaptedData.findIndex(
          (x) =>
            x?.documentConfiguration?.name === b?.documentConfiguration?.name
        );
        return aIndex - bIndex;
      });
    } else {
      sortedData = filteredData.sort((a, b) =>
        a.order.rulesEngineDecision !== RulesEngineDecision.Match &&
        b.order.rulesEngineDecision === RulesEngineDecision.Match
          ? 1
          : a.order.rulesEngineDecision === RulesEngineDecision.Failed &&
            b.order.rulesEngineDecision !== RulesEngineDecision.Failed
          ? 0
          : -1
      );
    }

    return sortedData.map((data, i) => {
      const rowData: ElphiTableProps["rows"][0] = {
        index: String(i),
        cells: [
          {
            index: 0,
            data: <Text align={"center"}>{i + 1}</Text>,
            type: ElphiCellType.Element,
            maxWidth: "100px",
            minWidth: "100px"
          },
          {
            index: 1,
            data: (
              <Tooltip label={data?.documentConfiguration?.name}>
                <Text
                  overflow={"hidden"}
                  whiteSpace={"nowrap"}
                  textOverflow={"ellipsis"}
                  align={"center"}
                >
                  {data?.documentConfiguration?.name}
                </Text>
              </Tooltip>
            ),
            type: ElphiCellType.Element,
            maxWidth: "300px",
            minWidth: "300px"
          },
          {
            index: 2,
            data: <EntityColumn rowData={data} />,
            type: ElphiCellType.Element,
            maxWidth: "300px",
            minWidth: "300px"
          },
          {
            index: 3,
            data: (
              <Tooltip label={data?.documentRule?.ruleName}>
                <Text
                  align={"center"}
                  overflow={"hidden"}
                  whiteSpace={"nowrap"}
                  textOverflow={"ellipsis"}
                >
                  {data?.documentRule?.ruleName || "N/A"}
                </Text>
              </Tooltip>
            ),
            type: ElphiCellType.Element,
            maxWidth: "200px",
            minWidth: "200px"
          },
          {
            index: 4,
            data: (
              <Text align={"center"}>
                {data?.documentConfiguration?.autoGenerate || "N/A"}
              </Text>
            ),
            type: ElphiCellType.Element,
            maxWidth: "100px",
            minWidth: "100px"
          },
          {
            index: 5,
            data: (
              <Text
                bgColor={statusToColor[data?.order.rulesEngineDecision]}
                align={"center"}
              >
                {data?.order.rulesEngineDecision}
              </Text>
            ),
            type: ElphiCellType.Element,
            maxWidth: "100px",
            minWidth: "100px"
          }
        ]
      };
      return rowData;
    });
  }, [
    props.orderItems,
    documentConfigurationState,
    documentRuleState,
    tableFilterState
  ]);

  const tableHeaders: ElphiTableProps["header"] = useMemo(() => {
    return [
      {
        index: 0,
        data: "Row Index",
        type: ElphiCellType.String,
        maxWidth: "100px",
        minWidth: "100px"
      },
      {
        index: 1,
        data: (
          <FilterCellComponent<OrderItemView>
            data={orderItemsMemo}
            fieldKey={"requestedDocumentName"}
            setter={tableFilterSetter}
            state={tableFilterState}
            adapter={(orderItemView) => {
              return orderItemView.documentConfiguration?.name ?? "N/A";
            }}
            formatter={(v, _) => {
              if (typeof v === "string")
                return {
                  label: v,
                  value: v
                };
              else
                return {
                  label: "N/A",
                  value: "N/A"
                };
            }}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
          />
        ),
        type: ElphiCellType.Element,
        maxWidth: "350px",
        minWidth: "350px"
      },
      {
        index: 2,
        data: (
          <FilterCellComponent<OrderItemView>
            data={orderItemsMemo}
            fieldKey={"documentEntity"}
            setter={tableFilterSetter}
            state={tableFilterState}
            adapter={(orderItemView) => {
              return getEntityText(orderItemView) ?? "N/A";
            }}
            formatter={(v, _) => {
              if (typeof v === "string")
                return {
                  label: v,
                  value: v
                };
              else
                return {
                  label: "N/A",
                  value: "N/A"
                };
            }}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
          />
        ),
        type: ElphiCellType.Element,
        maxWidth: "300px",
        minWidth: "300px"
      },
      {
        index: 3,
        data: (
          <FilterCellComponent<OrderItemView>
            data={orderItemsMemo}
            fieldKey={"ruleName"}
            setter={tableFilterSetter}
            state={tableFilterState}
            adapter={(orderItemView) => {
              return orderItemView.documentRule?.ruleName ?? "N/A";
            }}
            formatter={(v, _) => {
              if (typeof v === "string")
                return {
                  label: v,
                  value: v
                };
              else
                return {
                  label: "N/A",
                  value: "N/A"
                };
            }}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
          />
        ),
        type: ElphiCellType.Element,
        maxWidth: "250px",
        minWidth: "250px"
      },
      {
        index: 4,
        data: (
          <FilterCellComponent<OrderItemView>
            data={orderItemsMemo}
            fieldKey={"autoGenerate"}
            setter={tableFilterSetter}
            state={tableFilterState}
            adapter={(orderItemView) => {
              return orderItemView.documentConfiguration?.autoGenerate ?? "N/A";
            }}
            formatter={(v, _) => {
              if (typeof v === "string")
                return {
                  label: v,
                  value: v
                };
              else
                return {
                  label: "N/A",
                  value: "N/A"
                };
            }}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
          />
        ),
        type: ElphiCellType.Element,
        maxWidth: "300px",
        minWidth: "300px"
      },
      {
        index: 5,
        data: (
          <FilterCellComponent<OrderItemView>
            data={orderItemsMemo}
            fieldKey={"rulesEngine"}
            setter={tableFilterSetter}
            state={tableFilterState}
            adapter={(orderItemView) => {
              return orderItemView.order.rulesEngineDecision ?? "N/A";
            }}
            formatter={(v, _) => {
              if (typeof v === "string")
                return {
                  label: v,
                  value: v
                };
              else
                return {
                  label: "N/A",
                  value: "N/A"
                };
            }}
            selectedOrderSetter={setSelectedOrderState}
            selectedOrderState={selectedOrderState}
          />
        ),
        type: ElphiCellType.Element,
        maxWidth: "250px",
        minWidth: "250px"
      }
    ];
  }, [tableFilterState, orderItemsMemo]);
  const tableProps: ElphiTableProps = useMemo(() => {
    return {
      header: tableHeaders,
      rows: tableRows,
      isLoading: isLoading,
      rowsCount: 1
    };
  }, [tableRows, isLoading]);

  const { heightOffsetInPx } = useWindowDimensions();
  return (
    <>
      <ElphiTable
        minHeight={heightOffsetInPx(250)}
        maxHeight={heightOffsetInPx(250)}
        header={tableProps.header}
        rows={tableProps.rows}
        footer={tableProps.footer}
        isLoading={tableProps.isLoading}
        rowsCount={1}
      />
    </>
  );
};

const ViewDocumentPackageOrderForm = (props: {
  options?: { label: string; value: string }[];
  onSuccess?: () => void;
}) => {
  const { selectedDocumentPackageOrder } = useDocumentPackageOrderHooks();
  const orderItems = useMemo(() => {
    if (selectedDocumentPackageOrder) {
      return selectedDocumentPackageOrder.order.items;
    }
    return {} as OrderItems;
  }, [selectedDocumentPackageOrder]);

  return (
    <>
      <SelectedDocumentPackageOrderTable orderItems={orderItems} />
      <Button
        {...elphiTheme.components.light.button.primary}
        mt="10px"
        onClick={() => props?.onSuccess && props?.onSuccess()}
        isDisabled={false}
        isLoading={false}
      >
        Close
      </Button>
    </>
  );
};
export const DocumentPackageOrderModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setSelectedDocumentPackageOrder } = useDocumentPackageOrderHooks();

  const onCloseHandler = () => {
    setSelectedDocumentPackageOrder("");
    onClose();
  };

  return (
    <Box>
      <ButtonIconViewSummary
        onClick={onOpen}
        tooltipLabel={"View Documents Summary"}
      />
      <Modal isOpen={isOpen} onClose={onCloseHandler}>
        <ModalOverlay />
        <ModalContent minWidth={"1800px"}>
          <ModalHeader>Documents Summary</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ViewDocumentPackageOrderForm onSuccess={onCloseHandler} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
