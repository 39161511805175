import { AggregationField, ThirdPartyField } from "../aggregations";
import { ElphiDealSnapshotEntity } from "../snapshots";
import { DotNestedKeys } from "../utils/flatten";
import { LoanProductType } from "./deal.enums.types";
import { QuoteInputFields } from "./deal.quote.rental.types";
import {
  QuoteMappingDataType,
  SharedQuoteFields
} from "./deal.quote.shared.types";

export const shortTermQuoteGETApiToDealMapping: {
  [path: string]: string[];
} = {
  id: ["quote", "aggregations", "QuoteId"],
  "LoanCosts.brokerFee": [
    "quote",
    "aggregations",
    "BrokerOriginationFeeAmount"
  ],
  achRequired: ["quote", "aggregations", "AchRequiredIndicator"],
  achExempt: ["quote", "aggregations", "AchExemptionStatus"],
  "LoanCosts.interestReserve": [
    "quote",
    "aggregations",
    "InterestReserveEscrow"
  ],
  "LoanCosts.originationFee": ["quote", "aggregations", "OriginationFeeAmount"],
  "LoanCosts.processingFee": ["quote", "aggregations", "ProcessingFeeAmount"],
  "LoanCosts.brokerProcessingFeeCalc": [
    "quote",
    "aggregations",
    "BrokerProcessingFeeAmount"
  ],
  "LoanCosts.servicingSetupFee": ["quote", "aggregations", "ServicingSetupFee"],
  "RateFeeLLPA.finalRate": ["quote", "aggregations", "NoteRatePercent"],
  "Payments.initMonthlyPayment": [
    "quote",
    "aggregations",
    "InitialMonthlyInterestOnlyPaymentAmount"
  ],
  "Payments.paymentFullyDrawn": [
    "quote",
    "aggregations",
    "FullyDrawnInterestOnlyPayment"
  ],
  currentProjectedLTVLTARV: [
    "quote",
    "aggregations",
    "PricingEngineLTARVRatePercent"
  ],
  feeLLPAsFactored: ["quote", "aggregations", "FeeLLPAs"],
  rateLLPAsFactored: ["quote", "aggregations", "RateLLPAs"],
  warnings: ["quote", "aggregations", "PricingEngineWarnings"],
  overridePricing: ["quote", "aggregations", "PricingEngineOverrides"],
  "CashToClose.estimatedLoanAmt": [
    "quote",
    "aggregations",
    "PricingEngineTotalLoanAmount"
  ],
  "LTVARVCalc.maxLTVRatio": [
    "quote",
    "aggregations",
    "PricingEngineMaxLoanToAfterRepairValuePercent"
  ],
  "BlendedLTCCalc.maxBlendedLTCRatio": [
    "quote",
    "aggregations",
    "PricingEngineMaxBlendedLoanToCostPercent"
  ],
  "LTCCalcOnPurchaseRehab.maxLTCOnPurchase": [
    "quote",
    "aggregations",
    "PricingEngineMaxPurchaseLoanToCostPercent"
  ],
  exitFee: ["quote", "aggregations", "DeferredOriginationFeeAmount"],
  lenderFinanceProcessingFee: [
    "quote",
    "aggregations",
    "LenderFinanceProcessingFee"
  ],
  lenderFinanceApplicationFee: [
    "quote",
    "aggregations",
    "LenderFinanceApplicationFee"
  ],
  lenderFinanceDocumentFee: [
    "quote",
    "aggregations",
    "LenderFinanceDocumentFee"
  ],
  lenderFinanceAppraisalFee: [
    "quote",
    "aggregations",
    "LenderFinanceAppraisalFee"
  ],
  pricingInfo: ["quote", "aggregations", "PricingInfo"],
  campaignRateIncentive: ["quote", "aggregations", "CampaignRateIncentive"],
  campaignFeeIncentive: ["quote", "aggregations", "CampaignFeeIncentive"],
  campaignLeverageIncentive: [
    "quote",
    "aggregations",
    "CampaignLeverageIncentive"
  ]
};

export const shortTermQuoteGETApiToDealOverridesMapping: {
  [path: string]: string[];
} = {
  "exceptionPricingOverride.finalRateOverride": [
    "quote",
    "aggregations",
    "PricingEngineNoteRateOverridePercent"
  ],
  "exceptionPricingOverride.arvOverride": [
    "quote",
    "aggregations",
    "PricingEngineTotalLoanToAfterRepairValueOverridePercent"
  ],
  "exceptionPricingOverride.blendedLTCOverride": [
    "quote",
    "aggregations",
    "PricingEngineTotalBlendedLoanToCostOverridePercent"
  ],
  "exceptionPricingOverride.purchaseLTCOverride": [
    "quote",
    "aggregations",
    "PricingEngineTotalPurchaseLoanToCostOverridePercent"
  ]
};

export const shortTermQuoteGETApiToPropertyMapping: {
  [path: string]: string[];
} = {
  currentProjectedLTVLTARV: ["aggregations", "BridgeLTVPercent"]
};

export const shortTermQuotePOSTApiToSnapshotMapping: Partial<{
  [path in DotNestedKeys<ShortTermQuotePOSTApiFields>]: {
    type: QuoteMappingDataType;
    field: DotNestedKeys<ElphiDealSnapshotEntity>;
  };
}> = {
  "fields.dealTerms.submittedDate": {
    type: QuoteMappingDataType.TimestampToDate,
    field: "createdAt"
  }
};

export const shortTermQuotePOSTApiToDealMapping: {
  [path: string]: { type: QuoteMappingDataType; field: string[] };
} = {
  id: {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "QuoteId"]
  },
  "fields.borrowerInfo.primaryGuarantor": {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "PrimaryGuarantor"]
  },
  "fields.borrowerInfo.completedExits": {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "CompletedExits"]
  },
  "fields.borrowerInfo.borrowingEntity": {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "BorrowingEntity"]
  },
  "fields.borrowerInfo.qualifyingFICO": {
    type: QuoteMappingDataType.Number,
    field: ["quote", "aggregations", "PrimaryBorrowerCreditScore"]
  },
  "fields.borrowerInfo.combinedLiquidity": {
    type: QuoteMappingDataType.Number,
    field: ["quote", "aggregations", "SumTotalStatementQualifyingBalance"]
  },
  "fields.borrowerInfo.usCitizen": {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "USCitizenshipIndicator"]
  },
  "fields.borrowerInfo.altTier": {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "AltTier"]
  },
  "fields.dealTerms.payoff": {
    type: QuoteMappingDataType.Number,
    field: ["aggregations", "TotalOutstandingLoanPayoffAmount"]
  },
  "fields.dealTerms.targetCloseDate": {
    type: QuoteMappingDataType.String,
    field: ["aggregations", "EstimatedClosingDate"]
  },
  "fields.dealTerms.lesserOfLotOrPurchase": {
    type: QuoteMappingDataType.Number,
    field: ["quote", "aggregations", "LesserOfLotOrPurchase"]
  },
  "fields.dealTerms.totalPurchasePrice": {
    type: QuoteMappingDataType.Number,
    field: ["aggregations", "TotalPurchasePriceAmount"]
  },
  "fields.dealTerms.rehabConBudget": {
    type: QuoteMappingDataType.Number,
    field: ["aggregations", "TotalBudgetAmount"]
  },
  "fields.dealTerms.afterCompletedVal": {
    type: QuoteMappingDataType.Number,
    field: ["aggregations", "TotalSubjectToAppraisedValueAmount"]
  },
  "fields.dealTerms.existingDebtStatus": {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "DebtStatus"]
  },
  "fields.dealTerms.numOfProperties": {
    type: QuoteMappingDataType.Number,
    field: ["aggregations", "TotalNumberOfProperties"]
  },
  "fields.dealTerms.originalPurchaseDate": {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "OriginalPurchaseDate"]
  },
  "fields.loanSummary.loanPurpose": {
    type: QuoteMappingDataType.String,
    field: ["LoanPurposeType"]
  },
  "fields.loanSummary.loanProduct": {
    // THIS IS CORRECT, product on Lima's end should map to our program type
    type: QuoteMappingDataType.String,
    field: ["aggregations", "LoanProgramType"]
  },
  "fields.loanSummary.propertyAddress": {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "PropertyAddressLineText"]
  },
  "fields.loanSummary.city": {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "PropertyCityName"]
  },
  "fields.loanSummary.state": {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "PropertyStateCode"]
  },
  "fields.loanSummary.zip": {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "PropertyPostalCode"]
  },
  "fields.loanSummary.propertyType": {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "PropertyType"]
  },
  "fields.loanTerm.internalRefinance": {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "InternalL1CRefinanceIndicator"]
  },
  "fields.loanTerm.loanTerm": {
    type: QuoteMappingDataType.String,
    field: ["LoanTermPeriodMonthCount"]
  },
  "fields.loanTerm.guaranty": {
    type: QuoteMappingDataType.String,
    field: ["RecourseType"]
  },
  "fields.borrowerInfo.borrowerTier": {
    type: QuoteMappingDataType.String,
    field: ["quote", "aggregations", "BorrowerTier"]
  },

  "fields.milestone": {
    type: QuoteMappingDataType.String,
    field: ["DealMetadata", "milestone"]
  },
  "fields.exceptionPricingOverride.arvOverride": {
    type: QuoteMappingDataType.Number,
    field: [
      "quote",
      "aggregations",
      "PricingEngineTotalLoanToAfterRepairValueOverridePercent"
    ]
  },
  "fields.exceptionPricingOverride.blendedLTCOverride": {
    type: QuoteMappingDataType.Number,
    field: [
      "quote",
      "aggregations",
      "PricingEngineTotalBlendedLoanToCostOverridePercent"
    ]
  },
  "fields.exceptionPricingOverride.purchaseLTCOverride": {
    type: QuoteMappingDataType.Number,
    field: [
      "quote",
      "aggregations",
      "PricingEngineTotalPurchaseLoanToCostOverridePercent"
    ]
  },
  "fields.exceptionPricingOverride.finalRateOverride": {
    type: QuoteMappingDataType.Number,
    field: ["quote", "aggregations", "PricingEngineNoteRateOverridePercent"]
  },
  "fields.exceptionPricingOverride.processingFeeOverride": {
    type: QuoteMappingDataType.Number,
    field: ["ProcessingFeeOverride"]
  },
  "fields.loanCostOverride.iREscrowOverrideInt": {
    type: QuoteMappingDataType.Number,
    field: ["InterestReserveEscrowOverrideMonthCount"]
  },
  "fields.loanCostOverride.iREscrowOverrideMoneyAmt": {
    type: QuoteMappingDataType.Number,
    field: ["InterestReserveEscrowOverrideAmount"]
  }
};

export const directShortTermQuotePOSTApiToDealMapping: {
  [field: string]: string[];
} = {
  id: ["quote", "aggregations", "QuoteId"],
  "loanSummary.loanProduct": ["aggregations", "LoanProgramType"],
  "leverage.adjustedBlendedLTC": [
    "quote",
    "aggregations",
    "BlendedLTCAdjuster"
  ],
  "leverage.adjustedPurchaseLTC": [
    "quote",
    "aggregations",
    "PurchaseLTCAdjuster"
  ],
  "leverage.adjustedARV": ["quote", "aggregations", "ARVAdjuster"],
  campaignCode: ["quote", "aggregations", "CampaignCode"],
  campaignExpirationDate: ["quote", "aggregations", "CampaignExpirationDate"]
};

type ShortTermQuoteGETFields = {
  InitialMonthlyInterestOnlyPaymentAmount: ThirdPartyField;
  PricingEngineLTARVRatePercent: ThirdPartyField;
  PricingEngineTotalLoanAmount: ThirdPartyField;
  FullyDrawnInterestOnlyPayment: ThirdPartyField;

  PricingEngineTotalLoanToAfterRepairValueOverridePercent: ThirdPartyField;
  PricingEngineTotalBlendedLoanToCostOverridePercent: ThirdPartyField;
  PricingEngineTotalPurchaseLoanToCostOverridePercent: ThirdPartyField;
  PricingEngineNoteRateOverridePercent: ThirdPartyField;

  PricingEngineMaxLoanToAfterRepairValuePercent: ThirdPartyField;
  PricingEngineMaxBlendedLoanToCostPercent: ThirdPartyField;
  PricingEngineMaxPurchaseLoanToCostPercent: ThirdPartyField;
  DeferredOriginationFeeAmount: ThirdPartyField;
};

type ShortTermQuotePOSTFields = {
  PrimaryGuarantor: AggregationField;
  CompletedExits: AggregationField;
  SumTotalStatementQualifyingBalance: AggregationField;
  AltTier: AggregationField;
  LesserOfLotOrPurchase: AggregationField;
  DebtStatus: AggregationField;
  PropertyAddressLineText: AggregationField;
  PropertyCityName: AggregationField;
  PropertyStateCode: AggregationField;
  PropertyPostalCode: AggregationField;
  PropertyType: AggregationField;
  PropertyId: AggregationField;
  BorrowerTier: AggregationField;
  OriginalPurchaseDate: AggregationField;

  PricingEngineTotalLoanToAfterRepairValueOverridePercent: ThirdPartyField;
  PricingEngineTotalBlendedLoanToCostOverridePercent: ThirdPartyField;
  PricingEngineTotalPurchaseLoanToCostOverridePercent: ThirdPartyField;
  PricingEngineNoteRateOverridePercent: ThirdPartyField;
};

type ShortTermQuoteInputFields = {
  id: string;
  username: string;
  quoteName: string;
  quoteType: string;
  overrideMaster: boolean;
  milestone: string;
  borrowerInfo: {
    primaryGuarantor: string;
    completedExits: string;
    secondaryGuarantor: string;
    borrowerIsReferral: string;
    borrowingEntity: string;
    fnfCompletedInThreeYears: number;
    buildsCompletedInThreeYears: number;
    completedTransactions: number;
    qualifyingFICO: number;
    combinedLiquidity: string;
    usCitizen: string;
    borrowerTier: string;
    altTier: string;
  };
  dealTerms: {
    targetCloseDate: string;
    timeCloseToPayoff: number;
    lesserOfLotOrPurchase: number;
    rehabConBudget: number;
    afterCompletedVal: number;
    refinance: string;
    existingDebtStatus: string;
    cashOut: string;
    heavyRehab: string;
    originalPurchaseDate: string;
    payoff: string;
    buyUpPercentage: number;
    buyDownPercentage: number;
    IsZeroOrigFee: string;
    numOfProperties: number;
    totalPurchasePrice: number;
    submittedDate: string;
  };
  exceptionPricingOverride: {
    autoRate: number;
    autoFee: number;
    commissionReduction: number;
    arvOverride: string;
    finalRateOverride: number;
    blendedLTCOverride: number;
    purchaseLTCOverride: number;
  };
  loanSummary: {
    loanPurpose: string;
    loanProduct: string;
    propertyAddress: string;
    city: string;
    state: string;
    zip: string;
    propertyType: string;
  };
  loanTerm: {
    channel: string;
    referralFee: string;
    brokerFee: number;
    loanTerm: string;
    guaranty: string;
    appraisalFee: string;
    brokerProcessingFee: number;
    projectInspectionFee: number;
    internalRefinance: string;
  };
  leverage: {
    adjustedBlendedLTC: number;
    adjustedPurchaseLTC: number;
    adjustedARV: number;
  };
};

type ShortTermQuoteOutputFields = {
  id?: string;
  validationErrors?: string;
  LoanCosts?: {
    brokerFee?: number;
    interestReserve?: number;
    originationFee?: number;
    processingFee?: number;
    brokerProcessingFeeCalc?: number;
    servicingSetupFee?: number;
  };
  Payments?: {
    initMonthlyPayment?: number;
    paymentFullyDrawn?: number;
  };
  RateFeeLLPA?: {
    finalRate: number;
  };
  LTVARVCalc?: {
    maxLTVRatio: number;
  };
  BlendedLTCCalc?: {
    maxBlendedLTCRatio: number;
  };

  CashToClose?: {
    estimatedLoanAmt: number;
  };
  LTCCalcOnPurchaseRehab?: {
    maxLTCOnPurchase: number;
  };
  currentProjectedLTVLTARV?: number;
  feeLLPAsFactored?: string;
  rateLLPAsFactored?: string;
  warnings?: string;
  overridePricing?: string;
  estimatedLoanAmount?: string;
};

type ShortTermQuotePOSTApiFields = {
  id: string;
  fields: ShortTermQuoteInputFields;
};

export type ShortTermQuoteGETResponse = {
  quoteInfo: {
    id: string;
    program: string;
    product: LoanProductType;
  } & object;
  input: QuoteInputFields & object;
  output: ShortTermQuoteOutputFields & object;
};

export type ShortTermQuoteFields = ShortTermQuoteGETFields &
  ShortTermQuotePOSTFields &
  SharedQuoteFields;
