import {
  BatchDocumentTemplateResponse,
  DocumentTemplateCreateRequest,
  DocumentTemplateResponse,
  DocumentTemplateUpdateBatchRequest,
  DocumentTemplateUpdateRequest,
  FirebaseFilter
} from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { entries } from "lodash";
import { AppConfig } from "../../../config/appConfig";
import { serviceBuilder } from "../builders/api.builder";
import { Builder } from "../builders/crud.endpoints.builder";
import { BasePaginationResponse } from "../hooks/pagination.hooks.v2";
import { documentTemplateAdapter } from "./documentTemplate.adapter";

export const documentTemplateApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "documentTemplateApi",
  tagTypes: ["documentTemplate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "document-template"
  }),
  endpoints: (builder: Builder) => ({
    getBatch: builder.query<
      { documentTemplates: EntityState<DocumentTemplateResponse> },
      string[]
    >({
      query: (ids) => {
        return {
          url: `/get-batch`,
          method: "POST",
          body: { ids }
        };
      },
      transformResponse: (response: BatchDocumentTemplateResponse) => {
        const adapter = documentTemplateAdapter.addMany(
          documentTemplateAdapter.getInitialState(),
          response.documentTemplates
        );

        return { documentTemplates: adapter };
      }
    }),
    paginateV2: builder.query<
      BasePaginationResponse<DocumentTemplateResponse[]>,
      {
        cursor?: string;
        options?: {
          order?: "desc" | "asc";
          filter?: FirebaseFilter | FirebaseFilter[];
        };
      }
    >({
      query: (r) => {
        return {
          url: `/paginate`,
          method: "POST",
          body: r
        };
      }
    }),
    search: builder.query<
      {
        query: string;
        hasMore: boolean;
        nextCursor: string | null;
        results: EntityState<DocumentTemplateResponse>;
      },
      { query: string; cursor?: string }
    >({
      query: (r) => {
        return {
          url: `/search?q=${r.query}${r.cursor ? `&cursor=${r.cursor}` : ""}`,
          method: "GET"
        };
      },
      transformResponse: (
        response: {
          results: DocumentTemplateResponse[];
          nextCursor: string | null;
          hasMore: boolean;
        },
        _,
        arg
      ) => {
        const adapter = documentTemplateAdapter.addMany(
          documentTemplateAdapter.getInitialState(),
          response.results
        );
        return {
          query: arg.query,
          results: adapter,
          nextCursor: response.nextCursor,
          hasMore: response.hasMore
        };
      }
    }),
    updateBatch: builder.mutation<
      { batch: string[] },
      { documentTemplates: DocumentTemplateUpdateBatchRequest }
    >({
      query: (r) => {
        return {
          url: `/update-batch`,
          method: "PUT",
          body: r
        };
      }
    }),
    create: builder.mutation<{ id: string }, DocumentTemplateCreateRequest>({
      query: (r) => {
        const formData = new FormData();
        entries(r).forEach(([key, value]: [string, string]) => {
          value && formData.append(key, value);
        });

        return {
          url: `/`,
          method: "POST",
          body: formData
        };
      }
    }),
    update: builder.mutation<
      { id: string },
      Omit<DocumentTemplateUpdateRequest, "file"> & {
        file?: File;
      }
    >({
      query: (r) => {
        const formData = new FormData();
        entries(r).forEach(([key, value]: [string, string]) => {
          formData.append(key, value);
        });
        return {
          url: `/${r.id}`,
          method: "PUT",
          body: formData
        };
      }
    }),
    getDownloadUrl: builder.query<
      { url: string },
      { id?: string; templatePath?: string }
    >({
      query: (r) => {
        return {
          url: `/download-url`,
          method: "POST",
          body: r
        };
      }
    })
  })
});
