// Need to use the React-specific entry point to allow generating React hooks
import { Statement } from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import { serviceBuilder } from "../builders/api.builder";
import { statementEntityAdapter } from "./statement.adapter";

// Define a service using a base URL and expected endpoints
export const statementBaseApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "statementApi",
  tagTypes: ["statement"],
  baseQuery: serviceBuilder.baseQuery({
    route: "statement"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: statementEntityAdapter
  })
});

export const statementApi = statementBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    search: builder.query<EntityState<Statement>, string>({
      query: (query) => {
        return {
          url: `/search?q=${query}`,
          method: "GET"
        };
      },
      transformResponse: (response: { results: Statement[] }) => {
        const adapter = statementEntityAdapter.addMany(
          statementEntityAdapter.getInitialState(),
          response.results
        );
        return adapter;
      }
    }),
    batchUpdate: builder.mutation<
      { batch: string[] },
      { statements: ({ id: string } & Partial<Statement>)[] }
    >({
      query: (r) => {
        return {
          url: `/update-batch`,
          method: "PUT",
          body: r
        };
      }
    })
  })
});
