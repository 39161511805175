// Need to use the React-specific entry point to allow generating React hooks
import { DocumentMetadata, Task } from "@elphi/types";
import { EntityId, EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import { serviceBuilder } from "../builders/api.builder";
import { sortComparer } from "../utils/sort.utils";

export const taskEntityAdapter = createEntityAdapter<Task>({ sortComparer });

// Define a service using a base URL and expected endpoints
export const baseTaskApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "taskApi",
  tagTypes: ["Task", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "task/v2"
  }),

  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: taskEntityAdapter
  })
});

export const taskApi = baseTaskApi.injectEndpoints({
  endpoints: (builder) => ({
    entityTasks: builder.query<
      { tasks: EntityState<Task> },
      { ids: string[] | EntityId[] }
    >({
      query: (request) => {
        return {
          url: `/entity-tasks`,
          method: "POST",
          body: request
        };
      },
      transformResponse: (response: { results: { tasks: Task[] } }) => {
        const taskAdapter = taskEntityAdapter.addMany(
          taskEntityAdapter.getInitialState(),
          response.results.tasks
        );
        return {
          tasks: taskAdapter
        };
      }
    }),
    batchUpdate: builder.mutation<
      { batch: string[] },
      { tasks: ({ id: string } & Partial<Task>)[] }
    >({
      query: (r) => {
        return {
          url: `/update-batch`,
          method: "PUT",
          body: r
        };
      }
    }),
    generateTasks: builder.mutation<
      {
        results: {
          tasks: { deal: string[]; property: string[]; party: string[] };
        };
      },
      { dealId?: string; partyId?: string; serviceProviderId?: string }
    >({
      query: (r) => {
        return {
          url: `/generate/run`,
          method: "POST",
          body: r
        };
      }
    }),
    generateManualTasks: builder.mutation<
      {
        results: {
          tasks: {
            deal: string[];
            property: string[];
            party: string[];
            statement: string[];
            asset: string[];
          };
        };
      },
      {
        dealId: string[];
        propertyId: string[];
        partyId: string[];
        statementId: string[];
        taskConfigurationId: string[];
        serviceProvderId: string[];
      }
    >({
      query: (r) => {
        return {
          url: `/generate/manual`,
          method: "POST",
          body: r
        };
      }
    }),
    uploadMultipleTaskFiles: builder.mutation<
      { id: string; failedResponses: { payload: { description: string } }[] },
      {
        files: FileList | File;
        taskId: string;
        folderId: string;
        fileKey?: string;
      }
    >({
      query: (r) => {
        const formData = new FormData();
        if (r.files instanceof FileList && r.files?.length) {
          for (let i = 0; i < r.files.length; i++) {
            formData.append(`files`, r.files[i]);
          }
        } else {
          const file = r.files as File;
          formData.append(`files`, file);
          r?.fileKey && formData.append(`fileKey`, r.fileKey);
        }
        return {
          url: `/documents/${r.taskId}/${r.folderId}`,
          method: "POST",
          body: formData
        };
      }
    }),
    uploadTaskFile: builder.mutation<
      { id: string },
      { file: File; taskId: string; folderId: string }
    >({
      query: (r) => {
        const formData = new FormData();
        formData.append("file", r.file);
        return {
          url: `/document/${r.taskId}/${r.folderId}`,
          method: "POST",
          body: formData
        };
      }
    }),
    deleteTaskFile: builder.mutation<
      { id: string },
      { document: DocumentMetadata; taskId: string }
    >({
      query: (r) => {
        return {
          url: `/document/${r.taskId}`,
          method: "DELETE",
          body: { document: r.document }
        };
      }
    }),
    getDownloadUrl: builder.mutation<
      { url: string },
      { document: DocumentMetadata }
    >({
      query: (r) => {
        return {
          url: `/document/download-url`,
          method: "POST",
          body: { document: r.document }
        };
      }
    }),
    createTaskFolder: builder.mutation<
      { folderId: string },
      { taskId: string }
    >({
      query: (r) => {
        return {
          url: `/document/folder/${r.taskId}`,
          method: "POST"
        };
      }
    }),
    bulkOrder: builder.mutation<{ taskIds: string[] }, { taskIds: string[] }>({
      query: (r) => {
        return {
          url: `/bulk-order`,
          method: "POST",
          body: r
        };
      }
    })
  })
});
