import { TObject, TProperties, TSchema, Type } from "@sinclair/typebox";
import { partyValidations } from "../../../utils/src/validation.utils";
import {
  BooleanSelectType,
  ContactPointRoleType,
  CreditReportPullType,
  EntityType,
  LOCStatusType,
  Party,
  PartyMilestoneType,
  PartyOperationsDepartmentType,
  PartyType,
  RealEstateOperationType,
  TaxpayerIdentifierType,
  getPartyTemplate
} from "../../entities";
import { limaFixNFlipNBridgePlusTier } from "../../entities/lima";
import { BaseEntitySchema } from "../baseEntity.typebox";
import { FieldExceptionTemplateSchema } from "../fieldExceptions.typebox";
import {
  BaseAddressSchema,
  StateCodeSchema,
  StateNameSchema
} from "../location.typebox";
import { OmitCreateSchema, OmitUpdateSchema } from "../service.typebox";
import { BasePaginationRequest } from "../shared.typebox";
import {
  AggregationFieldSchema,
  ArrayField,
  BooleanField,
  DateField,
  Deprecated,
  EmailField,
  EnumField,
  IntegerField,
  LiteralField,
  MoneyAmountField,
  MoneyAmountFieldString,
  NumberField,
  PercentField,
  PhoneNumberField,
  StringField,
  UnionField
} from "../utils.typebox";
import { FieldMetaTarget, buildFieldMetadata } from "../utils/fieldMeta.utils";

const IndividualPartyExceptionsSchema = Type.Object({
  NewConstructionEligibilityIndicator: Deprecated(
    Type.Partial(FieldExceptionTemplateSchema)
  ),
  FixNFlipNBridgePlusTier: Deprecated(
    Type.Partial(FieldExceptionTemplateSchema)
  )
});

const BasePartyAggregationsSchema = Type.Object({
  BackgroundReportExpirationDate: AggregationFieldSchema(DateField)
});

const IndividualPartyAggregationsSchema = Type.Object({
  TotalStatementQualifyingBalance: AggregationFieldSchema(
    MoneyAmountFieldString
  ),
  LOCExpirationDate: AggregationFieldSchema(DateField)
});
const EntityPartyAggregationsSchema = Type.Object({
  TotalStatementQualifyingBalance: Deprecated(
    AggregationFieldSchema(MoneyAmountFieldString)
  )
});

const BasePartySchema = <TType extends TSchema, TTaxIdentifier extends TSchema>(
  partyType: TType,
  taxpayerIdentifierType: TTaxIdentifier
) =>
  Type.Intersect([
    BaseEntitySchema,
    Type.Object({
      PartyType: partyType,
      TaxpayerIdentifierType: taxpayerIdentifierType,
      TaxpayerIdentifierValue: StringField,
      LiquidAssetTotalAmount: MoneyAmountField,
      OperationsDepartment: EnumField(PartyOperationsDepartmentType),
      Address: Type.Partial(BaseAddressSchema),
      BackgroundReportDate: DateField,
      CRMId: StringField
    })
  ]);

const entityPartyFieldMetaSchema = buildFieldMetadata<
  Party,
  TObject<TProperties>
>({
  entityTemplate: getPartyTemplate(PartyType.Entity),
  target: FieldMetaTarget.Schema
});
const EntityPartyFieldsSchema = Type.Object({
  aggregations: Type.Partial(
    Type.Intersect([BasePartyAggregationsSchema, EntityPartyAggregationsSchema])
  ),
  FieldMeta: Type.Partial(entityPartyFieldMetaSchema),
  EntityType: EnumField(EntityType),
  FullName: Type.String({
    maxLength: partyValidations.fullNameMaxLength
  }),
  OwnershipPercent: PercentField,
  BusinessIncorporationStateName: StateNameSchema,
  BusinessIncorporationStateCode: StateCodeSchema,
  BusinessRegistrationStates: ArrayField(StateCodeSchema),
  FixAndFlipBridgePlusTier: EnumField(limaFixNFlipNBridgePlusTier),
  NewConstructionEligibilityIndicator: BooleanField,
  RemainingLineOfCreditAmount: MoneyAmountField,
  Notes: StringField,
  EntityBackgroundReportExpirationDate: Deprecated(DateField),
  EvidenceOfGoodStandingExpirationDate: DateField,
  RealEstateOperationType: ArrayField(EnumField(RealEstateOperationType)),
  TotalFixAndFlipNewConstructionSoldPastThreeYearsCount: IntegerField,
  PartyMilestone: EnumField(PartyMilestoneType),
  RepresentativeCreditScore: IntegerField,
  TotalOutstandingLongTermLoanCount: IntegerField,
  TotalOutstandingLongTermPrincipalAmount: MoneyAmountField,
  GroupId: StringField,
  OverridePartyValidationSettingsIndicator: EnumField(BooleanSelectType)
});

export const limaEntityPartySchema = Type.Intersect([
  BasePartySchema(
    LiteralField(PartyType.Entity),
    LiteralField(TaxpayerIdentifierType.EIN)
  ),
  EntityPartyFieldsSchema
]);

const individualFieldMetaSchema = buildFieldMetadata<
  Party,
  TObject<TProperties>
>({
  entityTemplate: getPartyTemplate(PartyType.Individual),
  target: FieldMetaTarget.Schema
});

const IndividualPartyFieldsSchema = Type.Object({
  aggregations: Type.Partial(
    Type.Intersect([
      BasePartyAggregationsSchema,
      IndividualPartyAggregationsSchema
    ])
  ),
  FieldMeta: Type.Partial(individualFieldMetaSchema),
  FirstName: StringField,
  MiddleName: StringField,
  LastName: StringField,
  BirthDate: DateField,
  GovernmentIssuedIDExpirationDate: DateField,
  IndividualBackgroundReportExpirationDate: Deprecated(DateField),
  CitizenshipCountry: StringField,
  USCitizenshipIndicator: BooleanField,
  ContactPointRoleType: EnumField(ContactPointRoleType),
  ContactPointTelephoneValue: PhoneNumberField,
  ContactPointEmailValue: EmailField,
  PaymentTokenId: StringField,
  GroupId: StringField,

  //Line of Credit stuff
  EquifaxScore: IntegerField,
  TransUnionScore: IntegerField,
  ExperianScore: IntegerField,
  CreditReportNotes: StringField,
  CreditReportDate: DateField,
  CreditReportPullType: EnumField(CreditReportPullType),
  FixNFlipNBridgePlusTier: EnumField(limaFixNFlipNBridgePlusTier),
  NewConstructionEligibilityIndicator: BooleanField,
  LOCStatusType: EnumField(LOCStatusType),
  TotalApprovedLOC: MoneyAmountField,
  UnpaidPrincipalBalance: MoneyAmountField,
  LoanAmountInProcess: MoneyAmountField,
  RemainingLOCAvailable: MoneyAmountField,
  LOCExpirationDate: Deprecated(DateField),

  //Short Term Experience stuff
  CompletedExits: NumberField,
  NumberOfTransactions: NumberField,
  TransactionsCumulativeSalePrice: MoneyAmountField,
  TransactionsAverageProjectDuration: NumberField,
  TransactionsAverageSalePrice: MoneyAmountField,
  NumberOfFlips: NumberField,
  FlipsCumulativeSalePrice: MoneyAmountField,
  FlipsAverageProjectDuration: NumberField,
  FlipsAverageSalePrice: MoneyAmountField,
  NumberOfNewBuilds: NumberField,
  NewBuildsCumulativeSalePrice: MoneyAmountField,
  NewBuildsAverageProjectDuration: NumberField,
  NewBuildsAverageSalePrice: MoneyAmountField,
  ExperienceNotes: StringField,
  Exceptions: Type.Partial(IndividualPartyExceptionsSchema),
  EstimatedCreditScore: IntegerField,
  ConvictionIndicator: BooleanField,
  OutstandingLawsuitsIndicator: BooleanField,
  OutstandingJudgmentsIndicator: BooleanField,
  BankruptcyIndicator: BooleanField,
  ForeclosureIndicator: BooleanField,
  MortgageDelinquentIndicator: BooleanField,
  StatedCashOnHandAmount: MoneyAmountField
});

export const limaIndividualPartySchema = Type.Intersect([
  BasePartySchema(
    LiteralField(PartyType.Individual),
    UnionField([
      LiteralField(TaxpayerIdentifierType.SSN),
      LiteralField(TaxpayerIdentifierType.TIN)
    ])
  ),
  IndividualPartyFieldsSchema
]);

export const PartySchema = Type.Union([
  limaEntityPartySchema,
  limaIndividualPartySchema
]);

export const limaIndividualUpdate = OmitUpdateSchema(limaIndividualPartySchema);
export const limaEntityUpdate = OmitUpdateSchema(limaEntityPartySchema);

export const IndividualCreate = OmitCreateSchema(limaIndividualPartySchema);
export const EntityCreate = OmitCreateSchema(limaEntityPartySchema);

export const PartyUpdate = Type.Union([limaIndividualUpdate, limaEntityUpdate]);
export const PartyCreate = Type.Union([IndividualCreate, EntityCreate]);

export const AddChildRequest = Type.Object({
  child: Type.Union([Type.Object({ id: StringField }), PartyCreate])
});

export const PaginateEnhanceRequest = Type.Intersect([
  BasePaginationRequest,
  Type.Object({
    enhance: Type.Optional(
      Type.Object({
        partyTree: Type.Optional(
          Type.Boolean({ default: false, examples: [true, false] })
        )
      })
    )
  })
]);
