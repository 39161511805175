import { ButtonIconDelete } from "../../button-icon/ButtonIcon";

import { DeleteDealPartyRelationModal } from "./DeleteDealParty";

type DeleteNodeType = {
  dealId: string;
  callback?: () => void;
};

export const DeleteDealPartyButton = (props: DeleteNodeType) => {
  const DeleteModal = (
    <DeleteDealPartyRelationModal
      buttonFn={ButtonIconDelete}
      callback={props.callback}
      dealId={props.dealId}
    />
  );

  return {
    component: DeleteModal,
    tooltip: "Delete this Deal-Party Relation"
  };
};
