import { Role } from "@elphi/types";
import { useRoleHooks } from "../../../hooks/role.hooks";
import { SearchComponentProps } from "../../SearchComponent";
import { SearchHandler } from "../../search/SearchHandler";

const buildOption = (role: Role) => {
  const label = `${role.label}`;
  const value = role.id;
  return {
    label,
    value
  };
};

export const RoleSearch = (
  props: {
    filter?: (a: Role) => boolean;
  } & Pick<
    SearchComponentProps,
    | "fetchMore"
    | "hasMore"
    | "onSelect"
    | "currentValue"
    | "label"
    | "labelPosition"
    | "isReadOnly"
    | "isDisabled"
    | "hideSelectedOptions"
    | "customComponent"
  > &
    Pick<Partial<SearchComponentProps>, "fieldType">
) => {
  const { searchRole, searchResponse, rankedSort, roleState } = useRoleHooks();

  return (
    <SearchHandler
      {...props}
      searchApi={searchRole}
      searchResponse={searchResponse}
      rankedSort={rankedSort}
      state={roleState}
      buildOption={buildOption}
    />
  );
};
