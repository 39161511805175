import { Organization, OrganizationType, StatusCode } from "@elphi/types";
import { useDispatch, useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { useElphiToast } from "../components/toast/toast.hook";
import { RootState } from "../redux/store";
import { orgApi } from "../redux/v2/organization/service";
import { orgSlice } from "../redux/v2/organization/slice";
import { PathVariables } from "../routes/pathVariables";
import { FieldsWeight, getRankedData } from "../utils/ranked.utils";

const dataRank: FieldsWeight<Organization> = {
  id: 25,
  name: 30,
  description: 10
};
export const PLATFORM_ORG_ID = OrganizationType.Platform;
export const PLATFORM_URL = `/${PLATFORM_ORG_ID}${PathVariables.PlatformManagement}`;

export const useOrgHooks = () => {
  const { successToast, errorToast } = useElphiToast();
  const dispatcher = useDispatch();
  const setSelectedOrg = (id: string) =>
    dispatcher(orgSlice.actions.selectedId({ id }));

  const [getOrgApi, getOrgApiResponse] = orgApi.useLazyGetQuery();
  const [updateApi, updateApiResponse] = orgApi.useUpdateMutation();
  const [createApi, createApiResponse] = orgApi.useCreateMutation();
  const [getUserOrganizations, getUserOrganizationsApiResponse] =
    orgApi.useLazyUserOrganizationsQuery();
  const [searchOrgs, searchResponse] = orgApi.useLazySearchQuery();

  const updateHandler = async (r: Partial<Organization> & { id: string }) => {
    return await updateApi({
      id: r.id,
      name: r.name,
      description: r.description,
      isSubscribed: r.isSubscribed,
      cloneConfiguration: r.cloneConfiguration,
      subscribeToOrganization: r.subscribeToOrganization
    })
      .then(responseHandler)
      .then((r) =>
        toasterHandler(
          r,
          { title: "Update organization", description: "Successfully updated" },
          { title: "Update organization", description: "Failed" }
        )
      );
  };
  const createHandler = async (r: Partial<Organization>) => {
    return await createApi({
      id: r.id,
      name: r.name,
      description: r.description,
      isSubscribed: r.isSubscribed,
      cloneConfiguration: r.cloneConfiguration,
      subscribeToOrganization: r.subscribeToOrganization
    })
      .then(responseHandler)
      .then((r) =>
        toasterHandler(
          r,
          { title: "Create organization", description: "Successfully created" },
          { title: "Create organization", description: "Failed" }
        )
      );
  };
  const selectedOrg = useSelector(
    (state: RootState) =>
      state.org.selectedId && state.org.entities[state.org.selectedId]
  );

  const selectedOrgId = useSelector((state: RootState) => state.org.selectedId);
  const orgState = useSelector((state: RootState) => state.org);

  const rankedSort = (query: string) => {
    return Object.values(orgState.entities).sort((a, b) => {
      const rankA = getRankedData(query, a, dataRank);
      const rankB = getRankedData(query, b, dataRank);

      if (rankA < rankB) {
        return 1;
      }
      return -1;
    });
  };

  const isPlatformOrg = selectedOrgId === PLATFORM_ORG_ID;

  const toasterHandler = (
    r:
      | {
          status: StatusCode.BadRequest;
          data: {
            error: any;
            description: string;
          };
        }
      | {
          status: StatusCode.OK;
          data: {
            id: string;
          };
        },
    successToaster: { title: string; description: string },
    errorToaster: { title: string; description: string }
  ) => {
    if (r.status === StatusCode.OK) {
      successToast({
        ...successToaster,
        description: `${successToaster.description} - ${r.data.id}`
      });
    }
    if (r.status === StatusCode.BadRequest) {
      errorToast({
        ...errorToaster,
        description: `${errorToaster.description} - ${JSON.stringify(r.data)}`
      });
    }
  };

  return {
    getUserOrganizations,
    getUserOrganizationsApiResponse,
    setSelectedOrg,
    selectedOrg,
    selectedOrgId,
    orgState,
    updateHandler,
    createHandler,
    updateApiResponse,
    createApiResponse,
    dataRank,
    rankedSort,
    searchOrgs,
    searchResponse,
    isPlatformOrg,
    getOrgApi,
    getOrgApiResponse
  };
};
