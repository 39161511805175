import { TSchema, Type } from "@sinclair/typebox";
import { DealPartyRelationType } from "../../entities";
import {
  limaLoanPartyEntityRoleType,
  limaLoanPartyIndividualRoleType
} from "../../entities/lima";
import { BaseEntitySchema } from "../baseEntity.typebox";
import { OmitCreateSchema, OmitUpdateSchema } from "../service.typebox";
import { ArrayField, EnumField, StringField } from "../utils.typebox";

const BaseDealPartyRelationFieldsSchema = <TType extends TSchema>(
  type: TType
) =>
  Type.Object({
    type: type,
    dealId: StringField,
    partyId: StringField
  });

const BaseDealPartyRelationSchema = <TType extends DealPartyRelationType>(
  type: TType
) =>
  Type.Intersect([
    BaseEntitySchema,
    BaseDealPartyRelationFieldsSchema(Type.Literal(type))
  ]);

export const limaDealIndividualSchema = Type.Intersect([
  BaseDealPartyRelationSchema(DealPartyRelationType.DealIndividual),
  Type.Object({
    relationRoleType: ArrayField(EnumField(limaLoanPartyIndividualRoleType))
  })
]);
export const limaDealEntitySchema = Type.Intersect([
  BaseDealPartyRelationSchema(DealPartyRelationType.DealEntity),
  Type.Object({
    relationRoleType: ArrayField(EnumField(limaLoanPartyEntityRoleType))
  })
]);

export const limaDealIndividualUpdate = OmitUpdateSchema(
  limaDealIndividualSchema
);
export const limaDealEntityUpdate = OmitUpdateSchema(limaDealEntitySchema);

export const limaDealIndividualCreate = OmitCreateSchema(
  limaDealIndividualSchema
);
export const limaDealEntityCreate = OmitCreateSchema(limaDealEntitySchema);

export const limaDealPartyUpdate = Type.Union([
  limaDealIndividualUpdate,
  limaDealEntityUpdate
]);
