import { LOSUser } from "@elphi/types";
import { Dispatch, EntityId, Update } from "@reduxjs/toolkit";
import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";
import { losUserSlice } from "./losUser.slice";
const slice = losUserSlice;
export const losUserAction: ActionDispatcher<LOSUser> = (
  dispatch: Dispatch,
  callback?: {
    add?: (r: LOSUser[]) => void;
    update?: (r: Update<LOSUser>[]) => void;
    remove?: (r: EntityId[]) => void;
  }
) => {
  const actionDispatcher = buildAction(slice);
  return actionDispatcher(dispatch, callback);
};

