import { BaseEntity, FirestoreTimestampFieldUnionType } from "../db";
import { CamelCaseKeys } from "../utils";
import { DealMilestoneType, LenderIdentifierType } from "./deal.enums.types";
import { Deal, DealReadonlyField } from "./deal.types";
import {
  BaseDealPartyRelation,
  DealPartyRelationType,
  LoanPartyEntityRoleType,
  LoanPartyIndividualRoleType
} from "./dealParty.types";
import {
  BaseParty,
  EntityParty,
  IndividualParty,
  LOCStatusType,
  PartyMilestoneType,
  PartyType,
  TaxpayerIdentifierType
} from "./party.types";
import { Property } from "./property.types";
import { Task } from "./task.types";

export type DealCalendar = BaseEntity<DealCalendarType>;

export type DealCalendarType = Partial<DealCalendarFields>;

type DealCalendarDateType = {
  totalDealsCalendar: number;
  totalRequestedLoanAmount: number;
};

export type DealCalendarDealMapDateType = {
  id: string;
  deals: DealCalendarTypeById;
} & DealCalendarDateType;

export type DealCalendarDealListDateType = {
  deals: DealCalendarType[];
  dealsIds: string[];
} & DealCalendarDateType;

export type DealCalendarDateItem = Partial<{
  milestone: DealMilestoneType;
  lenderIdentifier: LenderIdentifierType;
  assignedUsers: string[];
  dealParties: string[];
  moveTo: string;
  createDateAt: string;
}> &
  DealCalendarDealId &
  CamelCaseKeys<DealReadonlyField>;

export type DealCalendarFields = DealCalendarDealFields &
  DealCalendarPropertyFields &
  DealCalendarCustomFields &
  DealCalendarDateItem;

type DealCalendarCustomFields = Partial<{
  action: CalendarActionStatus;
  dealCalendarCreatedAt: FirestoreTimestampFieldUnionType;
  totalCostAmount: string;
  previousEstimatedClosingDate: string;
  modifiedAt: FirestoreTimestampFieldUnionType;
  primaryBorrowers?: string[];
  primarySponsors?: string[];
  secondaryBorrowers?: string[];
  secondarySponsors?: string[];
  taskLength: number;
  parties?: DealCalendarPartyById;
  tasks?: DealCalendarTaskById;
  previousRequestedLoanAmount?: string;
}>;

type DealCalendarDealFields = CamelCaseKeys<
  Pick<Deal, "LoanIdentifier" | "LoanName" | "createdAt">
>;

type DealCalendarDealId = Pick<Deal, "id">;

type DealCalendarPropertyFields = Pick<Property, "Address">;

export type DealCalendarParty = Partial<{
  partyId: string;
  relationRoleType: LoanPartyEntityRoleType[] | LoanPartyIndividualRoleType[];
  dealPartyType: DealPartyRelationType;
  partyType: PartyType;
  dealPartyRelationId: string;
  locStatusType: LOCStatusType;
  partyMilestone: PartyMilestoneType;
  dealPartyFieldMeta: BaseDealPartyRelation<DealPartyRelationType>["fieldMeta"];
  partyFieldMeta: BaseParty<PartyType, TaxpayerIdentifierType>["fieldMeta"];
}> &
  CamelCaseKeys<Partial<PartyPick>>;

type PartyPick = Pick<
  IndividualParty,
  "FirstName" | "LastName" | "MiddleName"
> &
  Pick<EntityParty, "FullName">;

export type DealCalendarPartyById = {
  [id: string]: DealCalendarParty;
};

export type DealCalendarTaskById = {
  [id: string]: Task;
};

export type DealCalendarTypeById = {
  [id: string]: DealCalendarType;
};

export const DocumentDateTypesToDealIds = "0000_dateTypesToDealIdsMap";

export type DealCalendarCollectionPath = "closingDate" | "createdAt";

export enum CalendarActionStatus {
  Create = "Create",
  Update = "Update",
  PrepareMove = "PrepareMove",
  Move = "Move",
  CreateClosingDate = "CreateClosingDate",
  ChangeLoanAmount = "ChangeLoanAmount"
}
