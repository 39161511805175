import { Task } from "@elphi/types";
import { Dispatch, EntityId, Update } from "@reduxjs/toolkit";
import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";
import { taskSlice } from "./task.slice";

export const taskAction: ActionDispatcher<Task> = (
  dispatch: Dispatch,
  callback?: {
    add?: (r: Task[]) => void;
    update?: (r: Update<Task>[]) => void;
    remove?: (r: EntityId[]) => void;
  }
) => {
  const slice = taskSlice;
  const actionDispatcher = buildAction(slice);
  return actionDispatcher(dispatch, callback);
};

