import { Type } from "@sinclair/typebox";
import {
  AutoGenerateType,
  ElphiEntityType,
  FieldStatus,
  KeyClosingTaskType,
  SwitchValueType
} from "../entities";
import { BaseEntitySchema } from "./baseEntity.typebox";
import { OmitUpdateSchema } from "./service.typebox";
import { EnumField, StringField } from "./utils.typebox";

const FieldSelection = Type.Record(
  Type.String(),
  Type.Object({
    fieldPath: Type.String(),
    status: Type.Optional(EnumField(FieldStatus)),
    index: Type.Number()
  })
);

const elphiEntityType = Object.values(ElphiEntityType);
export const EntitiesFieldSelection = Type.Object(
  Object.fromEntries(
    elphiEntityType.map((entityType) => [entityType, FieldSelection])
  )
);

const TaskConfigurationFieldsSchema = Type.Object({
  taskTemplateId: StringField,
  autoGenerate: EnumField(AutoGenerateType),
  keyClosingTaskType: EnumField(KeyClosingTaskType),
  id: StringField,
  taskRuleTemplateId: StringField,
  status: EnumField(SwitchValueType),
  name: StringField,
  taskEntity: EnumField(ElphiEntityType),
  dataToOrder: Type.Optional(EntitiesFieldSelection),
  dataToVerify: Type.Optional(EntitiesFieldSelection)
});

export const TaskConfigurationUpdate = Type.Intersect([
  OmitUpdateSchema(BaseEntitySchema),
  Type.Required(TaskConfigurationFieldsSchema)
]);
