import { connect } from "react-redux";
import { firebase } from "../../../firebase/firebaseConfig";
import { useLOSUserHooks } from "../../../hooks/losuser.hooks";
import { Sidebar } from "../components/Sidebar";

const signOutUser = () => {
  return firebase.auth().signOut();
};

const sidebarContainer = (props) => {
  const { updateLOSStatusApi } = useLOSUserHooks();
  return (
    <Sidebar
      {...props}
      signOutUser={() =>
        updateLOSStatusApi("away").then(async () => {
          return await signOutUser().catch((error) => {
            // TODO handle errors
            console.log(error);
          });
        })
      }
    />
  );
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  roles: state.userData.roles
});

export const SidebarContainer =  connect(mapStateToProps)(sidebarContainer);
