export type Meta = {
  status?: FieldStatus;
};

type FieldMetaBuilder<T extends object, U = any> = {
  [P in keyof T]?: T[P] extends Array<any>
    ? Meta
    : T[P] extends object
    ? U extends T[P]
      ? never
      : FieldMetaBuilder<T[P], T[P]>
    : Meta;
};

export type FieldMeta<T extends object, U = any> = {
  fieldMeta?: FieldMetaBuilder<T, U>;
};

export enum FieldStatus {
  None = "Pending",
  Approved = "Approved",
  Denied = "Denied",
  AttentionRequired = "Attention Required"
}

export enum SectionStatus {
  InvalidRequired = "Invalid Required",
  Override = "Override Fields"
}

/////////////
//
//  example
//
// type Foo = {
//   x: {
//     y: string[];
//   };
// };

// const meta: FieldMeta<Foo> = {
//   x: {
//     y: {
//       status: FieldStatus.Approved
//     }
//   }
// };
