// Need to use the React-specific entry point to allow generating React hooks
import { Property } from "@elphi/types";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../../config/appConfig";
import { RootState } from "../../../store";
import { serviceBuilder } from "../../builders/api.builder";
import { propertyEntityAdapter } from "../../property/property.adapter";

export const propertySelector = propertyEntityAdapter.getSelectors<RootState>(
  (state) => state.property
);
// Define a service using a base URL and expected endpoints
export const baseServiceLinkFloodApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "serviceLinkFloodApi",
  tagTypes: ["Flood", "Integration", "ServiceLink", "Property"],
  baseQuery: serviceBuilder.baseQuery({
    route: "integration"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: propertyEntityAdapter
  })
});

export const serviceLinkFloodApi = baseServiceLinkFloodApi.injectEndpoints({
  endpoints: (builder) => ({
    //TODO delete
    createServiceLink: builder.mutation<
      Property,
      { dealId: string; propertyId: string }
    >({
      query: (r) => {
        return {
          url: `/flood/cert/service-link/create`,
          method: "POST",
          body: r
        };
      }
    }),
    //TODO delete
    quickCheckServiceLink: builder.mutation<
      Property,
      { dealId: string; propertyId: string }
    >({
      query: (r) => {
        return {
          url: `/flood/cert/service-link/quick-check`,
          method: "POST",
          body: r
        };
      }
    }),
    //TODO delete
    changeServiceLink: builder.mutation<
      Property,
      { dealId: string; propertyId: string }
    >({
      query: (r) => {
        return {
          url: `/flood/cert/service-link/change`,
          method: "POST",
          body: r
        };
      }
    }),
    createOrder: builder.mutation<
      { taskId: string },
      { propertyId: string; dealId: string; taskId: string; option: string }
    >({
      query: (r) => {
        return {
          url: `/flood/cert/service-link/create`,
          method: "POST",
          body: r
        };
      }
    })
  })
});
