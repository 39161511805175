import { TObject, TProperties, Type } from "@sinclair/typebox";
import { BaseEntitySchema } from "./baseEntity.typebox";
import { OmitCreateSchema, OmitUpdateSchema } from "./service.typebox";
import { ArrayField, StringField } from "./utils.typebox";

const DealUserFieldsSchema = Type.Object({
  dealId: StringField,
  userId: StringField,
  roleIds: Type.Optional(ArrayField(StringField))
});

export const DealUserUpdate = Type.Intersect([
  OmitUpdateSchema(BaseEntitySchema),
  Type.Required(DealUserFieldsSchema)
]);

const DealUserCreate = Type.Intersect([
  OmitCreateSchema(BaseEntitySchema),
  Type.Required(DealUserFieldsSchema)
]);

export const getOperationDealUserSchema = (
  op: "create" | "update"
): TObject<TProperties> => {
  return op === "create" ? DealUserCreate : DealUserUpdate;
};

export const UserAssignmentFullReplaceRequest = Type.Object({
  dealId: StringField,
  dealUserIds: ArrayField(StringField)
});
