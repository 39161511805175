import { Statement } from "@elphi/types";
import { Dispatch, EntityId, Update } from "@reduxjs/toolkit";
import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";
import { statementSlice } from "./statement.slice";

export const statementAction: ActionDispatcher<Statement> = (
  dispatch: Dispatch,
  callback?: {
    add?: (r: Statement[]) => void;
    update?: (r: Update<Statement>[]) => void;
    remove?: (r: EntityId[]) => void;
  }
) => {
  const slice = statementSlice;
  const actionDispatcher = buildAction(slice);
  return actionDispatcher(dispatch, callback);
};

