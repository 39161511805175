import {
  LabelValue,
  RolodexServiceProviderView,
  StatusCode
} from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { EntityId } from "@reduxjs/toolkit";
import { orderBy } from "lodash";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { responseHandler } from "../../../../apis/rtk/response.handler";
import { RTKResponse } from "../../../../apis/rtk/response.types";
import { useElphiToast } from "../../../../components/toast/toast.hook";
import { EMPTY } from "../../../../constants/common";
import { useDealHooks } from "../../../../hooks/deal.hooks";
import { usePartyHooks } from "../../../../hooks/party.hooks";
import { useRolodexConfiguration } from "../../../../hooks/rolodexConfiguration.hooks";
import { RootState } from "../../../../redux/store";
import {
  ServiceProviderViewFilterState,
  serviceProviderViewApi,
  serviceProviderViewSlice
} from "../../../../redux/v2/rolodex/service-provider-view";

export const serviceProviderListHooks = () => {
  const dispatch = useDispatch();
  const { errorToast } = useElphiToast();
  const { selectedDeal } = useDealHooks();
  const { selectedOrHighlightedParty: selectedParty } = usePartyHooks();

  const {
    configurationState,
    getPaginateConfigurations,
    getPaginateConfigurationsResponse
  } = useRolodexConfiguration();

  const serviceProviderViewState = useSelector(
    (state: RootState) => state.serviceProviderView
  );

  const setCurrentFilter = (filter: ServiceProviderViewFilterState) => {
    dispatch(serviceProviderViewSlice.actions.setFilter(filter));
  };

  const currentFilter = useSelector(
    (state: RootState) => state.serviceProviderView.current.filter
  );

  const [getAllApi, getAllResponse] =
    serviceProviderViewApi.useLazyGetAllQuery();

  const getAllHandler = () => {
    getAllApi(
      {
        dealId: currentFilter.entityId,
        partyId: currentFilter.partyId
      },
      true
    )
      .then((r) => responseHandler(r as RTKResponse<typeof r.data>))
      .then((r) => {
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to get",
            description: r.data?.description
          });
        }
      });
  };

  const filterIdsByDealId = () => {
    const { ids } = serviceProviderViewState;
    if (!selectedDeal) {
      return [];
    }
    return ids.filter(
      (id) =>
        serviceProviderViewState.entities[id]?.dealEntities?.[selectedDeal.id]
    );
  };

  const filterIdsByPartyId = () => {
    const { ids } = serviceProviderViewState;
    if (!selectedParty) {
      return [];
    }
    return ids.filter((id) =>
      serviceProviderViewState.entities[id]?.partyIds?.includes(
        selectedParty.id
      )
    );
  };

  const configurationOptions = useMemo(() => {
    return Object.values(configurationState.entities)
      .filter(removeEmpty)
      .map((x) => {
        const lv: LabelValue = {
          label: `${x.name} - ${x.status}`,
          value: x.id
        };
        return lv;
      });
  }, [configurationState.entities]);

  const filteredItems = useCallback(
    (filterIds: () => EntityId[]): RolodexServiceProviderView[] => {
      const configurations = new Set(currentFilter.domainConfigurations);
      const statuses = new Set(currentFilter.statuses);

      const ids = filterIds();
      const filtered = ids
        .map((id) => {
          const entity = serviceProviderViewState.entities[id];
          if (!entity) {
            return undefined;
          }

          const { domainConfigurationId, status, keywords } = entity;
          if (
            (!currentFilter.freeText ||
              keywords?.includes(currentFilter.freeText.toLowerCase())) &&
            (configurations.size === 0 ||
              (domainConfigurationId &&
                configurations.has(domainConfigurationId))) &&
            (statuses.size === 0 ||
              (status && status.some((x) => statuses.has(x))))
          ) {
            const domainConfigurationName =
              configurationState.entities[
                entity?.domainConfigurationId || EMPTY
              ]?.name;
            return {
              ...entity,
              domainConfigurationName
            };
          }
          return undefined;
        })
        .filter(removeEmpty);

      return orderBy(filtered, (item) => item.domainConfigurationId, "desc");
    },
    [
      currentFilter.freeText,
      currentFilter.domainConfigurations,
      currentFilter.statuses,
      serviceProviderViewState.entities,
      configurationState.entities,
      selectedDeal,
      selectedParty
    ]
  );

  const filteredByDealId = useMemo(
    () => filteredItems(filterIdsByDealId),
    [filteredItems]
  );

  const filteredByPartyId = useMemo(
    () => filteredItems(filterIdsByPartyId),
    [filteredItems]
  );

  return {
    serviceProviderViewState,
    configurationOptions,
    setCurrentFilter,
    currentFilter,
    filteredItems,
    getPaginateConfigurations,
    getPaginateConfigurationsResponse,
    getAllResponse,
    getAllHandler,
    configurationState,
    filteredByDealId,
    filteredByPartyId
  };
};
