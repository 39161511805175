import { configureStore } from "@reduxjs/toolkit";
import LogRocket from "logrocket";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { createMigrate, persistReducer, persistStore } from "redux-persist";
import { MigrationManifest } from "redux-persist/es/types";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { rootReducer, rtkMiddleware } from "./reducers";

const REDUX_PERSIST_VERSION = 1;

const reduxPersistMigrations: MigrationManifest = {
  // A migration will be applied when its key (version number) falls within the range defined by inboundVersion and currentVersion.
  //The key must be an integer value.
  [REDUX_PERSIST_VERSION]: (state) => {
    return {
      ...state,
      _persist: state?._persist || {
        rehydrated: true,
        version: REDUX_PERSIST_VERSION
      },
      tableManager: undefined
    };
  }
};

// Middleware: Redux Persist Config
const persistConfig = {
  // Root
  key: "root",
  // Storage Method
  storage,
  // Setting timeout to null because of timeout bug using redux persist
  timeout: undefined,
  // Whitelist (Save Specific Reducers)
  whitelist: ["userData", "tableManager"],
  // Blacklist (Don't Save Specific Reducers)
  blacklist: [],
  version: REDUX_PERSIST_VERSION,
  migrate: createMigrate(reduxPersistMigrations, { debug: false })
};

// Persisted root reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Redux store
const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: [
    thunk,
    LogRocket.reduxMiddleware({
      stateSanitizer: function (_) {
        return {};
      },
      actionSanitizer: function (action) {
        return { type: action.type };
      }
    }),
    ...rtkMiddleware
  ]
});

// Persistor for redux store
const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// Exports
export { persistor, store };
