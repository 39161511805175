import { Td, Text, Tr } from "@chakra-ui/react";

export enum AggregationColumnTypes {
  String = "string",
  Aggregation = "aggregation",
  Empty = "empty"
}
type AggregationFooterProps = {
  columns: {
    columnType: AggregationColumnTypes;
    input?: JSX.Element | string | undefined;
  }[];
  width: string;
};

export const AggregationFooter = (props: AggregationFooterProps) => {
  return (
    <Tr>
      {props.columns.map((c, i) => {
        return (
          <Td minW={props.width} maxW={props.width} w={props.width} key={i}>
            {c.columnType === AggregationColumnTypes.String ? (
              <Text fontWeight="bold">{c.input}</Text>
            ) : c.columnType ===
              AggregationColumnTypes.Empty ? null : c.columnType ===
              AggregationColumnTypes.Aggregation ? (
              <>{c.input}</>
            ) : null}
          </Td>
        );
      })}
    </Tr>
  );
};
