import {
  PosInviteRequest,
  PosPublishChangesRequest,
  PosPublishToUserRequest,
  PosUnpublishFromUserRequest,
  StatusCode
} from "@elphi/types";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { RTKResponse } from "../apis/rtk/response.types";
import { useElphiToast } from "../components/toast/toast.hook";
import { RootState } from "../redux/store";
import { posDealUserApi } from "../redux/v2/pos";
import { useDealHooks } from "./deal.hooks";

export const usePosDealUserPartyHooks = () => {
  const { successToast, errorToast } = useElphiToast();
  const { selectedDeal } = useDealHooks();
  const [publishApi, publishResponse] = posDealUserApi.usePublishMutation();
  const [publishChangesApi, publishChangesResponse] =
    posDealUserApi.usePublishChangesMutation();
  const [unpublishApi, unpublishResponse] =
    posDealUserApi.useUnpublishMutation();
  const [inviteApi, inviteResponse] = posDealUserApi.useInviteMutation();
  const [dealUserPartyViewGetApi, dealUserPartyViewResponse] =
    posDealUserApi.useLazyGetQuery();

  const dealUserPartyViewState = useSelector(
    (state: RootState) => state.posDealUserPartyView
  );

  const handleGetDealUserPartyView = async (dealId: string) => {
    return await dealUserPartyViewGetApi({ dealId }, true)
      .then((r) => responseHandler(r as RTKResponse<typeof r.data>))
      .then((r) => {
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to get deal pos users",
            description: r.data?.description
          });
        }
        return r;
      });
  };

  const handlePublishDeal = async (r: PosPublishToUserRequest) => {
    return await publishApi(r)
      .then(responseHandler)
      .then((r) => {
        if (r.status === StatusCode.OK) {
          successToast({
            title: "Deal published successfully",
            description: r.data?.id
          });
        }
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to publish deal",
            description: r.data?.description
          });
        }
        return r;
      });
  };

  const handlePublishChanges = async (r: PosPublishChangesRequest) => {
    return await publishChangesApi(r)
      .then(responseHandler)
      .then((r) => {
        if (r.status === StatusCode.OK) {
          successToast({
            title: "Changes published successfully",
            description: r.data?.id
          });
        }
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to publish changes",
            description: r.data?.description
          });
        }
        return r;
      });
  };

  const handleUnpublishDeal = async (r: PosUnpublishFromUserRequest) => {
    return await unpublishApi(r)
      .then(responseHandler)
      .then((r) => {
        if (r.status === StatusCode.OK) {
          successToast({
            title: "Deal unpublished successfully",
            description: r.data?.id
          });
        }
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to unpublish deal",
            description: r.data?.description
          });
        }
        return r;
      });
  };

  const handleInviteUser = async (r: PosInviteRequest) => {
    return await inviteApi(r)
      .then(responseHandler)
      .then((r) => {
        if (r.status === StatusCode.OK) {
          successToast({
            title: "Invitation sent successfully",
            description: r.data?.response?.posUser?.email
          });
        }
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to send invitation",
            description: r.data?.description
          });
        }
        return r;
      });
  };

  const filteredViews = useMemo(() => {
    if (!selectedDeal) return [];
    return Object.values(dealUserPartyViewState?.entities || {}).filter(
      (v) => v?.dealId === selectedDeal.id
    );
  }, [selectedDeal, dealUserPartyViewState?.entities]);

  return {
    filteredViews,
    dealUserPartyViewState,
    handleGetDealUserPartyView,
    dealUserPartyViewResponse,
    handlePublishDeal,
    handleUnpublishDeal,
    publishResponse,
    unpublishResponse,
    handlePublishChanges,
    publishChangesResponse,
    handleInviteUser,
    inviteResponse
  };
};
