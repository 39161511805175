import {
  AGGREGATIONS,
  BaseEntity,
  FieldMeta,
  FieldStatus,
  FieldType,
  Meta
} from "@elphi/types";
import { getRelevantFields } from "@elphi/types/typebox/utils/fieldMeta.utils";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { get, set } from "lodash";
import { createOptionsFromMap } from "../../../utils/formUtils";
import { EntityFormFieldSpecs, InputBuilderFieldSpecs } from "../fields.types";

const statusField: DotNestedKeys<Meta> = "status";
const OVERRIDE = "override";
const fieldMetaField: DotNestedKeys<FieldMeta<object>> = "fieldMeta";

const createStatusSpec = (r: {
  path: string;
  specInfo: InputBuilderFieldSpecs<any[]>;
}) => {
  const { path, specInfo } = r;
  const pathParts = path.split(".");
  const statusSpec: InputBuilderFieldSpecs<typeof pathParts> = {
    fieldKey: [fieldMetaField, ...pathParts, statusField],
    fieldType: FieldType.SingleSelect,
    label: `${specInfo.label} Status`,
    options: createOptionsFromMap(FieldStatus)
  };
  return { [statusField]: statusSpec };
};

const buildSpecFieldMetadata = <T extends Partial<BaseEntity<object>>>(
  specs: EntityFormFieldSpecs<T>,
  paths: string[]
) => {
  return paths.reduce((acc, path) => {
    const isAggregation = path.includes(AGGREGATIONS);
    const key = isAggregation ? `${path}.${OVERRIDE}` : path;
    const statusSpec = createStatusSpec({
      path,
      specInfo: get(specs, key)
    });
    set(acc, path, statusSpec);
    return acc;
  }, {});
};

export const createSpecWithFieldMeta = <
  T extends EntityFormFieldSpecs<object>
>(r: {
  spec: T;
  fieldsToOmit?: DotNestedKeys<T>[];
  fieldsToPick?: DotNestedKeys<T>[];
}): T & { fieldMeta: FieldMeta<object> } => {
  const { spec, fieldsToOmit, fieldsToPick } = r;
  const relevantPaths = getRelevantFields({
    obj: spec,
    fieldsToOmit,
    fieldsToPick
  });
  const fieldMeta = buildSpecFieldMetadata(spec, relevantPaths);
  return { ...spec, fieldMeta };
};
