import { FirebaseFilter, NotificationConfiguration } from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import { serviceBuilder } from "../builders/api.builder";
import { notificationConfigurationAdapter } from "./notificationConfiguration.adapter";

export type NotificationConfigurationPaginateV2Response = {
  nextCursor?: string;
  hasMore: boolean;
  page: NotificationConfiguration[];
};

export const baseNotificationConfigurationApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "notificationConfigurationApi",
  tagTypes: ["Notification", "Configuration", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "notification-configuration"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: notificationConfigurationAdapter
  })
});

export const notificationConfigurationApi =
  baseNotificationConfigurationApi.injectEndpoints({
    endpoints: (builder) => ({
      updateBatch: builder.mutation<
        { batch: string[] },
        {
          configurations: ({
            id: string;
          } & Partial<NotificationConfiguration>)[];
        }
      >({
        query: (r) => {
          return {
            url: `/update-batch`,
            method: "PUT",
            body: r
          };
        }
      }),
      search: builder.query<
        {
          query: string;
          hasMore: boolean;
          nextCursor: string | null;
          results: EntityState<NotificationConfiguration>;
        },
        { query: string; cursor?: string }
      >({
        query: (r) => {
          return {
            url: `/search?q=${r.query}${r.cursor ? `&cursor=${r.cursor}` : ""}`,
            method: "GET"
          };
        },
        transformResponse: (
          response: {
            results: NotificationConfiguration[];
            nextCursor: string | null;
            hasMore: boolean;
          },
          _,
          arg
        ) => {
          const adapter = notificationConfigurationAdapter.addMany(
            notificationConfigurationAdapter.getInitialState(),
            response.results
          );
          return {
            query: arg.query,
            results: adapter,
            nextCursor: response.nextCursor,
            hasMore: response.hasMore
          };
        }
      }),
      paginateV2: builder.query<
        NotificationConfigurationPaginateV2Response,
        {
          cursor?: string;
          options?: {
            order?: "desc" | "asc";
            filter?: FirebaseFilter | FirebaseFilter[];
          };
        }
      >({
        query: (r) => {
          return {
            url: `/paginate`,
            method: "POST",
            body: r
          };
        }
      })
    })
  });
