import { Type } from "@sinclair/typebox";
import { DealPartyRelationType } from "../../entities";
import { baseLoanPartyIndividualRole } from "../../entities/base";
import { limaLoanPartyEntityRoleType } from "../../entities/lima";
import { BaseEntitySchema } from "../baseEntity.typebox";
import { ArrayField, EnumField, StringField } from "../utils.typebox";

const BaseDealPartyRelationSchema = (type: DealPartyRelationType) =>
  Type.Intersect([
    BaseEntitySchema,
    Type.Object({
      type: Type.Literal(type),
      dealId: StringField,
      partyId: StringField
    })
  ]);

export const elphiDealIndividualSchema = Type.Intersect([
  BaseDealPartyRelationSchema(DealPartyRelationType.DealIndividual),
  Type.Object({
    relationRoleType: ArrayField(EnumField(baseLoanPartyIndividualRole))
  })
]);

export const elphiDealEntitySchema = Type.Intersect([
  BaseDealPartyRelationSchema(DealPartyRelationType.DealEntity),
  Type.Object({
    relationRoleType: ArrayField(EnumField(limaLoanPartyEntityRoleType))
  })
]);
