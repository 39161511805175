import { Type } from "@sinclair/typebox";
import {
  DocumentEntityType,
  DocumentPackageType,
  OrderStatusType,
  RulesEngineDecision
} from "../entities";
import { BaseEntitySchema } from "./baseEntity.typebox";
import { OmitUpdateSchema } from "./service.typebox";
import { ArrayField, EnumField, StringField } from "./utils.typebox";

export const OrderItemFieldsSchema = Type.Object({
  rulesEngineDecision: EnumField(RulesEngineDecision),
  ruleTemplateId: StringField,
  documentEntity: EnumField(DocumentEntityType),
  documentName: StringField,
  entityId: StringField,
  configurationId: StringField
});

const OrderItemsFieldsSchema = Type.Optional(
  Type.Object({
    configuration_id: Type.Optional(
      Type.Object({
        deal: Type.Optional(
          Type.Object({
            entity_id: OrderItemFieldsSchema
          })
        ),
        party: Type.Optional(
          Type.Object({
            entity_id: OrderItemFieldsSchema
          })
        ),
        property: Type.Optional(
          Type.Object({
            entity_id: OrderItemFieldsSchema
          })
        )
      })
    )
  })
);

const DocumentPackageOrderFieldsSchema = Type.Object({
  type: EnumField(DocumentPackageType),
  dealId: StringField,
  userId: StringField,
  order: Type.Object({
    status: EnumField(OrderStatusType),
    items: OrderItemsFieldsSchema,
    error: Type.Optional(StringField)
    //data TODO type all the object
  })
});

export const DocumentPackageOrderUpdate = Type.Intersect([
  OmitUpdateSchema(BaseEntitySchema),
  Type.Required(DocumentPackageOrderFieldsSchema)
]);

export const GenerateDocumentFromTemplateRequest = Type.Object({
  dealId: StringField,
  propertyIds: ArrayField(StringField),
  partyIds: ArrayField(StringField),
  dealPartyIds: Type.Optional(ArrayField(StringField)),
  configurationIds: ArrayField(StringField)
});
