import { LoanpassFieldType } from "../event-models";
import {
  BaseLoanpassFieldsMapping,
  LoanpassDurationField,
  LoanpassEnumField,
  LoanpassField,
  LoanpassNumberField,
  LoanpassStringField
} from "./loanpassFields.types";

export enum LoanpassArrayExtractionMethods {
  GetAll = "all",
  GetFirst = "first",
  GetLast = "last",
  Select = "select"
}

type EnumField = LoanpassEnumField & {
  optionMaps: {
    [option: string]: string;
  };
};

type LoanpassToElphiField =
  | LoanpassStringField
  | LoanpassNumberField
  | LoanpassDurationField
  | LoanpassInArrayField
  | EnumField;

type PickingMethod<T extends LoanpassArrayExtractionMethods> = {
  pickMethod?: T;
};

type NestedFieldMapping = {
  nestedField: {
    path: string;
  } & LoanpassToElphiField;
};

type PositionalExtraction = PickingMethod<
  | LoanpassArrayExtractionMethods.GetFirst
  | LoanpassArrayExtractionMethods.GetLast
  | LoanpassArrayExtractionMethods.GetAll
> &
  NestedFieldMapping;

type SelectPickingMethod =
  PickingMethod<LoanpassArrayExtractionMethods.Select> &
    NestedFieldMapping & { selectorField: string; selectorValue: string };

type ExtractionConfig = PositionalExtraction | SelectPickingMethod;
export type LoanpassInArrayField = LoanpassField<LoanpassFieldType.Array> &
  ExtractionConfig;

export type LoanpassToElphiMappingType = BaseLoanpassFieldsMapping &
  LoanpassToElphiField;
