import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../../config/appConfig";
import { RootState } from "../../../store";
import { serviceBuilder } from "../../builders/api.builder";
import { taskTemplateEntityAdapter } from "../../task-template/taskTemplate.adapter";
export const taskTemplateSelector =
  taskTemplateEntityAdapter.getSelectors<RootState>(
    (state) => state.taskTemplate
  );

export const baseFundingShieldApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "fundingShieldApi",
  tagTypes: ["FundingShield", "Integration", "Task"],
  baseQuery: serviceBuilder.baseQuery({
    route: "integration/wire-insurance/funding-shield"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: taskTemplateEntityAdapter
  })
});

export const fundingShieldApi = baseFundingShieldApi.injectEndpoints({
  endpoints: (builder) => ({
    createOrderCertificate: builder.mutation<
      { taskId: string },
      { dealId: string; taskId: string }
    >({
      query: (r) => {
        return {
          url: `/create`,
          method: "POST",
          body: r
        };
      }
    }),
    retryFailedOrderCertificate: builder.mutation<
      { taskId: string },
      { taskId: string }
    >({
      query: (r) => {
        return {
          url: `/retry`,
          method: "POST",
          body: r
        };
      }
    })
  })
});
