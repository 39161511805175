// Need to use the React-specific entry point to allow generating React hooks
import { DocumentGenerationTemplate, OrderStatusType } from "@elphi/types";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import { serviceBuilder } from "../builders/api.builder";

// Define a service using a base URL and expected endpoints
export const documentGenerationApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "documentGenerationApi",
  tagTypes: ["DocumentGeneration"],
  baseQuery: serviceBuilder.baseQuery({
    route: "document"
  }),
  endpoints: (builder) => ({
    generate: builder.mutation<
      {
        file: Blob;
      },
      {
        dealId?: string;
        propertyIds?: string[];
        partyIds?: string[];
        templateKeys?: (keyof typeof DocumentGenerationTemplate)[];
      }
    >({
      query: (r) => {
        return {
          url: `/generate`,
          method: "POST",
          responseHandler: async (response) => {
            try {
              if (response.status === 200) {
                return await response.blob();
              }
              console.log("generate failed status: ", response.status);
              try {
                return await response.json();
              } catch (err) {
                console.log("response is not a json");
              }
              try {
                return await response.text();
              } catch (err) {
                console.log("response is not a text");
              }
              try {
                const bin = await response.arrayBuffer();
                return Buffer.from(bin).toString();
              } catch (err) {
                console.log("response is not an array buffer");
              }
              return "generate failed";
            } catch (err) {
              console.log("Error generate: ", err);
              return "generate failed";
            }
          },
          cache: "no-cache",
          body: r
        };
      },
      transformResponse: (file: Blob) => {
        return {
          file
        };
      }
    }),
    generateDocumentsFromSource: builder.mutation<
      { url?: string; status?: OrderStatusType },
      {
        dealId: string;
        docPackageSrcId: string;
      }
    >({
      query: (r) => {
        return {
          url: `/generate-documents-from-source`,
          method: "POST",
          body: r
        };
      },
      transformResponse: (response: {
        result: { url?: string; status?: OrderStatusType };
      }) => {
        return { ...response.result };
      }
    })
  })
});
