import { PosUser } from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../../config/appConfig";
import { serviceBuilder } from "../../builders/api.builder";
import { posUserAdapter } from "./posUser.adapter";

export const basePosUserApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "posUser",
  tagTypes: ["PosUser", "Search"],
  baseQuery: serviceBuilder.baseQuery({
    route: "pos-user"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: posUserAdapter
  })
});

export const posUserApi = basePosUserApi.injectEndpoints({
  endpoints: (builder) => ({
    getByPartyId: builder.query<
      { result: PosUser | undefined },
      { partyId: string }
    >({
      query: (r) => {
        return {
          url: `/get-by-party-id/${r.partyId}`,
          method: "GET"
        };
      }
    }),
    search: builder.query<
      {
        query: string;
        hasMore: boolean;
        nextCursor: string | null;
        results: EntityState<PosUser>;
      },
      { query: string; cursor?: string }
    >({
      query: (r) => {
        return {
          url: `/search?q=${r.query}${r.cursor ? `&cursor=${r.cursor}` : ""}`,
          method: "GET"
        };
      },
      transformResponse: (
        response: {
          results: PosUser[];
          nextCursor: string | null;
          hasMore: boolean;
        },
        _,
        arg
      ) => {
        const adapter = posUserAdapter.addMany(
          posUserAdapter.getInitialState(),
          response.results
        );
        return {
          query: arg.query,
          results: adapter,
          nextCursor: response.nextCursor,
          hasMore: response.hasMore
        };
      }
    })
  })
});
