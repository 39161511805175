import { CopyIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, IconButton, Tooltip } from "@chakra-ui/react";
import {
  ElphiTrxStatus,
  FieldType,
  IntegrationType,
  PropertyReportVendorType,
  SiteXTrxStatus,
  StatusCode
} from "@elphi/types";
import { getFocusedData } from "@elphi/utils";
import { isEmpty, isNil, omitBy, some, values } from "lodash";
import { useEffect, useState } from "react";
import { elphiTheme } from "../../../../../assets/themes/elphi.theme.default";
import { EMPTY } from "../../../../../constants/common";
import { useStepRequiredHooks } from "../../../../../hooks/integrationCenter.hooks";
import { usePropertyReportTaskHooks } from "../../../../../hooks/propertyReportTask.hooks";
import { PropertyStructureState } from "../../../../../hooks/propertyform.hooks";
import { ScrollableSections } from "../../../../ScrollableSections";
import FormBuilder, {
  SectionHeader
} from "../../../../form-builder/FormBuilder";
import { StyledInputBuilder } from "../../../../form-builder/InputBuilder";
import { RotateLeft, WindWhiteIcon } from "../../../../icons";
import { useElphiToast } from "../../../../toast/toast.hook";
import { createOptionsFromMap } from "../../../../utils/formUtils";
import { IntegrationTaskHeaderForm } from "../../IntegrationTaskHeaderForm";
import { BUTTON_TOOLTIP_MESSAGES } from "../../integration.type";
import { VendorTypeTaskFormProps } from "../PropertyReportTaskForm";
import { ElphiLocationResponse, ServiceOption } from "../propertyReport.type";
import { SiteXOrderModal, SiteXOrderModalType } from "./SiteXOrderModal";
type SiteXTaskFormProps = {} & VendorTypeTaskFormProps;
export const SiteXTaskForm = ({
  snapshotId,
  selectedTask,
  state,
  propertyOnChangeBatch,
  selectedPropertyState,
  sections
}: SiteXTaskFormProps) => {
  const [sectionValidationState, setSectionValidationState] = useState<{
    [index: string]: boolean;
  }>({});
  const [prepareOrderedSiteXModal, setPrepareOrderedSiteXModal] =
    useState<boolean>(false);
  const [orderedSiteXModalType, setOrderedSiteXModalType] = useState<
    SiteXOrderModalType | undefined
  >();
  const { updatePropertiesHandler } = usePropertyReportTaskHooks();
  const { infoToast, successToast } = useElphiToast();
  const { disableStepRequired, syncState } = useStepRequiredHooks();
  const {
    getPropertyReportVendorTask,
    handleOnCreateOrder,
    createSiteXReportApiResponse,
    handleOnRetryOrder,
    retrySiteXReportApiResponse,
    getOrderStatusTooltip
  } = usePropertyReportTaskHooks();
  const siteXTask = getPropertyReportVendorTask(
    selectedTask,
    PropertyReportVendorType.SiteX
  )!;

  const [serviceSelection, setServiceSelection] = useState<ServiceOption>(
    ServiceOption.SiteXReport
  );

  //we only need the first section (index 0) to be valid in the form to enable the order button
  const isAllValid = sectionValidationState[0];

  useEffect(() => {
    syncState({
      shouldSync: !!selectedTask,
      state: selectedTask,
      statePath: () => {
        return ["tasks", selectedTask.id];
      }
    });
  }, [selectedTask]);

  useEffect(() => {
    onResetState();
    if (siteXTask?.stepRequired === "additionalCost") {
      setOrderedSiteXModalType(SiteXOrderModalType.AlreadySuccessfullyOrdered);
    }
    if (siteXTask?.stepRequired === "multiLocations") {
      setOrderedSiteXModalType(SiteXOrderModalType.Multi);
    }
  }, [selectedTask]);

  useEffect(() => {
    if (
      siteXTask.elphiTrxStatus !== ElphiTrxStatus.InProcess &&
      prepareOrderedSiteXModal
    ) {
      onShowOrderedSiteXModal();
    }
  }, [siteXTask.locations]);

  const onResetState = () => {
    setOrderedSiteXModalType(undefined);
    setPrepareOrderedSiteXModal(false);
  };

  const onShowOrderedSiteXModal = () => {
    const isMultiLocations =
      siteXTask?.locations &&
      siteXTask.locations.length > 1 &&
      siteXTask.failedLabel === "failedMultiLocations";
    const isSuccessWithLocation =
      siteXTask?.locations &&
      siteXTask.locations?.length === 1 &&
      siteXTask.elphiTrxStatus === ElphiTrxStatus.Success;

    if (isMultiLocations) {
      infoToast({
        title: "Multiple Property Records Returned",
        description:
          "Your property search returned multiple matches. Please select the record you are interested in from the list"
      });
      setTimeout(() => {
        setOrderedSiteXModalType(SiteXOrderModalType.Multi);
      }, 0);
      setPrepareOrderedSiteXModal(false);
    } else if (isSuccessWithLocation) {
      const { addr, lastLine } = getSelectedPropertyState;

      successToast({
        title: "Property Report Received",
        description: `Property Report for ${addr}, ${lastLine} was received`
      });
      onResetState();
    }
  };

  const getSelectedPropertyState = {
    addr: `${selectedPropertyState?.Address?.AddressLineText}${
      selectedPropertyState?.Address?.AddressUnitIdentifier
        ? `, ${selectedPropertyState?.Address?.AddressUnitIdentifier}`
        : EMPTY
    }`,
    lastLine: `${selectedPropertyState?.Address?.CityName}, ${selectedPropertyState?.Address?.StateCode} ${selectedPropertyState?.Address?.PostalCode}`
  };

  const onCloseModal = () => {
    onResetState();
    if (siteXTask?.stepRequired) {
      disableStepRequired(selectedTask.id);
    }
  };

  const onCreateOrder = async (location?: ElphiLocationResponse) => {
    onResetState();
    const createOrder = await handleOnCreateOrder(
      serviceSelection,
      selectedPropertyState,
      selectedTask.id,
      location
    );
    if (createOrder?.status === StatusCode.OK) {
      setPrepareOrderedSiteXModal(true);
    }
  };

  const onCopy = async () => {
    const propertyAddress =
      selectedPropertyState?.aggregations?.Integrations?.[
        IntegrationType.PropertyReport
      ]?.[PropertyReportVendorType.SiteX];
    const updateProperties: Partial<PropertyStructureState> | {} =
      selectedPropertyState?.id
        ? {
            properties: {
              [selectedPropertyState.id]: {
                Address: omitBy(
                  {
                    AddressLineText: getFocusedData(
                      propertyAddress?.addressLineText
                    ),
                    AddressUnitIdentifier: getFocusedData(
                      propertyAddress?.addressUnitIdentifier
                    ),
                    CityName: getFocusedData(propertyAddress?.cityName),
                    CountyName: getFocusedData(propertyAddress?.countyName),
                    StateCode: getFocusedData(propertyAddress?.stateCode),
                    PostalCode: getFocusedData(propertyAddress?.postalCode)
                  },
                  isNil
                ),
                ParcelNumber: getFocusedData(
                  propertyAddress?.assessorParcelNumber
                )
              }
            }
          }
        : {};
    await updatePropertiesHandler(updateProperties, {
      successToastTitle: "Copy/pasted property report address to property data"
    });
  };

  const showCopyButton = some(
    values(
      selectedPropertyState?.aggregations?.Integrations?.[
        IntegrationType.PropertyReport
      ]?.[PropertyReportVendorType.SiteX]
    ),
    (value) => !isEmpty(getFocusedData(value))
  );

  const onOrder = () => {
    onResetState();
    const isFirstTime =
      selectedTask.integrationType === IntegrationType.PropertyReport &&
      selectedTask.vendorType === PropertyReportVendorType.SiteX &&
      selectedTask.siteXTrxStatus !== SiteXTrxStatus.Ok;
    if (!isFirstTime) {
      setOrderedSiteXModalType(SiteXOrderModalType.AlreadySuccessfullyOrdered);
    } else {
      onCreateOrder();
    }
  };
  const tooltipLabel = !!serviceSelection
    ? !isAllValid
      ? BUTTON_TOOLTIP_MESSAGES.validationError
      : BUTTON_TOOLTIP_MESSAGES?.[siteXTask?.elphiTrxStatus]
    : BUTTON_TOOLTIP_MESSAGES.chooseService;

  const shouldOrderButtonDisable =
    !isAllValid || !serviceSelection || !siteXTask.isReadyToOrder;
  return (
    <>
      <ScrollableSections
        customKey={"siteXTaskForm"}
        sections={[
          {
            header: (
              <SectionHeader
                header={
                  <IntegrationTaskHeaderForm
                    task={selectedTask}
                    elphiTrxStatus={siteXTask.elphiTrxStatus}
                    getOrderStatusTooltip={getOrderStatusTooltip}
                  />
                }
              />
            ),
            body: (
              <Flex>
                <Box w="250px" mr="50px">
                  <StyledInputBuilder
                    options={createOptionsFromMap(ServiceOption)}
                    label="Choose Service"
                    onChange={(e) => setServiceSelection(e.target.value)}
                    isClearable={true}
                    fieldType={FieldType.SingleSelect}
                    currentValue={serviceSelection}
                  />
                </Box>
                <Box>
                  <Tooltip label={tooltipLabel}>
                    <Button
                      iconSpacing={0}
                      leftIcon={<WindWhiteIcon boxSize="8" pt="8px" />}
                      disabled={shouldOrderButtonDisable}
                      onClick={onOrder}
                      isLoading={createSiteXReportApiResponse.isLoading}
                      color="white"
                      {...elphiTheme.components.light.button.scaleChart}
                    >
                      Order
                    </Button>
                  </Tooltip>
                </Box>
                {siteXTask?.elphiTrxStatus === ElphiTrxStatus.Failed &&
                  siteXTask?.failedLabel === "failedUploadingReport" && (
                    <Box>
                      <Tooltip label={BUTTON_TOOLTIP_MESSAGES.retryToOrder}>
                        <IconButton
                          ml={5}
                          isLoading={retrySiteXReportApiResponse.isLoading}
                          variant={"ghost"}
                          aria-label="retry-button"
                          onClick={() =>
                            handleOnRetryOrder(
                              serviceSelection,
                              selectedTask.id
                            )
                          }
                          icon={<RotateLeft boxSize="10" pr="1px" pt="5px" />}
                        />
                      </Tooltip>
                    </Box>
                  )}
                {showCopyButton && (
                  <Box>
                    <Tooltip
                      placement="top"
                      label={BUTTON_TOOLTIP_MESSAGES.copyOrPaste}
                    >
                      <Button
                        ml={5}
                        leftIcon={<CopyIcon />}
                        onClick={onCopy}
                        isLoading={createSiteXReportApiResponse.isLoading}
                        {...elphiTheme.components.light.button.primary}
                      >
                        Copy/Paste Report Address to System
                      </Button>
                    </Tooltip>
                  </Box>
                )}
              </Flex>
            )
          }
        ]}
      />
      <>
        {state?.tasks && (
          <FormBuilder
            setSectionValidationState={setSectionValidationState}
            customKey="siteXSections"
            isDisabled={!!snapshotId}
            size={{
              minW: "300px"
            }}
            onChange={propertyOnChangeBatch}
            sections={sections}
          />
        )}
      </>
      <SiteXOrderModal
        isShow={!!orderedSiteXModalType}
        onCloseModal={onCloseModal}
        selectedPropertyState={selectedPropertyState}
        onCreateOrder={onCreateOrder}
        modalType={orderedSiteXModalType}
        data={siteXTask.locations}
      />
    </>
  );
};
