import { useCallbackRef } from "@chakra-ui/react";
import { Deal, DealProperty, DealUser, Property } from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { AppConfig } from "../../../../config/appConfig";
import { useDealHooks } from "../../../../hooks/deal.hooks";
import { useDealPropertyRelationHooks } from "../../../../hooks/dealPropertyRelation.hooks";
import { useDealUserRelationHooks } from "../../../../hooks/dealuser.hooks";
import { usePropertyHooks } from "../../../../hooks/property.hooks";
import { RootState } from "../../../../redux/store";
import {
  PropertySliceState,
  propertySlice
} from "../../../../redux/v2/property";
import { getListFromDictionary } from "../../../../utils/batchUtils";
import { useFormBuilderStateHandler } from "../../../form-builder/InputBuilder";
import { useElphiToast } from "../../../toast/toast.hook";

type PropertyTableStateToUpdate = {
  properties: { [id: string]: { id: string } & Partial<Property> };
  deals: { [id: string]: { id: string } & Partial<Deal> };
};
type PropertyTableState = {
  propertyState: PropertySliceState;
  dealPropertyState: EntityState<DealProperty>;
  dealUserState: EntityState<DealUser>;
  dealState: EntityState<Deal>;
};
export type HookOptions = {
  config: { isNonMutation: boolean };
};

export const usePropertyTableState = (r: { options?: HookOptions } = {}) => {
  const isNonMutation = r.options?.config?.isNonMutation ?? true;
  const { errorToast, successToast } = useElphiToast();
  const dispatch = useDispatch();

  const {
    propertyState,
    updateBatchProperties,
    setPropertyTableCursor,
    getPropertyTableCursor
  } = usePropertyHooks();
  const { dealPropertyRelationState } = useDealPropertyRelationHooks();
  const { dealState, updateBatch: updateDealBatch } = useDealHooks();
  const { dealUserRelationState } = useDealUserRelationHooks();
  const updatePropertyBatchHandler = useCallbackRef(
    async (v: Partial<PropertyTableStateToUpdate>) => {
      if (!v.properties) return null;

      const properties = getListFromDictionary(v.properties);
      if (!properties.length) return null;

      return await updateBatchProperties({ properties } as {
        properties: ({
          id: string;
        } & Partial<Property>)[];
      }).then((r) => {
        if (r.status === 200) {
          successToast({
            title: "Properties Updated",
            description: `total properties updated: ${properties.length}`
          });
        }
        if (r.status === 400) {
          errorToast({
            title: "Failed to update batch",
            description: r.data.description
          });
        }
        return r;
      });
    }
  );
  const {
    state: localPropertyState,
    onChange: formBuilderPropertyOnChange,
    syncState: syncPropertyState
  } = useFormBuilderStateHandler({
    initialState: { properties: {} } as {
      properties: { [id: string]: Property };
    },
    callback: updatePropertyBatchHandler,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });
  const handleUpdateDealBatch = async (
    tableState: Partial<PropertyTableStateToUpdate>
  ) => {
    if (!tableState.deals) return null;
    const listDeals = getListFromDictionary(tableState?.deals);
    if (!listDeals.length) return null;
    const filteredDeals = listDeals.filter((d): d is Deal => !!d);
    return await updateDealBatch({ deals: filteredDeals }).then((r) => {
      if (r.status === 200) {
        successToast({
          title: "Deals Updated",
          description: `total deals updated: ${listDeals.length}`
        });
      }
      r.status === 400 &&
        errorToast({
          title: "Failed to update batch",
          description: r.data.description
        });
      return r;
    });
  };
  const {
    state: localDealState,
    syncState: syncDealState,
    onChange: dealOnChange
  } = useFormBuilderStateHandler({
    initialState: { deals: {} } as { deals: { [id: string]: Deal } },
    callback: handleUpdateDealBatch,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });
  const propertyTableState: PropertyTableState = {
    propertyState,
    dealPropertyState: dealPropertyRelationState,
    dealUserState: dealUserRelationState,
    dealState
  };
  const propertyOnChange = isNonMutation
    ? formBuilderPropertyOnChange
    : undefined;

  const setCurrentCursorHasMore = (hasMore: boolean) => {
    dispatch(
      propertySlice.actions.setPropertyTableCurrentCursorIdHasMore(hasMore)
    );
  };
  const setCurrentCursorId = (cursorId: string) => {
    dispatch(
      propertySlice.actions.setPropertyTableCurrentCursorId({ id: cursorId })
    );
  };
  const currentCursorIdHasMore = useSelector((state: RootState) =>
    state?.property?.propertyTable
      ? state.property.propertyTable.cursor.hasMore[
          state.property.propertyTable.cursor.currentId
        ]
      : false
  );
  return {
    setCurrentCursorId,
    currentCursorIdHasMore,
    setCurrentCursorHasMore,
    localDealState,
    syncDealState,
    dealOnChange,
    localPropertyState,
    propertyOnChange,
    syncPropertyState,
    propertyState,
    dealPropertyState: dealPropertyRelationState,
    dealState,
    dealUserState: dealUserRelationState,
    setPropertyTableCursor,
    getPropertyTableCursor,
    propertyTableState
  };
};
