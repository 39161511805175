import { DocumentRuleTemplate } from "@elphi/types";
import { Dispatch, EntityId, Update } from "@reduxjs/toolkit";
import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";
import { documentRuleSlice } from "./documentRule.slice";

export const documentRuleAction: ActionDispatcher<DocumentRuleTemplate> = (
  dispatch: Dispatch,
  callback?: {
    add?: (r: DocumentRuleTemplate[]) => void;
    update?: (r: Update<DocumentRuleTemplate>[]) => void;
    remove?: (r: EntityId[]) => void;
  }
) => {
  const slice = documentRuleSlice;
  const actionDispatcher = buildAction(slice);
  return actionDispatcher(dispatch, callback);
};
