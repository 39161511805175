import { Type } from "@sinclair/typebox";

const TimestampObjectSchema = Type.Union([
  Type.Object({
    nanoseconds: Type.Number(),
    seconds: Type.Number()
  }),
  Type.Object({
    _nanoseconds: Type.Number(),
    _seconds: Type.Number()
  })
]);

const TimestampSchema = Type.Object({
  createdAt: TimestampObjectSchema,
  modifiedAt: TimestampObjectSchema
});

export const BaseEntitySchema = Type.Intersect([
  Type.Object({
    id: Type.String(),
    index: Type.String()
  }),
  TimestampSchema
]);
