import { TObject, TProperties, TSchema, Type } from "@sinclair/typebox";
import {
  Party,
  PartyType,
  TaxpayerIdentifierType,
  getPartyTemplate
} from "../../entities";
import {
  mofinEntityType,
  mofinOperationsDepartment
} from "../../entities/mofin/party/mofinParty.enumeration";
import {
  elphiBaseSchemaEntityParty,
  elphiBaseSchemaIndividualParty
} from "../base/party.typebox";
import { BaseEntitySchema } from "../baseEntity.typebox";
import {
  AggregationFieldSchema,
  DateField,
  Deprecated,
  EnumField,
  LiteralField,
  MoneyAmountFieldString,
  UnionField
} from "../utils.typebox";
import { FieldMetaTarget, buildFieldMetadata } from "../utils/fieldMeta.utils";

const BasePartyAggregationsSchema = Type.Object({
  BackgroundReportExpirationDate: AggregationFieldSchema(DateField)
});

const IndividualPartyAggregationsSchema = Type.Object({
  TotalStatementQualifyingBalance: AggregationFieldSchema(
    MoneyAmountFieldString
  ),
  LOCExpirationDate: AggregationFieldSchema(DateField)
});
const EntityPartyAggregationsSchema = Type.Object({
  TotalStatementQualifyingBalance: Deprecated(
    AggregationFieldSchema(MoneyAmountFieldString)
  )
});

const BasePartySchema = <TType extends TSchema, TTaxIdentifier extends TSchema>(
  partyType: TType,
  taxpayerIdentifierType: TTaxIdentifier
) =>
  Type.Intersect([
    BaseEntitySchema,
    Type.Object({
      PartyType: partyType,
      TaxpayerIdentifierType: taxpayerIdentifierType,
      OperationsDepartment: EnumField(mofinOperationsDepartment)
    })
  ]);

const entityPartyFieldMetaSchema = buildFieldMetadata<
  Party,
  TObject<TProperties>
>({
  entityTemplate: getPartyTemplate(PartyType.Entity),
  target: FieldMetaTarget.Schema
});

const EntityPartyFieldsSchema = Type.Object({
  aggregations: Type.Partial(
    Type.Intersect([BasePartyAggregationsSchema, EntityPartyAggregationsSchema])
  ),
  FieldMeta: Type.Partial(entityPartyFieldMetaSchema),
  EntityType: EnumField(mofinEntityType)
});

export const mofinEntityPartySchema = Type.Intersect([
  BasePartySchema(
    LiteralField(PartyType.Entity),
    LiteralField(TaxpayerIdentifierType.EIN)
  ),
  elphiBaseSchemaEntityParty,
  EntityPartyFieldsSchema
]);

const individualFieldMetaSchema = buildFieldMetadata<
  Party,
  TObject<TProperties>
>({
  entityTemplate: getPartyTemplate(PartyType.Individual),
  target: FieldMetaTarget.Schema
});

const IndividualPartyFieldsSchema = Type.Object({
  aggregations: Type.Partial(
    Type.Intersect([
      BasePartyAggregationsSchema,
      IndividualPartyAggregationsSchema
    ])
  ),
  FieldMeta: Type.Partial(individualFieldMetaSchema)
});

export const mofinIndividualPartySchema = Type.Intersect([
  BasePartySchema(
    LiteralField(PartyType.Individual),
    UnionField([
      LiteralField(TaxpayerIdentifierType.SSN),
      LiteralField(TaxpayerIdentifierType.TIN)
    ])
  ),
  elphiBaseSchemaIndividualParty,
  IndividualPartyFieldsSchema
]);
