import { Type } from "@sinclair/typebox";
import { BaseEntitySchema } from "./baseEntity.typebox";
import { OmitUpdateSchema } from "./service.typebox";
import { TaskFieldsSchema } from "./task.typebox";
import { StringField } from "./utils.typebox";

const TaskTemplateFieldsSchema = Type.Object({
  template: Type.Omit(TaskFieldsSchema, [
    "id",
    "templateId",
    "dataToVerify",
    "dataToOrder"
  ]),
  id: StringField
});

export const TaskTemplateUpdate = Type.Intersect([
  OmitUpdateSchema(BaseEntitySchema),
  Type.Required(TaskTemplateFieldsSchema)
]);
