import { Box, Button, Flex } from "@chakra-ui/react";
import { FieldType, Property } from "@elphi/types";

import { elphiTheme } from "../../assets/themes/elphi.theme.default";
import { usePropertyHooks } from "../../hooks/property.hooks";
import { CreateInsurancePolicyButton } from "../insurance-policy/CreateInsurancePolicyButton";
import { AddPropertyModal } from "../property/AddProperty";
import {
  DeleteDealPropertyRelationModal,
  DeletePropertyModal
} from "../property/DeleteProperty";
import { DealPropertySearchContainer } from "../property/PropertySearch";

export const DealPropertySearchFormWidget = (props: {
  dealId: string;
  selectedProperty?: Property;
  snapshotId?: string;
  setSelectedProperty: ReturnType<
    typeof usePropertyHooks
  >["setSelectedProperty"];
}) => {
  const { selectedProperty, setSelectedProperty } = props;

  return (
    <Flex justifyContent={"space-between"} w="100%">
      <DealPropertySearchContainer
        snapshotId={props.snapshotId}
        dealId={props.dealId}
        fieldType={FieldType.SingleSelect}
        currentValue={selectedProperty ? selectedProperty.id : ""}
        onSelect={setSelectedProperty}
      />
      <Flex p="10px" gap="10px">
        <Box>
          <DeletePropertyModal
            injectedProperty={selectedProperty || ({} as Property)}
            buttonFn={(r) => (
              <Button
                {...elphiTheme.components.light.button.secondary}
                onClick={r?.onClick}
                isDisabled={!!props.snapshotId || !selectedProperty}
              >
                Delete Property
              </Button>
            )}
          />
        </Box>
        <Box>
          <DeleteDealPropertyRelationModal
            buttonFn={(onOpen) => (
              <Button
                {...elphiTheme.components.light.button.secondary}
                onClick={onOpen}
                isDisabled={!!props.snapshotId || !selectedProperty}
              >
                Remove Property From Deal
              </Button>
            )}
          />
        </Box>
        <Box>
          <AddPropertyModal
            buttonFn={(onOpen) => (
              <Button
                isDisabled={!!props.snapshotId}
                {...elphiTheme.components.light.button.primary}
                onClick={onOpen}
              >
                Add Property
              </Button>
            )}
          />
        </Box>
        <CreateInsurancePolicyButton dealId={props.dealId} />
      </Flex>
    </Flex>
  );
};
