import { Deal } from "@elphi/types";
import { useEffect } from "react";
import { useFormBuilderStateHandler } from "../components/form-builder/InputBuilder";
import { useElphiToast } from "../components/toast/toast.hook";
import { AppConfig } from "../config/appConfig";
import { useDealHooks } from "./deal.hooks";

export const useDealFormHandler = (dealId: string) => {
  const { selectedDeal, updateDeal } = useDealHooks();
  const { successToast, errorToast } = useElphiToast();

  useEffect(() => {
    if (selectedDeal) {
      syncDealState({
        shouldSync: !!selectedDeal,
        state: selectedDeal
      });
    }
  }, [selectedDeal]);

  const updateDealHandler = async (diff: Partial<Deal>) => {
    if (dealId) {
      diff.id = dealId;
    }

    return await updateDeal(diff as { id: string } & Partial<Deal>).then(
      (r) => {
        if (r.status === 200) {
          successToast({
            title: "Deal Updated",
            description: `deal: ${r.data.id}`
          });
        }
        if (r?.status === 400) {
          errorToast({
            title: "Failed to update deal",
            description:
              r.data?.error?.data?.error?.payload?.description ||
              r.data?.description
          });
        }
        return r;
      }
    );
  };

  const {
    onChange: dealOnChange,
    state: dealState,
    setState: setDealState,
    diffState: dealDiffState,
    setDiffState: setDealDiffState,
    syncState: syncDealState
  } = useFormBuilderStateHandler<Deal>({
    initialState: {} as Deal,
    callback: updateDealHandler,
    callbackOptions: {
      debounceRate: AppConfig.debounceRate,
      clearDiff: true
    }
  });
  return {
    dealOnChange,
    dealState,
    setDealState,
    dealDiffState,
    setDealDiffState,
    updateDealHandler,
    selectedDeal: selectedDeal || undefined
  };
};
