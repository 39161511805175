// Need to use the React-specific entry point to allow generating React hooks

import { CreditReportPOSTV2, OmitCreate } from "@elphi/types";
import { createApi } from "@reduxjs/toolkit/query/react";
import {
  CreditScore,
  PartyCreditScore
} from "../../../../../../shared/types/entities/creditReport.types";
import { AppConfig } from "../../../config/appConfig";
import { serviceBuilder } from "../builders/api.builder";
import { creditReportEntityAdapter } from "./creditReport.adapter";

// Define a service using a base URL and expected endpoints
export const creditReportBaseApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "creditReportApi",
  tagTypes: ["creditReport", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "credit-report"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: creditReportEntityAdapter
  })
});

export const creditReportApi = creditReportBaseApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    create: builder.mutation<CreditScore, OmitCreate<CreditScore>>({
      query: (r) => {
        return {
          url: `/`,
          method: "POST",
          body: r
        };
      }
    }),
    getCreditReport: builder.mutation<
      object,
      {
        body: CreditReportPOSTV2;
      }
    >({
      query: (r) => {
        return {
          url: `/getCreditReport`,
          method: "POST",
          body: { body: r.body }
        };
      }
    }),
    getCreditScore: builder.mutation<
      { creditScore: CreditScore; pdf: { type: string; data: Buffer } },
      {
        body: CreditReportPOSTV2;
        partyId: string;
      }
    >({
      query: (r) => {
        return {
          url: `/getCreditScore`,
          method: "POST",
          body: { partyId: r.partyId, body: r.body }
        };
      }
    }),
    getBatchPartyCreditScores: builder.query<
      {
        creditScores: CreditScore[];
        relations: PartyCreditScore[];
      },
      {
        partyIds: string[];
      }
    >({
      query: (r) => {
        return {
          url: `/getBatchPartyCreditScores`,
          method: "POST",
          body: { partyIds: r.partyIds }
        };
      }
    }),
    getLatestCreditScore: builder.query<CreditScore, string>({
      query: (query) => {
        return {
          url: `/getLatestCreditScore/${query}`,
          method: "GET"
        };
      }
    }),

    batchUpdate: builder.mutation<
      { batch: string[] },
      { creditScores: ({ id: string } & Partial<CreditScore>)[] }
    >({
      query: (r) => {
        return {
          url: `/update-batch`,
          method: "PUT",
          body: r
        };
      }
    })
  })
});
