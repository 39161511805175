import { Type } from "@sinclair/typebox";
import {
  ActionType,
  AutoGenerateType,
  ElphiEntityType,
  KeyClosingTaskType,
  TaskStatusType,
  TaskType
} from "../entities";
import { BaseEntitySchema } from "./baseEntity.typebox";
import { OmitUpdateSchema } from "./service.typebox";
import { EntitiesFieldSelection } from "./taskConfiguration.typebox";
import {
  ArrayField,
  Deprecated,
  EnumField,
  StringField
} from "./utils.typebox";

export enum UserRoleType {
  LineOfCreditOperationAnalyst = "LOC Ops Analyst",
  OperationAnalyst = "Ops Analyst",
  SeniorOperationAnalyst = "Senior Ops Analyst"
}

export const TaskFieldsSchema = Type.Object({
  templateName: StringField,
  ruleId: StringField,
  availableStatus: ArrayField(EnumField(TaskStatusType)),
  id: StringField,
  name: StringField,
  dataToVerify: Type.Optional(EntitiesFieldSelection),
  storageMeta: Type.Optional(
    Type.Object({ box: Type.Object({ folderId: StringField }) })
  ),
  dataToOrder: Type.Optional(EntitiesFieldSelection),
  comments: Type.Optional(StringField),
  type: EnumField(TaskType),
  assignableBy: ArrayField(EnumField(UserRoleType)),
  editableBy: ArrayField(EnumField(UserRoleType)),
  availableActions: Deprecated(ArrayField(EnumField(ActionType))),
  entityId: StringField,
  entityType: EnumField(ElphiEntityType),
  generation: StringField,
  keyClosingTaskType: EnumField(KeyClosingTaskType),
  templateId: StringField,
  checklistOf: ArrayField(EnumField(UserRoleType)),
  taskStatus: EnumField(TaskStatusType),
  autoGenerate: EnumField(AutoGenerateType)
});

export const TaskUpdate = Type.Intersect([
  OmitUpdateSchema(BaseEntitySchema),
  Type.Required(TaskFieldsSchema)
]);
