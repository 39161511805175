import { DocumentPackageOrder } from "@elphi/types";
import { Dispatch, EntityId } from "@reduxjs/toolkit";
import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";
import { documentPackageOrderSlice } from "./documentPackageOrder.slice";

export const documentPackageOrderAction: ActionDispatcher<
  DocumentPackageOrder
> = (
  dispatch: Dispatch,
  callback?: {
    add?: (r: Partial<DocumentPackageOrder> & { id: EntityId }[]) => void;
    update?: (r: Partial<DocumentPackageOrder> & { id: EntityId }[]) => void;
    remove?: (r: EntityId[]) => void;
  }
) => {
  const slice = documentPackageOrderSlice;
  const actionDispatcher = buildAction(slice);
  return actionDispatcher(dispatch, callback);
};
