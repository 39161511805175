import { Box, Flex } from "@chakra-ui/react";
import { EMPTY } from "../../../../../constants/common";
import { useDealHooks } from "../../../../../hooks/deal.hooks";
import { useLoanpassHooks } from "../../pricingEngine/loanpass.hooks";

export const DataSubmitionModalBody = () => {
  const { selectedDeal } = useDealHooks();
  const { selectedPipelineRecord } = useLoanpassHooks();
  const loanIdentifier = selectedDeal ? selectedDeal.LoanIdentifier : EMPTY;
  const pipelineRecordName = selectedPipelineRecord
    ? selectedPipelineRecord.name
    : EMPTY;
  return (
    <Flex direction="column" gap={8}>
      <Flex direction="column" gap={2}>
        <Box>By submitting, you will override any existing values:</Box>
        <Box>
          From: loan{" "}
          <Box as="span" fontWeight={"600"}>
            {loanIdentifier}
          </Box>
        </Box>
        <Box>
          To: pipeline record{" "}
          <Box as="span" fontWeight={"600"}>
            {pipelineRecordName}
          </Box>
        </Box>
      </Flex>
      <Flex direction="column" gap={4}>
        <Box fontWeight={"600"}>Are you sure you want to proceed?</Box>
        <Box fontWeight={"600"} textColor={"red"}>
          This operation is irreversible.
        </Box>
      </Flex>
    </Flex>
  );
};
