import { BaseEntity, FirebaseFilter, PartyGroup } from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { isString } from "lodash";
import { AppConfig } from "../../../config/appConfig";
import { serviceBuilder } from "../builders/api.builder";
import { partyGroupAdapter } from "./partyGroup.adapter";

export type partyGroupPaginateV2Response = {
  nextCursor?: string;
  hasMore: boolean;
  page: PartyGroup[];
};

export const basePartyGroupApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "partyGroupApi",
  tagTypes: ["partyGroup", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "party-group"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: partyGroupAdapter
  })
});

export const partyGroupApi = basePartyGroupApi.injectEndpoints({
  endpoints: (builder) => ({
    getBatch: builder.query<{ batch: EntityState<PartyGroup> }, string[]>({
      query: (ids) => {
        return {
          url: `/get-batch`,
          method: "POST",
          body: { ids }
        };
      },
      transformResponse: (response: { results: PartyGroup[] }) => {
        const adapter = partyGroupAdapter.addMany(
          partyGroupAdapter.getInitialState(),
          response.results
        );

        return { batch: adapter };
      }
    }),
    updateBatch: builder.mutation<
      { batch: string[] },
      {
        excludedIds?: string[];
        updateDetails?: Omit<Partial<PartyGroup>, keyof BaseEntity<object>>;
        partyGroups?: ({ id: string } & Partial<PartyGroup>)[];
        filters?: FirebaseFilter[];
      }
    >({
      query: (r) => {
        return {
          url: `/update-batch`,
          method: "PUT",
          body: r
        };
      }
    }),
    search: builder.query<
      {
        results: EntityState<PartyGroup>;
        hasMore: boolean;
        nextCursor: string | null;
        query: string;
      },
      { query: string; cursor?: string | null } | string
    >({
      query: (r) => {
        return {
          url: `/search?q=${isString(r) ? r : r.query}${
            !isString(r) && r.cursor ? `&cursor=${r.cursor}` : ""
          }`,
          method: "GET"
        };
      },
      transformResponse: (
        response: {
          results: PartyGroup[];
          hasMore: boolean;
          nextCursor: string | null;
        },
        _,
        arg
      ) => {
        const adapter = partyGroupAdapter.addMany(
          partyGroupAdapter.getInitialState(),
          response.results
        );
        return {
          query: isString(arg) ? arg : arg.query,
          results: adapter,
          hasMore: response.hasMore,
          nextCursor: response.nextCursor
        };
      }
    }),
    paginateV2: builder.query<
      partyGroupPaginateV2Response,
      {
        cursor?: string;
        options?: {
          order?: "desc" | "asc";
          filter?: FirebaseFilter | FirebaseFilter[];
        };
      }
    >({
      query: (r) => {
        return {
          url: `/paginate`,
          method: "POST",
          body: r
        };
      }
    })
  })
});
