import { TObject, TProperties, Type } from "@sinclair/typebox";
import {
  ARMRepriceLookbackType,
  ARMRepriceRoundingMethodType,
  ARMRepriceRoundingRatePercentType,
  CapitalStructureType,
  CollateralPackageStatusType,
  Deal,
  DealExceptionApprovedBy,
  DealMilestoneType,
  ExceptionType,
  IndexSourceType,
  InterestAccrualMethodType,
  LenderEntityType,
  LoanProgramType,
  PricingEngineExceptionStatusType,
  PropertyInsurancePremiumPaymentType,
  PropertyModelType,
  PropertyRightsOwnershipType,
  RealEstateProgramType,
  ShippingCompanyType,
  TitleVestingType,
  UnderwriterApprovalStatusType,
  WarehouseBankStatusType,
  getDealTemplate
} from "../../entities";
import {
  baseAggregationLoanProductType,
  baseAssignmentStatus,
  baseChannelType,
  baseDefaultStatus,
  baseFloodInsurancePremiumPayment,
  baseInterestType,
  baseLoanPayment,
  baseLoanProductType,
  baseLoanTermPeriodMonthCount,
  basePaymentDueDay,
  basePrepaymentPenaltyType
} from "../../entities/base";
import { BaseEntitySchema } from "../baseEntity.typebox";
import { BaseAddressSchema, StateNameSchema } from "../location.typebox";
import {
  AggregationFieldSchema,
  ArrayField,
  BooleanField,
  DateField,
  Deprecated,
  EmailField,
  EnumField,
  IntegerField,
  MoneyAmountField,
  NumberField,
  PercentField,
  PhoneNumberField,
  StringField,
  ThirdPartyFieldSchema,
  ThreeDecimalPercentField
} from "../utils.typebox";
import { FieldMetaTarget, buildFieldMetadata } from "../utils/fieldMeta.utils";

const dealLedgerSchema = Type.Object({
  AppraisalDepositAmount: MoneyAmountField,
  AppraisalFeeAmount: MoneyAmountField,
  CreditReportFeeAmount: MoneyAmountField,
  InterestReserveAmount: MoneyAmountField,
  NewYorkProcessingFeeAmount: MoneyAmountField,
  LenderCounselFeeAmount: MoneyAmountField,
  ContractorReviewFeeAmount: MoneyAmountField,
  FeasibilityReviewFeeAmount: MoneyAmountField,
  CondoCertificationFeeAmount: MoneyAmountField
});

const baseMilestoneData = <TType extends DealMilestoneType>(type: TType) =>
  Type.Object({
    type: Type.Literal(type),
    timestamp: StringField
  });

const milestoneChangeReasonData = <TType extends DealMilestoneType>(
  type: TType
) =>
  Type.Intersect([
    baseMilestoneData(type),
    Type.Object({
      genericReason: StringField,
      specificReason: StringField
    })
  ]);

const deadDealMilestoneData = milestoneChangeReasonData(
  DealMilestoneType.DeadDeal
);

const suspendedMilestoneData = milestoneChangeReasonData(
  DealMilestoneType.Suspended
);

const allowEmptyObject = (milestoneData: DealMilestoneType) =>
  Type.Optional(
    Type.Intersect([baseMilestoneData(milestoneData), Type.Object({})])
  );

const elphiDealMetadataSchema = Type.Object({
  milestone: EnumField(DealMilestoneType),
  organizations: ArrayField(StringField),
  branches: ArrayField(StringField),
  teams: ArrayField(StringField),
  activeUsers: ArrayField(StringField),
  milestoneData: Type.Optional(
    Type.Object({
      [DealMilestoneType.DeadDeal]: Type.Optional(
        Type.Intersect([deadDealMilestoneData, Type.Object({})])
      ),
      [DealMilestoneType.Suspended]: Type.Optional(
        Type.Intersect([suspendedMilestoneData, Type.Object({})])
      ),
      [DealMilestoneType.ClearToClose]: allowEmptyObject(
        DealMilestoneType.ClearToClose
      ),
      [DealMilestoneType.CloseOfEscrow]: allowEmptyObject(
        DealMilestoneType.CloseOfEscrow
      ),
      [DealMilestoneType.FileComplete]: allowEmptyObject(
        DealMilestoneType.FileComplete
      ),
      [DealMilestoneType.FinalClosingUpdatesRequested]: allowEmptyObject(
        DealMilestoneType.FinalClosingUpdatesRequested
      ),
      [DealMilestoneType.FinalDealReview]: allowEmptyObject(
        DealMilestoneType.FinalDealReview
      ),
      [DealMilestoneType.FinalDealReviewApproved]: allowEmptyObject(
        DealMilestoneType.FinalDealReviewApproved
      ),
      [DealMilestoneType.FinalQuoteAccepted]: allowEmptyObject(
        DealMilestoneType.FinalQuoteAccepted
      ),
      [DealMilestoneType.FinalQuoteIssued]: allowEmptyObject(
        DealMilestoneType.FinalQuoteIssued
      ),
      [DealMilestoneType.Funded]: allowEmptyObject(DealMilestoneType.Funded),
      [DealMilestoneType.InitialDealReview]: allowEmptyObject(
        DealMilestoneType.InitialDealReview
      ),
      [DealMilestoneType.LoanPackageReceived]: allowEmptyObject(
        DealMilestoneType.LoanPackageReceived
      ),
      [DealMilestoneType.LoanPackageSent]: allowEmptyObject(
        DealMilestoneType.LoanPackageSent
      ),
      [DealMilestoneType.NewDeal]: allowEmptyObject(DealMilestoneType.NewDeal),
      [DealMilestoneType.Processing]: allowEmptyObject(
        DealMilestoneType.Processing
      )
    })
  )
});

const attorneySchema = Type.Object({
  FullName: StringField
});

const titleCompanySchema = Type.Intersect([
  BaseAddressSchema,
  Type.Object({
    Attorney: Type.Partial(attorneySchema),
    FullName: StringField,
    ClosingAgentFirstName: StringField,
    ClosingAgentLastName: StringField,
    ClosingAgentContactPointTelephoneValue: PhoneNumberField,
    ClosingAgentContactPointEmailValue: EmailField,
    TitleInsuranceUnderwriter: StringField
  })
]);

const elphiTitleCommitmentSchema = Type.Object({
  TitleEffectiveDate: DateField,
  TitleExpirationDate: DateField,
  TitlePolicyAmount: MoneyAmountField,
  TitleVestingType: EnumField(TitleVestingType),
  AllSubjectPropertiesIncludedIndicator: BooleanField,
  TitleCommitmentComments: StringField,
  PropertyRightsOwnershipType: EnumField(PropertyRightsOwnershipType)
});

const lenderSchema = Type.Intersect([
  Type.Object({
    FormationState: StateNameSchema,
    EntityType: EnumField(LenderEntityType)
  }),
  BaseAddressSchema
]);

const elphiDealExceptionsSchema = Type.Object({
  ExceptionApprovedBy: EnumField(DealExceptionApprovedBy),
  ExceptionCommentary: StringField,
  ExceptionType: ArrayField(EnumField(ExceptionType))
});

//TODO: change Deal to BaseSchemaDeal
const fieldMetaSchema = buildFieldMetadata<Deal, TObject<TProperties>>({
  entityTemplate: getDealTemplate(),
  target: FieldMetaTarget.Schema
});

const integrationMetadataSchema = Type.Object({
  salesforceAccountId: StringField,
  salesforceDealId: StringField,
  customerPortalId: StringField
});

const quoteFieldsSchema = Type.Object({
  aggregations: Type.Partial(
    Type.Object({
      PrimaryGuarantor: AggregationFieldSchema(StringField),
      SumTotalStatementQualifyingBalance:
        AggregationFieldSchema(MoneyAmountField),
      LesserOfLotOrPurchase: AggregationFieldSchema(MoneyAmountField),
      PropertyAddressLineText: AggregationFieldSchema(StringField),
      PropertyCityName: AggregationFieldSchema(StringField),
      PropertyStateCode: AggregationFieldSchema(StringField),
      PropertyPostalCode: AggregationFieldSchema(StringField),
      PropertyType: AggregationFieldSchema(StringField),
      PropertyId: AggregationFieldSchema(StringField),
      BorrowerTier: AggregationFieldSchema(StringField),
      OriginalPurchaseDate: AggregationFieldSchema(DateField),
      UnderPropertyValuationAmount: AggregationFieldSchema(MoneyAmountField),
      PrimaryBorrowerCreditScore: AggregationFieldSchema(NumberField),
      BorrowingEntity: AggregationFieldSchema(StringField),
      OverPropertyValuationAmount: AggregationFieldSchema(MoneyAmountField),
      TotalValuationOrPurchasePriceAmount:
        AggregationFieldSchema(MoneyAmountField),
      USCitizenshipIndicator: AggregationFieldSchema(BooleanField)
    })
  )
});

const baseDealAggregationFieldsSchema = Type.Object({
  ARMFinalAdjustableRateTermMonthCount: AggregationFieldSchema(IntegerField),
  ARMInitialFixedTermMonthCount: AggregationFieldSchema(IntegerField),
  ScheduledFirstPaymentDate: AggregationFieldSchema(DateField),
  LoanMaturityDate: AggregationFieldSchema(DateField),
  PrepaymentPenaltyExpirationDate: AggregationFieldSchema(DateField),
  TotalHOAFeesAmount: AggregationFieldSchema(MoneyAmountField),
  TotalInitialInsuranceEscrowAmount: AggregationFieldSchema(MoneyAmountField),
  TotalAsIsAppraisedValueAmount: AggregationFieldSchema(MoneyAmountField),
  TotalBudgetAmount: AggregationFieldSchema(MoneyAmountField),
  TotalFloodInsurancePremiumAmount: AggregationFieldSchema(MoneyAmountField),
  TotalInitialTaxEscrowAmount: AggregationFieldSchema(MoneyAmountField),
  TotalOutstandingLoanPayoffAmount: AggregationFieldSchema(MoneyAmountField),
  TotalPropertyInsurancePremiumAmount: AggregationFieldSchema(MoneyAmountField),
  TotalPurchasePriceAmount: AggregationFieldSchema(MoneyAmountField),
  TotalSubjectToAppraisedValueAmount: AggregationFieldSchema(MoneyAmountField),
  TotalTaxAmount: AggregationFieldSchema(MoneyAmountField),
  TotalCostAmount: AggregationFieldSchema(MoneyAmountField),
  TotalFloodInsurancePremiumOutstandingAmount:
    AggregationFieldSchema(MoneyAmountField),
  RequestedLoanAmount: AggregationFieldSchema(MoneyAmountField),
  TotalMonthlyMarketRent: AggregationFieldSchema(MoneyAmountField),
  NetWireAmount: AggregationFieldSchema(MoneyAmountField),
  TotalPropertyInsurancePremiumOutstandingAmount:
    AggregationFieldSchema(MoneyAmountField),
  PrepaidInterestAmount: AggregationFieldSchema(MoneyAmountField),
  TotalNumberOfProperties: AggregationFieldSchema(NumberField),
  TotalAdjustedMonthlyRentAmount: AggregationFieldSchema(MoneyAmountField),
  LastDayOfClosingMonth: AggregationFieldSchema(DateField),
  PerDiemPrepaidInterestAmount: AggregationFieldSchema(MoneyAmountField),
  LoanProductType: ThirdPartyFieldSchema(
    EnumField(baseAggregationLoanProductType)
  ),
  EstimatedClosingDate: ThirdPartyFieldSchema(DateField),
  LoanProgramType: ThirdPartyFieldSchema(EnumField(LoanProgramType))
});

const dealFieldsSchemaSubset = Type.Object({
  FieldMeta: Type.Partial(fieldMetaSchema),
  quote: Type.Partial(quoteFieldsSchema),
  aggregations: Type.Partial(baseDealAggregationFieldsSchema),
  assignedUsers: ArrayField(StringField),
  DealMetadata: Type.Partial(elphiDealMetadataSchema),
  LOCAvailableAmount: MoneyAmountField,
  LOCExpirationDate: DateField,
  CashOutAmount: MoneyAmountField,
  DownPaymentAmount: MoneyAmountField,
  AggregateOccupancyPercent: PercentField,
  PolicySectionNumber: StringField,
  LenderIdentifier: Type.Literal(""),
  LoanIdentifier: StringField,
  ApplicationReceivedDate: DateField,
  LOCIssuanceDate: DateField,
  PreliminaryUnderwritingDecisionDate: DateField,
  FinalUnderwritingDecision: StringField,
  InitialTaskGenerationDate: DateField,
  ClearToCloseDate: DateField,
  LoanName: StringField,
  Channel: EnumField(baseChannelType),
  RealEstateProgramType: EnumField(RealEstateProgramType),
  TotalPropertyCount: IntegerField,
  WireReleaseDate: DateField,
  LoanFundingDate: DateField,
  PaymentDueDay: EnumField(basePaymentDueDay),
  NextPaymentDueDate: DateField,
  LoanPaymentType: EnumField(baseLoanPayment),
  LoanTermPeriodMonthCount: EnumField(baseLoanTermPeriodMonthCount),
  LoanAmortizationPeriodMonthCount: IntegerField,
  LoanInterestOnlyPeriodMonthCount: IntegerField,
  InterestAccrualMethodType: EnumField(InterestAccrualMethodType),
  LoanAmortizationPeriodYearCount: IntegerField,
  IndexSourceType: EnumField(IndexSourceType),
  CapitalStructureType: EnumField(CapitalStructureType),
  MarginRatePercent: Deprecated(ThreeDecimalPercentField),
  FloorRatePercent: Deprecated(ThreeDecimalPercentField),
  LifetimeCapRatePercent: Deprecated(ThreeDecimalPercentField),
  ARMRepriceRoundingRatePercentType: EnumField(
    ARMRepriceRoundingRatePercentType
  ),
  ARMRepriceRoundingMethodType: EnumField(ARMRepriceRoundingMethodType),
  ARMRepriceLookbackType: EnumField(ARMRepriceLookbackType),
  UCCExpirationDate: DateField,
  WarehouseBankType: Type.Literal(""),
  WarehouseBankStatusType: EnumField(WarehouseBankStatusType),
  HaircutAmount: MoneyAmountField,
  CollateralPackageStatusType: EnumField(CollateralPackageStatusType),
  CollateralPackageTrackingNumber: StringField,
  Ledger: Type.Partial(dealLedgerSchema),
  LienPosition: StringField,
  UCCFiledIndicator: BooleanField,
  TitleCompany: Type.Partial(titleCompanySchema),
  BlendedLTCLoanAmount: MoneyAmountField,
  ConstructionLTCRatePercent: PercentField,
  TotalPropertyValuationAmount: MoneyAmountField,
  MaxARVAmount: MoneyAmountField,
  InitialAdvancedFundAmount: MoneyAmountField,
  TotalLoanFeesAndClosingCostAmount: MoneyAmountField,
  ClosingAnalystUserId: StringField,
  PromissoryNoteComments: StringField,
  ShippingCompanyType: EnumField(ShippingCompanyType),
  NoteToWarehouseTrackingNumber: StringField,
  SettlementStatementFileNumber: StringField,
  AllongeOrAssignmentSentToWarehouseIndicator: BooleanField,
  Lender: Type.Partial(lenderSchema),
  PropertyModelType: EnumField(PropertyModelType),
  IntegrationMetadata: integrationMetadataSchema,
  GracePeriodDays: IntegerField,
  Exceptions: Type.Partial(elphiDealExceptionsSchema),
  NotaryUserId: StringField,
  NotaryExpirationDate: DateField,
  LoanPackageTrackingNumber: StringField,
  LoanPackageNoteTrackingNumber: StringField,
  LoanPackageInternationalExecutionIndicator: BooleanField,
  LoanPackageNoteReceivedByLenderIndicator: BooleanField,
  UnderwriterApprovalStatus: EnumField(UnderwriterApprovalStatusType),
  UnderwriterApprovalComments: StringField,
  AssetSummaryReportIndicator: Deprecated(BooleanField),
  CreditMemorandumIndicator: Deprecated(BooleanField),
  CreditReviewCommitteeApprovalIndicator: Deprecated(BooleanField),
  SubmissionNotes: StringField,
  PricingEngineExceptionStatus: EnumField(PricingEngineExceptionStatusType),
  PropertyInsurancePremiumPaymentType: EnumField(
    PropertyInsurancePremiumPaymentType
  ),
  FloodInsurancePremiumPaymentType: EnumField(baseFloodInsurancePremiumPayment),
  InterestReserveDepositAmount: MoneyAmountField,
  TitleCommitment: Type.Partial(elphiTitleCommitmentSchema),
  SettlementStatementComments: StringField,
  EOExpirationDate: DateField,
  WireABARoutingNumber: StringField,
  WireAccountNumber: StringField,
  WireBankName: StringField,
  WireAccountName: StringField,
  TitleFormsComments: StringField,
  PortfolioLoanIndicator: BooleanField,
  RateLockStartDate: DateField,
  RateLockEndDate: DateField,
  TotalCashToFromBorrower: MoneyAmountField,
  CashFromBorrowerAmount: MoneyAmountField,
  CashToBorrowerAmount: MoneyAmountField,
  PrimaryBorrowers: ArrayField(StringField),
  SecondaryBorrowers: ArrayField(StringField),
  PrimarySponsors: ArrayField(StringField),
  SecondarySponsors: ArrayField(StringField),
  ACHElectedIndicator: BooleanField,
  SellerHUDAddedIndicator: BooleanField,
  fundingAgentEmailAddress: EmailField,
  MarketingPromotionCreditAmount: MoneyAmountField,
  MarketingPromotionCode: StringField,
  InterestType: EnumField(baseInterestType),
  MERSMin: StringField,
  FundingDate: DateField,
  PrincipalBalance: MoneyAmountField,
  LateFeeAmount: MoneyAmountField,
  InvestorInterestRate: PercentField,
  InterestRateBuydownPercent: PercentField,
  OriginationFeePercent: PercentField,
  PrepaymentPenaltyType: EnumField(basePrepaymentPenaltyType),
  NoteRatePercent: PercentField,
  LoanProductType: EnumField(baseLoanProductType),
  FloorPercent: PercentField,
  CeilingPercent: PercentField,
  MarginPercent: PercentField,
  InterestReserveEscrowAmount: MoneyAmountField,
  ServicerLoanIdentifier: StringField,
  InvestorLoanIdentifier: StringField,
  ServicerAccountIdentifier: StringField,
  SoldDate: DateField,
  ServicingTransferDate: DateField,
  ServicerIdentifier: Type.Literal(""),
  AssignmentStatus: EnumField(baseAssignmentStatus),
  ExtendedMaturityDate: DateField,
  PaidOffIndicator: BooleanField,
  DefaultStatus: EnumField(baseDefaultStatus),
  FinalPrice: NumberField,
  InvestorIdentifier: Type.Literal(""),
  LateFeePercent: PercentField,
  DefaultInterestRatePercent: PercentField,
  TotalLiquidAssetAmount: MoneyAmountField
});

export const elphiDealSchema = Type.Intersect([
  BaseEntitySchema,
  dealFieldsSchemaSubset
]);
