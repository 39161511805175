import { Deal, LabelValue, LoanProgramType } from "@elphi/types";
import { getFocusedData } from "@elphi/utils";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { useCallback, useMemo } from "react";
import { getSpecs } from "../../forms/schemas/factories/specsFactory";
import {
  getLoanProgramOptions,
  getOptionLabel
} from "../../forms/schemas/utils/formsSpec.utils";
import { useDealHooks } from "../../hooks/deal.hooks";
import { getRankedData } from "../../utils/ranked.utils";
import { SearchComponentProps } from "../SearchComponent";
import { SearchHandler } from "../search/SearchHandler";

const buildOption = (r: {
  deal?: Deal;
  loanProgramOptions: Array<LabelValue>;
}) => {
  const { deal, loanProgramOptions } = r;
  const loanProgramType = getFocusedData(
    deal?.aggregations?.LoanProgramType
  ) as LoanProgramType;
  const loanProgramLabel = getOptionLabel(loanProgramOptions, loanProgramType);
  return {
    label: [
      deal?.LoanIdentifier,
      deal?.LoanName || "missing name",
      loanProgramLabel
    ]
      .filter(removeEmpty)
      .join(", "),
    value: deal?.id
  };
};
type DealSearchProps = {
  filter?: (a: Deal) => boolean;
} & Pick<
  SearchComponentProps,
  | "onSelect"
  | "currentValue"
  | "label"
  | "labelPosition"
  | "isReadOnly"
  | "isDisabled"
> &
  Pick<Partial<SearchComponentProps>, "fieldType">;
const DealSearch = (props: DealSearchProps) => {
  const {
    searchDeal,
    searchResponse,
    rankedSort,
    dealState,
    dataRank,
    dealSearchState
  } = useDealHooks();

  const loanProgramOptions = useMemo(() => {
    const fieldsSpecs = getSpecs();
    return getLoanProgramOptions(fieldsSpecs);
  }, []);

  const customFilterLogic: SearchComponentProps["filterOption"] = (
    option,
    searchText
  ) => {
    if (!searchText) return true;
    const deal = dealState?.entities?.[option.data.value];
    if (!deal || !deal.id) return false;
    return (
      getRankedData(searchText, deal, dataRank) > 0.5 ||
      searchText === deal.id.toLowerCase()
    );
  };
  const customFilter = useCallback(customFilterLogic, [dealState?.entities]);

  return (
    <SearchHandler
      {...props}
      version="v2"
      searchApi={searchDeal}
      searchResponse={searchResponse}
      rankedSort={rankedSort}
      filterOption={customFilter}
      state={dealSearchState}
      buildOption={(x) =>
        buildOption({
          deal: x,
          loanProgramOptions
        })
      }
    />
  );
};

export default DealSearch;
