import { Asset } from "./asset.types";
import { CreditScore } from "./creditReport.types";
import { Deal } from "./deal.types";
import { DealPartyRelation } from "./dealParty.types";
import { DealProperty } from "./dealProperty.types";
import {
  DocumentDeal,
  DocumentParty,
  DocumentProperty
} from "./documentPayload.types";
import { ElphiEntityType } from "./elphiEntities.types";
import { Party } from "./party.types";
import { PartyRelation } from "./partyRelation.types";
import { Property } from "./property.types";
import { RolodexServiceProvider } from "./rolodex";
import { Statement } from "./statement.types";
import { Task } from "./task.types";

export enum EntitiesDataType {
  Document = "document",
  Schema = "schema"
}

type BaseEntitiesData<T extends EntitiesDataType> = {
  type: T;
};

export type DocumentEntitiesData =
  BaseEntitiesData<EntitiesDataType.Document> & {
    type: EntitiesDataType.Document;
    deal?: DocumentDeal;
    party?: DocumentParty;
    property?: DocumentProperty;
    dealParty?: DealPartyRelation;
    partyRelation?: PartyRelation;
    dealProperty?: DealProperty;
    asset?: Asset;
    statement?: Statement;
    creditScore?: CreditScore;
  };

export type SchemaEntitiesData = BaseEntitiesData<EntitiesDataType.Schema> & {
  type: EntitiesDataType.Schema;
  deal?: Deal;
  party?: Party;
  property?: Property;
  dealParty?: DealPartyRelation;
  partyRelation?: PartyRelation;
  dealProperty?: DealProperty;
  asset?: Asset;
  statement?: Statement;
  creditScore?: CreditScore;
  task?: Task;
  serviceProvider?: RolodexServiceProvider;
};

export type EntitiesData = SchemaEntitiesData | DocumentEntitiesData;

export enum RuleNodeType {
  Function = "Function",
  Condition = "Condition",
  Operation = "Operation",
  Field = "Field",
  Value = "Value"
}
type BaseRuleNode<TType extends RuleNodeType> = {
  type: TType;
};

export enum ValueNodeType {
  Date = "date",
  Number = "number",
  String = "string",
  Boolean = "boolean"
}
type BaseValueNode<TType extends ValueNodeType> =
  BaseRuleNode<RuleNodeType.Value> & {
    valueType: TType;
  };
type DateValueNode = BaseValueNode<ValueNodeType.Date> & {
  value: Date;
};
type NumberValueNode = BaseValueNode<ValueNodeType.Number> & {
  value: number;
};
type StringValueNode = BaseValueNode<ValueNodeType.String> & {
  value: string;
};
type BooleanValueNode = BaseValueNode<ValueNodeType.Boolean> & {
  value: boolean;
};

type ValueNode =
  | DateValueNode
  | NumberValueNode
  | StringValueNode
  | BooleanValueNode;

type FieldNode = BaseRuleNode<RuleNodeType.Field> & {
  entityType: ElphiEntityType;
  path: string[];
};

export interface RuleFunctions {
  dateDelta: (r: {
    date: Date;
    period: "d" | "m" | "y";
    delta: number;
  }) => Date;
  todayDate: () => Date;
  multiply: (r: { a: number; b: number }) => number;
}

type BaseFunctionNode<TType extends keyof RuleFunctions> =
  BaseRuleNode<RuleNodeType.Function> & {
    functionType: TType;
  };
export type DateDeltaFunctionNode = BaseFunctionNode<"dateDelta"> & {
  period: "d" | "m" | "y";
  delta: number;
  field: FieldNode;
};
export type MultiplyFunctionNode = BaseFunctionNode<"multiply"> & {
  a: FieldNode | NumberValueNode;
  b: FieldNode | NumberValueNode;
};
export type TodayDateFunctionNode = BaseFunctionNode<"todayDate">;

export type FunctionRuleNode =
  | TodayDateFunctionNode
  | DateDeltaFunctionNode
  | MultiplyFunctionNode;
export type ExpressionRuleNode = ValueNode | FieldNode | FunctionRuleNode;

export type Operation =
  | "=="
  | "<="
  | "<"
  | ">"
  | ">="
  | "!="
  | "includes"
  | "includes-any"
  | "not-includes";
export type OperationNode = BaseRuleNode<RuleNodeType.Operation> & {
  operation: Operation;
  left: ExpressionRuleNode;
  right: ExpressionRuleNode;
};

export type ConditionNode = BaseRuleNode<RuleNodeType.Condition> & {
  children: (ConditionNode | OperationNode)[];
  condition: "or" | "and";
};

export type DataPermutation = {
  dealPropertyPermutation: {
    dealId: string;
    propertyId: string;
    dealProperty: string;
  }[];
  dealPartyPermutation: {
    dealId: string;
    partyId: string;
    dealParty: string;
  }[];
  dealPartyPropertyPermutation: {
    dealId: string;
    propertyId: string;
    partyId: string;
    dealProperty: string;
    dealParty: string;
  }[];
  partyAssetPermutation: {
    partyId: string;
    assetId: string;
  }[];
  partyAssetStatementPermutation: {
    partyId: string;
    assetId: string;
    statementId: string;
  }[];
  partyCreditScorePermutation: {
    partyId: string;
    creditScoreId: string;
  }[];
};
