import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from "@chakra-ui/icons";
import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { FieldType } from "@elphi/types";
import { isString } from "lodash";
import { useMemo, useState } from "react";
import { DragHandleIcon } from "../../components/icons";
import {
  MovementActionProps,
  SwitchActionProps,
  useTableManagerHook
} from "../../hooks/tableManager.hooks";
import { DebounceIconButton } from "../custom/chakra/DebounceIconButton";
import { StyledInputBuilder } from "../form-builder/InputBuilder";
import { ElphiCell } from "../table/table.types";
 type OrderableTableHeaderProps = {
  tableName: string;
  columnId: number;
  header: string | JSX.Element;
  columnList: ElphiCell[];
};
export const OrderableTableHeader = ({
  tableName,
  columnId,
  header,
  columnList
}: OrderableTableHeaderProps) => {
  const {
    moveLeft,
    moveRight,
    switchColumns,
    getTableState,
    moveToStart,
    moveToEnd,
    getSortCallback
  } = useTableManagerHook();

  const [isColumnChangeMenuDisplayed, setIsColumnChangeMenuDisplayed] =
    useState<boolean>(false);

  const { columnsOrder, minIndex, maxIndex } = getTableState(tableName) || {};

  const getHeaderForUnnamedColumn = (index: number) => {
    return `No Name - index: ${
      columnsOrder ? columnsOrder[index].index : index
    }`;
  };

  const columnsOptions: { label: string; value: string }[] = useMemo(() => {
    const filteredColumn = columnList
      .filter(({ data }) => isString(data))
      .filter(({ index }) => index !== minIndex && index !== columnId);

    const sortCallback = getSortCallback({ isOrderable: true });
    const sortedCells = sortCallback({ tableName, cells: filteredColumn });

    return sortedCells.map(({ data, index }) => ({
      label: data ? String(data) : getHeaderForUnnamedColumn(index),
      value: String(index)
    }));
  }, [columnList.length, columnsOrder]);

  const displayColumnChangeMenu = () => {
    setIsColumnChangeMenuDisplayed(true);
  };

  const hideColumnChangeMenu = () => {
    setIsColumnChangeMenuDisplayed(false);
  };

  const actionHandler = <T extends MovementActionProps | SwitchActionProps>(
    action: (props: T) => void
  ) => {
    hideColumnChangeMenu();
    return action;
  };

  const moveToStartHandler = (props: MovementActionProps) => {
    actionHandler(moveToStart)(props);
  };

  const moveToEndHandler = (props: MovementActionProps) => {
    actionHandler(moveToEnd)(props);
  };

  const moveLeftHandler = (props: MovementActionProps) => {
    actionHandler(moveLeft)(props);
  };

  const moveRightHandler = (props: MovementActionProps) => {
    actionHandler(moveRight)(props);
  };

  const switchColumnsHandler = (
    props: MovementActionProps & {
      e: {
        target: {
          value: number;
        };
      };
    }
  ) => {
    const { tableName, columnId, e } = props;
    const { value } = e.target;
    if (value) {
      actionHandler(switchColumns)({
        tableName,
        columnAId: columnId,
        columnBId: value
      });
    }
  };

  const columnIndex = columnsOrder ? columnsOrder[columnId].index : columnId;
  const isFirstOrderableColumn = columnIndex === (minIndex || 0) + 1;
  const isLastOrderableColumn = columnIndex === maxIndex;

  return (
    <Flex pos="relative">
      <Box>
        <HStack spacing={1} onMouseEnter={displayColumnChangeMenu}>
          <Box>
            <DragHandleIcon />
          </Box>
          <Box>
            {typeof header === "string" ? (
              <Text fontWeight="bold" fontSize="14px">
                {header}
              </Text>
            ) : (
              header
            )}
          </Box>
        </HStack>

        {isColumnChangeMenuDisplayed && (
          <Box
            pos="absolute"
            left="50%"
            top="-14px"
            onMouseLeave={hideColumnChangeMenu}
            transition="all 0.3s ease-in-out"
            transform="translateX(-50%)"
            zIndex="1"
            bgColor="white"
            padding="6px"
            border="1px solid #e2e8f0"
            borderRadius="0.25rem"
            boxShadow="0px 1px 3px #0000001a"
          >
            <Flex width={"270px"} justifyContent="space-evenly">
              <Flex flex="1" justifyContent="space-evenly" alignItems="center">
                <DebounceIconButton
                  size="sm"
                  aria-label={"move-to-start"}
                  icon={<ArrowLeftIcon boxSize="2" />}
                  bgColor="transparent"
                  disabled={isFirstOrderableColumn}
                  onClick={() => moveToStartHandler({ tableName, columnId })}
                />
                <DebounceIconButton
                  size="sm"
                  aria-label={"move-left"}
                  icon={<ChevronLeftIcon />}
                  bgColor="transparent"
                  disabled={isFirstOrderableColumn}
                  onClick={() => moveLeftHandler({ tableName, columnId })}
                />
              </Flex>
              <Flex mr="1" ml="1">
                <StyledInputBuilder
                  options={columnsOptions}
                  label="Pick a column"
                  onChange={(e) => {
                    switchColumnsHandler({ e, tableName, columnId });
                  }}
                  isClearable={false}
                  isValid={true}
                  fieldType={FieldType.SingleSelect}
                  currentValue={""}
                  responsiveSize="sm"
                  width="130px"
                  chakraStyles={{
                    container: (provided, _) => ({
                      ...provided,
                      width: "130px"
                    })
                  }}
                />
              </Flex>
              <Flex flex="1" justifyContent="space-evenly" alignItems="center">
                <DebounceIconButton
                  size="sm"
                  aria-label={"move-right"}
                  icon={<ChevronRightIcon />}
                  bgColor="transparent"
                  disabled={isLastOrderableColumn}
                  onClick={() => moveRightHandler({ tableName, columnId })}
                />
                <DebounceIconButton
                  size="sm"
                  aria-label={"move-to-end"}
                  icon={<ArrowRightIcon boxSize="2" />}
                  bgColor="transparent"
                  disabled={isLastOrderableColumn}
                  onClick={() => moveToEndHandler({ tableName, columnId })}
                />
              </Flex>
            </Flex>
          </Box>
        )}
      </Box>
    </Flex>
  );
};
