import {
  DiscussionComment,
  DiscussionEntity,
  FirebaseCollections,
  TimestampField
} from "@elphi/types";
import { onSnapshot } from "firebase/firestore";
import { union } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getTimestampFromFirestoreObject } from "../firebase/firebase.utils";
import {firebase,  auth, firestore } from "../firebase/firebaseConfig";
import { discussionEntitySlice } from "../redux/v2/discussion-entity";
import { useOrgHooks } from "./org.hooks";

export const useShouldNotifyDiscussionSubscriber = (props: {
  entityId: string;
}) => {
  const { entityId } = props;
  const { selectedOrgId } = useOrgHooks();
  const [notify, setNotify] = useState<boolean>(false);

  useEffect(() => {
    if (!selectedOrgId || !auth?.currentUser?.uid) {
      return;
    }

    const query = firestore
      .context({
        orgId: selectedOrgId.toString(),
        user: {
          id: auth.currentUser.uid
        }
      })
      .collection(FirebaseCollections.DiscussionUser)
      .doc(auth.currentUser.uid)
      .collection(FirebaseCollections.DiscussionNotifierUserEntity)
      .doc(entityId);

    const unsubscribe = onSnapshot(query, (snapshot) => {
      const data = snapshot.data();
      setNotify(data?.notify);
    });
    return () => {
      unsubscribe();
    };
  }, [selectedOrgId, auth?.currentUser?.uid, entityId]);

  return { notify, setNotify };
};

type DiscussionEntityCommentSubscriber = {
  added: DiscussionComment[];
  modified: { id: string; changes: DiscussionComment }[];
  removed: { id: string }[];
};

export const useDiscussionEntityCommentSubscriber = (props: {
  entityId: string;
}) => {
  const { entityId } = props;
  const { selectedOrgId } = useOrgHooks();
  const dispacher = useDispatch();
  const { addComments, updateComments, removeComments } =
    discussionEntitySlice.actions;
  useEffect(() => {
    if (!selectedOrgId || !auth?.currentUser?.uid) {
      return;
    }

    const dateNow = firebase.firestore.Timestamp.now().toDate();

    const query = firestore
      .context({
        orgId: selectedOrgId.toString(),
        user: {
          id: auth.currentUser.uid
        }
      })
      .collection(FirebaseCollections.DiscussionEntity)
      .doc(entityId)
      .collection(FirebaseCollections.DiscussionComments)
      .where("modifiedAt", ">=", dateNow);

    const unsubscribe = onSnapshot(query, async (snapshot) => {
      const changes = snapshot.docChanges().reduce((acc, dc) => {
        const data = dc.doc.data() as DiscussionComment;

        const { id, modifiedAt, createdAt, status } = data;

        const createdDate = getTimestampFromFirestoreObject(
          createdAt as TimestampField
        )?.toDate();

        const modifiedDate = getTimestampFromFirestoreObject(
          modifiedAt as TimestampField
        )?.toDate();

        if (
          createdDate &&
          modifiedDate &&
          createdDate.getTime() === modifiedDate.getTime()
        ) {
          acc.added = acc.added ? union(acc.added, [data]) : [data];
        }

        if (
          createdDate &&
          modifiedDate &&
          createdDate.getTime() < modifiedDate.getTime() &&
          status === "active"
        ) {
          acc.modified = acc.modified
            ? union(acc.modified, [{ id, changes: data }])
            : [{ id, changes: data }];
        }

        if (status === "deleted") {
          acc.removed = acc.removed ? union(acc.removed, [{ id }]) : [{ id }];
        }

        return acc;
      }, {} as DiscussionEntityCommentSubscriber);

      if (changes.added) {
        dispacher(addComments({ entityId, comments: changes.added }));
      }
      if (changes.modified) {
        dispacher(
          updateComments({
            entityId,
            comments: changes.modified.map((c) => c.changes)
          })
        );
      }
      if (changes.removed) {
        dispacher(
          removeComments({
            entityId,
            commentIds: changes.removed.map((c) => c.id)
          })
        );
      }
    });
    return () => {
      unsubscribe();
    };
  }, [selectedOrgId, auth?.currentUser?.uid, entityId]);
};

export const useDiscussionEntityWatchersSubscriber = (props: {
  entityId: string;
  isWatching: boolean;
}) => {
  const { entityId, isWatching } = props;
  const { selectedOrgId } = useOrgHooks();
  const dispacher = useDispatch();
  const { setWatchers } = discussionEntitySlice.actions;
  useEffect(() => {
    if (!selectedOrgId || !auth?.currentUser?.uid || !isWatching) {
      return;
    }

    const query = firestore
      .context({
        orgId: selectedOrgId.toString(),
        user: {
          id: auth.currentUser.uid
        }
      })
      .collection(FirebaseCollections.DiscussionEntity)
      .doc(entityId);

    const unsubscribe = onSnapshot(query, async (snapshot) => {
      const data = snapshot.data() as DiscussionEntity;
      dispacher(
        setWatchers({
          entityId,
          watchers: data?.watcherUIDs || []
        })
      );
    });
    return () => {
      unsubscribe();
    };
  }, [selectedOrgId, auth?.currentUser?.uid, entityId, isWatching]);
};
