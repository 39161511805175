import { Box, Tooltip } from "@chakra-ui/react";
import { FieldStatus, FieldType } from "@elphi/types";
import {
  fieldStatusToColor,
  isAggregationPath,
  removeAggregationProp
} from "../task/field-selection/d2v.utils";
import { OnChangeInput, Section } from "./FormBuilder";
import { StyledInputBuilder } from "./InputBuilder";

type FieldsStatusUpdaterProps = {
  section: Section;
  onChange: (e: OnChangeInput) => void;
  isDisabled?: boolean;
};
const getFieldMetaPath = (path: string[]) => {
  return isAggregationPath(path) ? removeAggregationProp(path) : path;
};
export const SectionStatusUpdater = (props: FieldsStatusUpdaterProps) => {
  const distinctValues = props.section.inputs
    .map((x) => {
      return x.fieldStatus;
    })
    .filter((value, i, self) => self.indexOf(value) === i);

  const selectedValue = distinctValues.length === 1 ? distinctValues[0] : "";
  const options = [
    { value: FieldStatus.None, label: FieldStatus.None },
    { value: FieldStatus.Approved, label: "Verified" },
    {
      value: FieldStatus.AttentionRequired,
      label: FieldStatus.AttentionRequired
    },
    { value: FieldStatus.Denied, label: FieldStatus.Denied }
  ];

  const onChange = (e: { target: { value: any } }) => {
    props.section.inputs.forEach((input) => {
      const path = input.fieldKey;
      const fieldMetaPath = getFieldMetaPath(path);
      const adaptedFieldKey = ["fieldMeta", ...fieldMetaPath, "status"];
      props.onChange({
        fieldKey: adaptedFieldKey,
        fieldType: input.fieldType,
        value: e.target.value
      });
    });
  };

  return (
    <Tooltip
      aria-label="section-status-updater-dropdown"
      label={
        selectedValue || props.isDisabled
          ? ""
          : "If the section fields have different statuses, you can align them all here."
      }
    >
      <Box w="200px" pl="20px" fontSize={"10px"}>
        <StyledInputBuilder
          isDisabled={props?.isDisabled}
          onChange={onChange}
          label="Set Section Status"
          chakraStyles={{
            dropdownIndicator: (provided) => {
              return {
                ...provided,
                w: "20px",
                backgroundColor:
                  selectedValue && fieldStatusToColor[selectedValue]
              };
            },

            singleValue: (provided) => ({
              ...provided,
              fontSize: "12px",
              p: 0
            }),
            placeholder: (provided) => ({
              ...provided,
              fontSize: "12px",
              p: 0
            }),
            control: (provided, _) => ({
              ...provided,
              height: "10px",
              boxShadow: "md",
              p: 0
            })
          }}
          currentValue={selectedValue}
          fieldType={FieldType.SingleSelect}
          options={options}
        />
      </Box>
    </Tooltip>
  );
};
