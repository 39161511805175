import {
  Deal,
  DealMilestoneType,
  LenderIdentifierType,
  Property
} from "@elphi/types";
import { ArgumentTypes } from "@elphi/types/utils/arguments";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { pickBy } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { responseHandler } from "../apis/rtk/response.handler";
import { RTKResponse } from "../apis/rtk/response.types";
import { useElphiToast } from "../components/toast/toast.hook";
import { newTab } from "../components/utils/navigationUtils";
import { OmitCreate } from "../firebase/firebase.types";
import { RootState, store } from "../redux/store";
import { dealCalendarSlice } from "../redux/v2/deal-calendar";
import { dealApi } from "../redux/v2/deal/deal.service";
import {
  DealPageViews,
  DealTableCursorType,
  dealSlice
} from "../redux/v2/deal/deal.slice";
import { uniqCursorId } from "../redux/v2/deal/utils";
import { PathVariables } from "../routes/pathVariables";
import { LocationState } from "../shared";
import { getListFromDictionary } from "../utils/batchUtils";
import { FieldsWeight, getRankedData } from "../utils/ranked.utils";

export const useDealHooks = () => {
  const dispatcher = useDispatch();
  const { successToast, errorToast } = useElphiToast();
  const [searchDeal, searchResponse] = dealApi.useLazySearchQuery();
  const [updateBatchApi] = dealApi.useBatchUpdateMutation();
  const [getDeal, getDealResponse] = dealApi.useLazyGetQuery();
  const [getDeals, getDealsResponse] = dealApi.useLazyGetDealBatchQuery();
  const [updateDealApi, updateDealApiResponse] = dealApi.useUpdateMutation();
  const [createDealApi, createDealApiResponse] = dealApi.useCreateMutation();
  const [getDealUserRelations, getDealUserRelationsResponse] =
    dealApi.useLazyDealUsersQuery();
  const [getDealPartiesApi, getDealPartiesApiResponse] =
    dealApi.useLazyDealPartiesQuery();
  const [getDealPropertiesApi, getDealPropertiesApiResponse] =
    dealApi.useLazyDealPropertiesQuery();
  const [addDealPropertiesApi, addDealPropertiesApiResponse] =
    dealApi.useAddPropertiesMutation();
  const [getDealQuoteApi, getDealQuoteResponse] =
    dealApi.useLazyGetDealQuoteQuery();
  const [getDealQuoteDataApi, getDealQuoteDataResponse] =
    dealApi.useLazyGetDealQuoteDataQuery();
  const [updateDealQuoteApi, updateDealQuoteResponse] =
    dealApi.useUpdateDealQuoteMutation();
  const [updateRateLockApi, updateRateLockResponse] =
    dealApi.useUpdateRateLockMutation();
  const [postRateLockApi, postRateLockResponse] =
    dealApi.usePostRateLockMutation();
  const [getRateLockApi, getRateLockResponse] =
    dealApi.useLazyGetRateLockQuery();

  const [getAdditionalDataForDeals] =
    dealApi.useLazyGetAdditionalDataForDealsQuery();
  const setSelectedDeal = (id: string) =>
    store.dispatch(dealSlice.actions.selectedId({ id }));
  const selectedDealPageView = useSelector(
    (state: RootState) => state.deal.selectedDealPageView
  );
  const setSelectedDealPageView = (pageView: DealPageViews) =>
    store.dispatch(dealSlice.actions.setSelectedDealPageView({ pageView }));

  const setDealTableSelectedDealId = (id: string) =>
    store.dispatch(dealSlice.actions.dealTableSelectedDealId({ id }));
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location?.state as LocationState;

  const selectedDeal = useSelector(
    (state: RootState) =>
      state.deal.selectedId && state.deal.entities[state.deal.selectedId]
  );

  const dealTableFilter = useSelector(
    (state: RootState) => state.deal.dealTable?.filter
  );

  useEffect(() => {
    store.dispatch(
      dealCalendarSlice.actions.setFilter({ filter: dealTableFilter })
    );
  }, [dealTableFilter]);

  const selectedId = useSelector((state: RootState) => state.deal.selectedId);
  const selectedIdString = useSelector((state: RootState) =>
    state.deal.selectedId?.toString()
  );
  const selectedDealTableData = useSelector(
    (state: RootState) =>
      state.deal.dealTable?.selectedDealId &&
      state.deal.entities[state.deal.dealTable?.selectedDealId]
  );

  const dealState = useSelector((state: RootState) => state.deal);
  const dealTableSelectedDealId = useSelector(
    (state: RootState) =>
      state.deal.dealTable && state.deal.dealTable?.selectedDealId
  );
  const getDealParties = async (ids: string[]) => {
    const response = await getDealPartiesApi(ids, true);
    return responseHandler(response as RTKResponse<typeof response.data>);
  };
  const createDeal = async (newDeal: Deal) => {
    const response = await createDealApi(newDeal);
    return responseHandler(response);
  };
  const updateDeal = async (newDeal: { id: string } & Partial<Deal>) => {
    const response = await updateDealApi(newDeal);
    return responseHandler(response);
  };

  const updateDealsHandler = async (
    map:
      | {
          [id: string]: {
            id: string;
          } & Partial<Deal>;
        }
      | undefined
  ) => {
    if (!map) return;
    const deals = getListFromDictionary(map);
    if (!deals.length) return;
    return await updateBatch({ deals } as {
      deals: ({
        id: string;
      } & Partial<Deal>)[];
    });
  };
  const updateBatch = async (r: {
    deals: ({ id: string } & Partial<Deal>)[];
  }) => {
    const response = await updateBatchApi(r);
    return responseHandler(
      response as RTKResponse<{
        batch: string[];
      }>
    );
  };
  const addDealProperties = async (r: {
    dealId: string;
    properties: ({ id: string } | OmitCreate<Property>)[];
  }) => {
    return addDealPropertiesApi({
      dealId: r.dealId,
      properties: r.properties
    }).then(responseHandler);
  };
  const getDealQuote = async (r: { dealId: string }) => {
    const response = await getDealQuoteApi({
      id: r.dealId
    });
    return responseHandler(
      response as RTKResponse<{ deal: Deal; pricingValidationErrors: string }>
    );
  };
  const getDealQuoteData = async (r: { dealId: string }) => {
    const response = await getDealQuoteDataApi({
      id: r.dealId
    });
    return responseHandler(response as RTKResponse<any>);
  };
  const updateDealQuote = async (r: { id: string; dealQuoteData: any }) => {
    const response = await updateDealQuoteApi({
      id: r.id,
      dealQuoteData: r.dealQuoteData
    });
    return responseHandler(response as RTKResponse<{ id: string }>);
  };
  const updateRateLock = async (r: {
    quoteId: string;
    startDate: string;
    endDate: string;
  }) => {
    const response = await updateRateLockApi(r);
    return responseHandler(response as RTKResponse<{ response: string }>);
  };
  const postRateLock = async (r: {
    quoteId: string;
    startDate: string;
    endDate: string;
  }) => {
    const response = await postRateLockApi(r);
    return responseHandler(response as RTKResponse<{ response: string }>);
  };
  const getRateLock = async (r: { id: string }) => {
    const response = await getRateLockApi(r);
    return responseHandler(response as RTKResponse<{ response: string }>);
  };

  const dataRank: FieldsWeight<Deal> = {
    LoanIdentifier: 30,
    LoanName: 30,
    "aggregations.LoanProgramType.thirdParty": 9,
    "aggregations.LoanProgramType.override": 9,
    PrimaryBorrowers: 10,
    SecondaryBorrowers: 10,
    PrimarySponsors: 10,
    SecondarySponsors: 10
  };
  const rankedSort = (query: string) => {
    return Object.values(dealState.entities).sort((a, b) => {
      const rankA = getRankedData(query, a, dataRank, {
        sortedByKeywords: true
      });

      const rankB = getRankedData(query, b, dataRank, {
        sortedByKeywords: true
      });

      if (rankA < rankB) {
        return 1;
      } else {
        return -1;
      }
    });
  };

  const getDealRelations = async (dealId: string) => {
    return await Promise.all([
      getDeal(dealId, true),
      getDealPropertiesApi([dealId], true),
      getDealPartiesApi([dealId], true)
    ]).then((responses) => {
      return {
        deal: responseHandler(
          responses[0] as RTKResponse<(typeof responses)[0]>
        ),
        dealProperties: responseHandler(
          responses[0] as RTKResponse<(typeof responses)[1]>
        ),
        dealParties: responseHandler(
          responses[0] as RTKResponse<(typeof responses)[2]>
        )
      };
    });
  };

  const navigateToWorksheet = (orgId: string, dealId: string) => {
    newTab(`/${orgId}${PathVariables.WorksheetPath}/${dealId}`, {
      features: ""
    });
  };
  const navigateToWorksheetV1 = (orgId: string, dealId: string) => {
    setSelectedDeal(dealId);
    navigate(`/${orgId}${PathVariables.WorksheetPath}/${dealId}`, {
      state: { ...locationState },
      replace: true
    });
  };

  const setDealTableFilter = (
    r: ArgumentTypes<typeof dealSlice.actions.setDealTableFilter>[0]
  ) => dispatcher(dealSlice.actions.setDealTableFilter(r));

  const setDealTableCursor = (
    r: ArgumentTypes<typeof dealSlice.actions.setDealTableCursor>[0]
  ) => dispatcher(dealSlice.actions.setDealTableCursor(r));

  const getDealTableCursor = (r: {
    cursorType: DealTableCursorType;
    dealId?: string;
    userId?: string;
    milestone?: DealMilestoneType[];
    lender?: LenderIdentifierType[];
    milestoneOp: string;
    estimatedClosingDateRange?: { from: string; to: string };
  }) => {
    const {
      //  cursorType, dealId, userId
      dealId,
      userId,
      milestone,
      lender,
      milestoneOp,
      estimatedClosingDateRange
    } = r;

    const cursorId = uniqCursorId({
      dealId,
      userId,
      milestone,
      lender,
      milestoneOp,
      estimatedClosingDateRange
    });
    const cursor = dealState.dealTable?.cursor?.agnostic[cursorId];

    return cursor;
  };

  const dealAddCallbackHandler = useCallback(
    (r: Partial<Deal>[]) => {
      const dealsId = r.map((d) => d.id);
      const missingIds = dealsId
        .filter(removeEmpty)
        .filter((p) => !dealState.ids.includes(p));
      missingIds.length && getAdditionalDataForDeals({ ids: missingIds });
    },
    [dealState]
  );

  const dealSearchState = useMemo(() => {
    return {
      ...dealState,
      entities: pickBy(dealState.entities, (_, key) =>
        dealState.searchResultsIds.includes(key)
      )
    };
  }, [dealState.entities, dealState.searchResultsIds]);

  const updateDealMilestoneHandler = async ({
    dealId,
    milestone
  }: {
    dealId: string;
    milestone: DealMilestoneType;
  }) => {
    const deal: { id: string } & Partial<Deal> = {
      DealMetadata: { milestone },
      id: dealId
    } as { id: string } & Partial<Deal>;
    if (!deal) return null;

    return await updateDeal(deal).then((r) => {
      if (r.status === 200) {
        successToast({
          title: "Milestone Updated",
          description: `Milestone updated for Deal: ${deal.id}`
        });
      }
      r.status === 400 &&
        errorToast({
          title: "Failed to update milestone",
          description: r.data.description
        });
      return r;
    });
  };

  return {
    navigateToWorksheetV1,
    dealTableFilter,
    setDealTableFilter,
    selectedDealTableData,
    navigateToWorksheet,
    dataRank,
    rankedSort,
    searchDeal,
    searchResponse,
    addDealProperties,
    addDealPropertiesApiResponse,
    getDeal,
    getDealResponse,
    getDeals,
    getDealsResponse,
    getDealUserRelations,
    getDealUserRelationsResponse,
    createDeal,
    createDealApiResponse,
    updateDeal,
    updateDealApiResponse,
    setSelectedDeal,
    selectedDeal: selectedDeal || undefined,
    dealState,
    updateBatch,
    getDealParties,
    getDealRelations,
    getDealPartiesApiResponse,
    getDealPropertiesApi,
    getDealPropertiesApiResponse,
    updateDealsHandler,
    getDealQuote,
    getDealQuoteData,
    getDealQuoteDataResponse,
    updateDealQuote,
    getDealQuoteResponse,
    updateDealQuoteResponse,
    setDealTableSelectedDealId,
    dealTableSelectedDealId,
    updateRateLock,
    updateRateLockResponse,
    postRateLock,
    postRateLockResponse,
    getRateLock,
    getRateLockResponse,
    getDealTableCursor,
    setDealTableCursor,
    selectedId,
    selectedIdString,
    dealAddCallbackHandler,
    dealSearchState,
    selectedDealPageView,
    setSelectedDealPageView,
    updateDealMilestoneHandler
  };
};
