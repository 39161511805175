// Need to use the React-specific entry point to allow generating React hooks
import { TaskRuleTemplate } from "@elphi/types";
import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import { serviceBuilder } from "../builders/api.builder";
import { sortComparer } from "../utils/sort.utils";

export const taskRuleEntityAdapter = createEntityAdapter<TaskRuleTemplate>({
  sortComparer
});

// Define a service using a base URL and expected endpoints
export const baseTaskRuleApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "taskRuleApi",
  tagTypes: ["Task", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "task-rule"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: taskRuleEntityAdapter
  })
});

export const taskRuleApi = baseTaskRuleApi.injectEndpoints({
  endpoints: (builder) => ({
    batchUpdate: builder.mutation<
      { batch: string[] },
      { tasks: ({ id: string } & Partial<TaskRuleTemplate>)[] }
    >({
      query: (r) => {
        return {
          url: `/update-batch`,
          method: "PUT",
          body: r
        };
      }
    }),
    search: builder.query<EntityState<TaskRuleTemplate>, string>({
      query: (query) => {
        return {
          url: `/search?q=${query}`,
          method: "GET"
        };
      },
      transformResponse: (response: { results: TaskRuleTemplate[] }) => {
        const adapter = taskRuleEntityAdapter.addMany(
          taskRuleEntityAdapter.getInitialState(),
          response.results
        );
        return { ...adapter };
      }
    }),
    getBatch: builder.query<{ batch: EntityState<TaskRuleTemplate> }, string[]>(
      {
        query: (ids) => {
          return {
            url: `/get-batch`,
            method: "POST",
            body: { ids }
          };
        },
        transformResponse: (response: { results: TaskRuleTemplate[] }) => {
          const adapter = taskRuleEntityAdapter.addMany(
            taskRuleEntityAdapter.getInitialState(),
            response.results
          );

          return { batch: adapter };
        }
      }
    )
  })
});
