import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Tooltip,
  useDisclosure
} from "@chakra-ui/react";
import { AggregationType, Asset, Statement } from "@elphi/types";
import { EntityId } from "@reduxjs/toolkit";
import { orderBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { AppConfig } from "../../config/appConfig";
import { getSpecs } from "../../forms/schemas/factories/specsFactory";
import {useAssetHooks} from "../../hooks/asset.hooks";
import { useSnapshotHooks } from "../../hooks/snapshot.hooks";
import useStatementHooks from "../../hooks/statement.hooks";
import { RootState } from "../../redux/store";
import { StatementSliceState } from "../../redux/v2/statement";
import { ButtonIconRepeatClock } from "../button-icon/ButtonIcon";
import { useFormBuilderStateHandler } from "../form-builder/InputBuilder";
import { getFocusedAggregationSpecs } from "../form-builder/field-specs/utils/aggregation.utils";
import { ElphiTable } from "../table/ElphiTableComponent";
import { ElphiCellType, ElphiTableProps } from "../table/table.types";
import { TableInput } from "../utils/tableUtils";
import { DeleteStatementModal } from "./DeleteStatementComponent";
import { assetToString } from "./utils/printUtils";

export const StatementHistoryModalContainer = (props: {
  selectedAsset: Asset;
  snapshotId?: string;
}) => {
  return props.snapshotId ? (
    <StatementHistoryModalSnapshotContainer
      {...props}
      snapshotId={props.snapshotId}
    />
  ) : (
    <StatementHistoryModalLiveStateContainer {...props} />
  );
};
const StatementHistoryModalSnapshotContainer = (props: {
  selectedAsset: Asset;
  snapshotId: string;
}) => {
  const { snapshotDataState } = useSnapshotHooks();
  const snapshot = snapshotDataState({
    snapshotId: props.snapshotId
  });
  if (!snapshot.statementState) return <>empty statement state</>;
  return (
    <StatementHistoryModal
      selectedAsset={props.selectedAsset}
      isLoading={false}
      isDisabled={true}
      statementState={snapshot.statementState}
    />
  );
};

const StatementHistoryModalLiveStateContainer = (props: {
  selectedAsset: Asset;
}) => {
  const statementState = useSelector((state: RootState) => state.statement);
  const { handleUpdateStatementBatch } = useStatementHooks();
  const { getAssetStatements, getAssetStatementsResponse } = useAssetHooks();

  const isLoading =
    getAssetStatementsResponse.isUninitialized ||
    getAssetStatementsResponse.isFetching ||
    getAssetStatementsResponse.isLoading;

  return (
    <StatementHistoryModal
      selectedAsset={props.selectedAsset}
      isLoading={isLoading}
      handleUpdateStatementBatch={handleUpdateStatementBatch}
      getAssetStatements={getAssetStatements}
      statementState={statementState}
    />
  );
};

const StatementHistoryModal = (props: {
  selectedAsset: Asset;
  isDisabled?: boolean;
  isLoading?: boolean;
  getAssetStatements?: ReturnType<typeof useAssetHooks>["getAssetStatements"];
  handleUpdateStatementBatch?: ReturnType<
    typeof useStatementHooks
  >["handleUpdateStatementBatch"];
  statementState: StatementSliceState;
}) => {
  const {
    selectedAsset,
    getAssetStatements,
    handleUpdateStatementBatch,

    isLoading
  } = props;
  const PAGE_SIZE = 10;
  const [pageIndex, setPageIndex] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const statementState = useSelector((state: RootState) => state.statement);
  const fieldsSpecs = getSpecs();
  const statementFieldSpecs = fieldsSpecs.statement?.entitySpecs;
  const {
    onChange: onStatementChange,
    state: localStatementState,
    syncState: syncStatementState
  } = useFormBuilderStateHandler({
    initialState: {
      statements: {}
    },
    callback: handleUpdateStatementBatch,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  const getStatementRowData = useCallback(
    (currentStatementId: EntityId) => {
      return {
        statement: statementState.entities[currentStatementId]
      };
    },
    [statementState]
  );

  const pageData = useMemo(() => {
    const currentStatements = orderBy(
      Object.values(statementState.entities).filter(
        (statement: Statement) =>
          (statement?.assetId || "") === selectedAsset.id
      ),
      ["end", "createdAt"],
      ["desc", "desc"]
    );
    return currentStatements.map((statement: Statement) =>
      getStatementRowData(statement.id)
    );
  }, [statementState, pageIndex]);

  const pageDataMemo = useMemo(() => {
    return pageData.slice((pageIndex - 1) * PAGE_SIZE, pageIndex * PAGE_SIZE);
  }, [pageIndex, pageData]);

  useEffect(() => {
    if (statementState) {
      syncStatementState({
        shouldSync: !!statementState,
        state: statementState.entities,
        statePath: () => {
          return ["statements"];
        }
      });
    }
  }, [statementState]);

  useEffect(() => {
    if (isOpen && selectedAsset && selectedAsset.id)
      getAssetStatements?.({ assetIds: [selectedAsset.id] });
  }, [isOpen, selectedAsset]);

  const statementTableFullDataRows: ElphiTableProps["rows"] = useMemo(() => {
    return (
      pageDataMemo?.flatMap((rowData, i) => {
        const rowStatement = (rowData.statement || {}) as Statement;
        return {
          cells: [
            {
              index: 0,
              data: (
                <TableInput
                  isDisabled={props.isDisabled}
                  specFields={statementFieldSpecs?.start}
                  prefix={["statements", rowStatement.id]}
                  state={localStatementState}
                  currentEntity={rowStatement}
                  onChange={onStatementChange}
                />
              ),
              type: ElphiCellType.Element,
              maxWidth: "350px",
              minWidth: "350px"
            },
            {
              index: 1,
              data: (
                <TableInput
                  isDisabled={props.isDisabled}
                  specFields={statementFieldSpecs?.end}
                  prefix={["statements", rowStatement.id]}
                  state={localStatementState}
                  currentEntity={rowStatement}
                  onChange={onStatementChange}
                />
              ),
              type: ElphiCellType.Element,
              maxWidth: "350px",
              minWidth: "350px"
            },
            {
              index: 2,
              data: (
                <TableInput
                  isDisabled={props.isDisabled}
                  specFields={statementFieldSpecs?.statementBalance}
                  prefix={["statements", rowStatement.id]}
                  state={localStatementState}
                  currentEntity={rowStatement}
                  onChange={onStatementChange}
                />
              ),
              type: ElphiCellType.Element,
              maxWidth: "350px",
              minWidth: "350px"
            },
            {
              index: 3,
              data: (
                <TableInput
                  isDisabled={props.isDisabled}
                  specFields={statementFieldSpecs?.unverifiedDeposits}
                  prefix={["statements", rowStatement.id]}
                  state={localStatementState}
                  currentEntity={rowStatement}
                  onChange={onStatementChange}
                />
              ),
              type: ElphiCellType.Element,
              maxWidth: "350px",
              minWidth: "350px"
            },
            {
              index: 4,
              data: (
                <TableInput
                  isDisabled={props.isDisabled}
                  specFields={statementFieldSpecs?.percentConsidered}
                  prefix={["statements", rowStatement.id]}
                  state={localStatementState}
                  currentEntity={rowStatement}
                  onChange={onStatementChange}
                />
              ),
              type: ElphiCellType.Element,
              maxWidth: "350px",
              minWidth: "350px"
            },
            {
              index: 5,
              data: (
                <TableInput
                  isDisabled={props.isDisabled}
                  isAggregation={AggregationType.Aggregation}
                  specFields={getFocusedAggregationSpecs({
                    spec: statementFieldSpecs?.aggregations?.qualifyingBalance,
                    focused:
                      localStatementState.statements[rowStatement?.id || ""]
                        ?.aggregations?.qualifyingBalance?.focused
                  })}
                  prefix={["statements", rowStatement.id]}
                  state={localStatementState}
                  currentEntity={
                    localStatementState.statements[
                      rowStatement?.id || ""
                    ] as Statement
                  }
                  onChange={onStatementChange}
                />
              ),
              type: ElphiCellType.Element,
              maxWidth: "450px",
              minWidth: "450px"
            },
            {
              index: 6,
              data: (
                <HStack spacing="10px" justifyContent={"center"}>
                  <Tooltip label="Delete statement">
                    <Center>
                      <DeleteStatementModal
                        selectedStatement={rowStatement}
                        isDisabled={props.isDisabled}
                      />
                    </Center>
                  </Tooltip>
                </HStack>
              ),
              type: ElphiCellType.Element,
              maxWidth: "350px",
              minWidth: "350px"
            }
          ],
          index: rowData.statement?.index || `${i}`,
          minHeight: "53px",
          maxHeight: "53px"
        };
      }) || []
    );
  }, [pageDataMemo, statementState, localStatementState]);

  const statementTableHeader: ElphiTableProps["header"] = useMemo(() => {
    return [
      {
        index: 0,
        data: "Statement Period Start Date",
        type: ElphiCellType.String,
        maxWidth: "350px",
        minWidth: "350px"
      },
      {
        index: 1,
        data: "Statement Period End Date",
        type: ElphiCellType.String,
        maxWidth: "350px",
        minWidth: "350px"
      },
      {
        index: 2,
        data: "Statement Balance",
        type: ElphiCellType.String,
        maxWidth: "350px",
        minWidth: "350px"
      },
      {
        index: 3,
        data: "Statement Unverified Deposits",
        type: ElphiCellType.String,
        maxWidth: "350px",
        minWidth: "350px"
      },
      {
        index: 4,
        data: "Statement % Considered",
        type: ElphiCellType.String,
        maxWidth: "350px",
        minWidth: "350px"
      },
      {
        index: 5,
        data: "Statement Qualifying Balance",
        type: ElphiCellType.String,
        maxWidth: "450px",
        minWidth: "450px"
      },
      {
        index: 6,
        data: "Actions",
        type: ElphiCellType.String,
        maxWidth: "200px",
        minWidth: "200px"
      }
    ];
  }, []);

  const statementTableProps: ElphiTableProps = useMemo(() => {
    return {
      header: statementTableHeader,
      rows: statementTableFullDataRows,
      footer: (
        <Box>
          {isLoading && <Progress size="xs" isIndeterminate />}
          <Flex w="100%">
            <Box>
              page:{pageIndex} / {Math.floor(pageData.length / PAGE_SIZE + 1)}
            </Box>
            <Box pl="20px">
              <Button
                disabled={pageIndex <= 1}
                onClick={() => {
                  setPageIndex(pageIndex - 1);
                }}
              >
                Prev
              </Button>
            </Box>
            <Box pl="10px">
              <Button
                disabled={pageIndex * PAGE_SIZE >= pageData.length}
                onClick={() => {
                  setPageIndex(pageIndex + 1);
                }}
              >
                Next
              </Button>
            </Box>
          </Flex>
        </Box>
      ),
      isLoading: isLoading || false,
      rowsCount: 1
    };
  }, [statementTableFullDataRows, pageIndex, isLoading]);

  const mainModal = (
    <Box>
      <Flex justifyContent={"space-between"}>
        <ElphiTable
          minHeight={"300px"}
          maxHeight={"825px"}
          header={statementTableProps.header}
          rows={statementTableProps.rows}
          footer={statementTableProps.footer}
          isLoading={statementTableProps.isLoading}
          rowsCount={1}
        />
      </Flex>
    </Box>
  );

  return (
    <Box>
      {ButtonIconRepeatClock({ onClick: onOpen })}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minWidth={"90%"}>
          <ModalHeader>
            {assetToString({ asset: selectedAsset })} Statement History
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>{mainModal}</ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
