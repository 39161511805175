import { TaskTemplate } from "@elphi/types";
import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";
import { taskTemplateSlice } from "./taskTemplate.slice";

export const taskTemplateAction: ActionDispatcher<TaskTemplate> = (
  dispatch,
  callback
) => {
  const slice = taskTemplateSlice;
  const actionDispatcher = buildAction(slice);
  return actionDispatcher(dispatch, callback);
};

