import { RolodexServiceProviderView } from "@elphi/types";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../../config/appConfig";
import { serviceBuilder } from "../../builders/api.builder";
import { serviceProviderViewAdapter } from "./serviceProviderView.adapter";

export type RolodexServiceProviderViewRequest = Partial<{
  dealId: string;
  partyId: string;
}>;

export type RolodexServiceProviderViewResponse = {
  result: RolodexServiceProviderView[];
};

export type RolodexServiceProviderViewPaginateV2Request =
  RolodexServiceProviderViewRequest &
    Partial<{
      cursor: string;
      limit: string;
    }>;

export type RolodexServiceProviderViewPaginateV2Response = {
  nextCursor?: string;
  hasMore: boolean;
  page: RolodexServiceProviderView[];
};

export const baseServiceProviderViewApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "serviceProviderViewApi",
  tagTypes: ["ServiceProvider", "Paginate", "View"],
  baseQuery: serviceBuilder.baseQuery({
    route: "rolodex"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: serviceProviderViewAdapter
  })
});

export const serviceProviderViewApi =
  baseServiceProviderViewApi.injectEndpoints({
    endpoints: (builder) => ({
      getAll: builder.query<
        RolodexServiceProviderViewResponse,
        RolodexServiceProviderViewRequest
      >({
        query: (r) => {
          const params: string[] = [];
          r.dealId && params.push(`dealId=${r.dealId}`);
          r.partyId && params.push(`partyId=${r.partyId}`);
          const baseUrl = "/get-all";
          const url = params.length
            ? `${baseUrl}?${params.join("&")}`
            : baseUrl;

          return {
            url,
            method: "GET"
          };
        }
      })
    })
  });
