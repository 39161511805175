import { Role } from "@elphi/types";
import { Dispatch, EntityId, Update } from "@reduxjs/toolkit";
import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";
import { roleSlice } from "./slice";
const slice = roleSlice;
export const roleAction: ActionDispatcher<Role> = (
  dispatch: Dispatch,
  callback?: {
    add?: (r: Role[]) => void;
    update?: (r: Update<Role>[]) => void;
    remove?: (r: EntityId[]) => void;
  }
) => {
  const actionDispatcher = buildAction(slice);
  return actionDispatcher(dispatch, callback);
};
