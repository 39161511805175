import { AuditEvent, AuditEventLinkResponse } from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import { RootState } from "../../store";
import { serviceBuilder } from "../builders/api.builder";
import { auditEventEntityAdapter } from "./auditEvent.adapter";

export type AuditEventPaginateV2Response = {
  nextCursor?: string;
  hasMore: boolean;
  page: AuditEvent[];
};

export type AuditEventPaginateV2Request = Partial<{
  cursor: string;
  limit: string;
  dealId: string;
  entityId: string;
  entityTypes: string[];
  fields: string[];
  auditTypes: string[];
  eventTypes: string[];
  users: string[];
  createdAt: string;
}>;

export const auditEventSelector =
  auditEventEntityAdapter.getSelectors<RootState>((state) => state.auditEvent);

export const baseAuditEventApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "auditEventApi",
  tagTypes: ["AuditEvent", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "audit-event"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: auditEventEntityAdapter
  })
});

export const auditEventApi = baseAuditEventApi.injectEndpoints({
  endpoints: (builder) => ({
    paginateV2: builder.query<
      AuditEventPaginateV2Response,
      AuditEventPaginateV2Request
    >({
      query: (r) => {
        const cursor = r.cursor ? `cursor=${r.cursor}` : null;
        const limit = r.limit ? `limit=${r.limit}` : null;
        const dealId = r.dealId ? `dealId=${r.dealId}` : null;
        const entityId = r.entityId ? `entityId=${r.entityId}` : null;

        const entityTypes = r.entityTypes?.length
          ? `entityTypes=${r.entityTypes.join(";")}`
          : null;

        const fields = r.fields?.length ? `fields=${r.fields.join(";")}` : null;

        const auditTypes = r.auditTypes?.length
          ? `auditTypes=${r.auditTypes.join(";")}`
          : null;

        const eventTypes = r.eventTypes?.length
          ? `eventTypes=${r.eventTypes.join(";")}`
          : null;

        const users = r.users?.length ? `users=${r.users.join(";")}` : null;
        const createdAt = r.createdAt ? `createdAt=${r.createdAt}` : null;

        const queryParams = [
          cursor,
          limit,
          dealId,
          entityId,
          entityTypes,
          fields,
          auditTypes,
          eventTypes,
          users,
          createdAt
        ]
          .filter(removeEmpty)
          .join("&");
        const query = queryParams ? `?${queryParams}` : "";
        const url = `/v2/paginate${query}`;
        return {
          url,
          method: "GET"
        };
      }
    }),
    getLinks: builder.query<
      { id: string; links: AuditEventLinkResponse },
      { id: string }
    >({
      query: (r) => {
        return {
          url: `/links/${r.id}`,
          method: "GET"
        };
      },
      transformResponse: (response: AuditEventLinkResponse, _, arg) => {
        return {
          id: arg.id,
          links: response
        };
      }
    })
  })
});
