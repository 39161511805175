import { FirebaseFilter, TaskInstruction } from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import { serviceBuilder } from "../builders/api.builder";
import { taskInstructionAdapter } from "./taskInstruction.adapter";

export type TaskInstructionPaginateV2Response = {
  nextCursor?: string;
  hasMore: boolean;
  page: TaskInstruction[];
};

export const baseTaskInstructionApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "taskInstructionApi",
  tagTypes: ["taskInstruction", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "task-instruction"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: taskInstructionAdapter
  })
});

export const taskInstructionApi = baseTaskInstructionApi.injectEndpoints({
  endpoints: (builder) => ({
    updateBatch: builder.mutation<
      { batch: string[] },
      { groups: ({ id: string } & Partial<TaskInstruction>)[] }
    >({
      query: (r) => {
        return {
          url: `/update-batch`,
          method: "PUT",
          body: r
        };
      }
    }),
    search: builder.query<
      {
        query: string;
        hasMore: boolean;
        nextCursor: string | null;
        results: EntityState<TaskInstruction>;
      },
      { query: string; cursor?: string }
    >({
      query: (r) => {
        return {
          url: `/search?q=${r.query}${r.cursor ? `&cursor=${r.cursor}` : ""}`,
          method: "GET"
        };
      },
      transformResponse: (
        response: {
          results: TaskInstruction[];
          nextCursor: string | null;
          hasMore: boolean;
        },
        _,
        arg
      ) => {
        const adapter = taskInstructionAdapter.addMany(
          taskInstructionAdapter.getInitialState(),
          response.results
        );
        return {
          query: arg.query,
          results: adapter,
          nextCursor: response.nextCursor,
          hasMore: response.hasMore
        };
      }
    }),
    paginateV2: builder.query<
      TaskInstructionPaginateV2Response,
      {
        cursor?: string;
        options?: {
          order?: "desc" | "asc";
          filter?: FirebaseFilter | FirebaseFilter[];
        };
      }
    >({
      query: (r) => {
        return {
          url: `/paginate`,
          method: "POST",
          body: r
        };
      }
    }),
    getBatch: builder.query<{ batch: EntityState<TaskInstruction> }, string[]>({
      query: (ids) => {
        return {
          url: `/get-batch`,
          method: "POST",
          body: { ids }
        };
      },
      transformResponse: (response: { results: TaskInstruction[] }) => {
        const adapter = taskInstructionAdapter.addMany(
          taskInstructionAdapter.getInitialState(),
          response.results
        );

        return { batch: adapter };
      }
    })
  })
});
