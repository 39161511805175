import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../../config/appConfig";
import { RootState } from "../../../store";
import { serviceBuilder } from "../../builders/api.builder";
import { taskTemplateEntityAdapter } from "../../task-template/taskTemplate.adapter";
export const taskTemplateSelector =
  taskTemplateEntityAdapter.getSelectors<RootState>(
    (state) => state.taskTemplate
  );

export const rentRangeApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "rentRangeApi",
  tagTypes: ["RentRange", "Integration", "Task"],
  baseQuery: serviceBuilder.baseQuery({
    route: "/integration/rental-data/rent-range"
  }),
  endpoints: (builder) => ({
    createOrderReport: builder.mutation<{ taskId: string }, { taskId: string }>(
      {
        query: (r) => {
          return {
            url: `/create`,
            method: "POST",
            body: r
          };
        }
      }
    ),
    retryOrderReport: builder.mutation<{ taskId: string }, { taskId: string }>({
      query: (r) => {
        return {
          url: `/retry`,
          method: "POST",
          body: r
        };
      }
    })
  })
});
