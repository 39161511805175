import { Box, useDimensions } from "@chakra-ui/react";
import { Property } from "@elphi/types";
import { useRef } from "react";
import { ExpandAllButton } from "../../common-components/expandAllButton/ExpandAllButton";

import { usePropertyHooks } from "../../hooks/property.hooks";
import { NavigationPath } from "../../shared/types/navigation.types";

import FormBuilder, { OnChangeInput } from "../form-builder/FormBuilder";
import {PropertyScrollableSections} from "../property/PropertyScrollableSections";
import {DealPropertySearchFormWidget} from "./DealPropertySearchFormWidget";
import { TabFormProps } from "./TabForm";

export const ConstructionTabForm = (
  props: Pick<
    TabFormProps,
    "dealId" | "onChange" | "sections" | "isLoading"
  > & {
    selectedProperty?: Property;
    snapshotId?: string;
    navigationPath?: NavigationPath;
  }
) => {
  const { setSelectedProperty } = usePropertyHooks(); //TODO: move to props
  const { selectedProperty, navigationPath } = props;
  const onChange = (e: OnChangeInput) => {
    if (e.fieldKey[0] === "property") {
      const removedPropertyFieldKey = { ...e, fieldKey: e.fieldKey.slice(1) };
      props.onChange(removedPropertyFieldKey);
    } else props.onChange(e);
  };
  const elementRef = useRef(null);
  const dimensions = useDimensions(elementRef);
  const HEADER_HEIGHT = "80px";
  return (
    <>
      <Box h="100%" w="100%">
        <Box w="100%" minH={HEADER_HEIGHT}>
          <DealPropertySearchFormWidget
            snapshotId={props.snapshotId}
            dealId={props.dealId}
            setSelectedProperty={setSelectedProperty}
            selectedProperty={selectedProperty}
          />
        </Box>
        {!!navigationPath && selectedProperty && (
          <Box>
            <ExpandAllButton navigationPath={navigationPath} />
          </Box>
        )}
        <Box overflow={"scroll"} h="100%">
          <Box>
            <PropertyScrollableSections
              elementRef={elementRef}
              height={450}
              selectedProperty={selectedProperty}
              navigationPath={navigationPath}
              dimensions={dimensions}
              dealId={props.dealId}
              isDisabled={!!props.snapshotId}
              snapshotId={props.snapshotId}
            />
          </Box>
          {selectedProperty && (
            <Box h={`calc(100% - ${HEADER_HEIGHT})`} overflow="scroll">
              <Box bgColor="white">
                <FormBuilder
                  customKey="constructionTabForm"
                  onChange={props.snapshotId ? (_) => {} : onChange}
                  sections={props.sections}
                  navigationPath={navigationPath}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
