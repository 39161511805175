import {
  Box,
  Progress,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from "@chakra-ui/react";
import {
  Deal,
  FirebaseCollections,
  IntegrationType,
  Party,
  PartyType,
  PricingVendorType,
  Property
} from "@elphi/types";
import { DevFeatureFlag } from "@elphi/utils";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { ExpandAllButton } from "../../common-components/expandAllButton/ExpandAllButton";
import { useLosSettingsContextHooks } from "../../features/main/providers/los-settings/LosSettingsProvider";
import { auth } from "../../firebase/firebaseConfig";
import { useSections } from "../../forms/schemas/providers/SectionsProvider";
import { useDealHooks } from "../../hooks/deal.hooks";
import { useDealFormHandler } from "../../hooks/dealform.hooks";
import { useInsurancePolicyHooks } from "../../hooks/insurance-policy/insurancePolicy.hooks";
import { usePartyHooks } from "../../hooks/party.hooks";
import {
  PartyStructureState,
  usePartyFormHandler
} from "../../hooks/partyform.hooks";
import { usePropertyHooks } from "../../hooks/property.hooks";
import {
  PropertyStructureState,
  usePropertyFormHandler
} from "../../hooks/propertyform.hooks";
import { useRolodexBranchRepRelation } from "../../hooks/rolodexBranchRepRelation.hooks";
import { formatSnapshot, useSnapshotHooks } from "../../hooks/snapshot.hooks";
import { useStatsigGate } from "../../hooks/statsig.hooks";
import { RootState } from "../../redux/store";
import { DealSliceState } from "../../redux/v2/deal";
import { dealApi } from "../../redux/v2/deal/deal.service";
import { NavigationTabs } from "../../shared/types/navigation.types";
import { AccordionSections } from "../ScrollableSections";
import { DealPartyChartContainer } from "../deal/deal-party-chart/DealPartyChart";
import {
  OnChangeInput,
  Section,
  SectionHeader
} from "../form-builder/FormBuilder";
import { partyName } from "../party/utils/printUtils";
import { PosUserTabContainer } from "../pos-user/PosUserTabContainer";
import { TabsContainer } from "../tabs-container/TabsContainer";
import { ConstructionTabForm } from "./ConstructionTabForm";
import { InsurancePolicyTab } from "./InsurancePolicyTab";
import { PartyTabFormContainer } from "./PartyTabForm";
import { PropertyTabFormContainer } from "./PropertyTabForm";
import { ServiceProviderTab } from "./ServiceProviderTab";
import { TabFormContainer } from "./TabForm";
import { UserTabContainer } from "./UserTab";
import { navigationPaths } from "./navigationPaths.utils";
import { ExpandSections } from "./sections/expandSections";

export const ApplicationPageContainer = (props: {
  dealId?: string;
  snapshotId?: string;
}) => {
  const { dealId: dealIdOutlet, snapshotId: snapshotIdOutlet } =
    useOutletContext<{
      dealId?: string;
      snapshotId?: string;
    }>() || {};

  const { dealId = dealIdOutlet, snapshotId = snapshotIdOutlet } = props;

  return snapshotId ? (
    <ApplicationPageSnapshotContainer
      {...props}
      dealId={dealId!}
      snapshotId={snapshotId!}
    />
  ) : (
    <ApplicationPageLiveStateContainer {...props} dealId={dealId!} />
  );
};

const ApplicationPageSnapshotContainer = (props: {
  dealId: string;
  snapshotId: string;
}) => {
  const { snapshotState, getSnapshotDataResponse } = useSnapshotHooks();
  const { propertyState } = usePropertyHooks();
  const { partyTab } = usePartyHooks();

  const snapshot = useMemo(() => {
    const snapshotData = snapshotState.entities[props.snapshotId]?.snapshot;
    return {
      partyState: formatSnapshot<{ entities: { [id: string]: Party } }>({
        snapshot: snapshotData,
        collection: FirebaseCollections.Party
      }),
      propertyState: formatSnapshot<{ entities: { [id: string]: Property } }>({
        snapshot: snapshotData,
        collection: FirebaseCollections.Property
      }),
      dealState: formatSnapshot<DealSliceState>({
        snapshot: snapshotData,
        collection: FirebaseCollections.Deal
      })
    };
  }, [props.snapshotId, snapshotState.entities]);
  const highlightedParty = useMemo(
    () =>
      partyTab &&
      partyTab?.highlightedPartyId &&
      snapshot.partyState?.entities?.[partyTab.highlightedPartyId],
    [props.snapshotId, partyTab?.highlightedPartyId]
  );
  const selectedProperty = useMemo(
    () =>
      propertyState.selectedId &&
      snapshot.propertyState?.entities?.[propertyState.selectedId],
    [props.snapshotId, propertyState?.selectedId]
  );

  if (!snapshot.dealState || !snapshot.partyState) return <></>;
  return (
    <>
      {getSnapshotDataResponse.isLoading ? (
        <Progress size="xs" isIndeterminate />
      ) : (
        <ApplicationPage
          {...props}
          dealId={props.dealId}
          dealState={snapshot.dealState?.entities[props.dealId]!}
          partyState={{
            parties: snapshot.partyState?.entities || {}
          }}
          propertyState={{ properties: snapshot.propertyState?.entities || {} }}
          selectedProperty={selectedProperty || undefined}
          highlightedParty={highlightedParty || undefined}
          dealResponseIsLoading={false}
          propertiesIsLoading={false}
          partyOnChangeBatch={() => {}}
          propertyOnChangeBatch={() => {}}
          dealOnChange={() => {}}
        />
      )}
    </>
  );
};
const ApplicationPageLiveStateContainer = (props: { dealId: string }) => {
  const { getDeal, getDealResponse, dealTableSelectedDealId } = useDealHooks();
  const { selectedProperty, setSelectedProperty } = usePropertyHooks();
  const {
    partyTabHighlightedParty: highlightedParty,
    setSelectedTreeNodeKey,
    setHighlightedPartyTabParty,
    setHighlightedPartyTabPartyParent
  } = usePartyHooks();

  const { dealState, dealOnChange } = useDealFormHandler(props.dealId);
  const { propertyState, propertyOnChange } = usePropertyFormHandler();
  const { partyState, partyOnChange } = usePartyFormHandler();

  const dealSlice = useSelector((state: RootState) => state.deal);
  const [getDealProperties, dealPropertiesResponse] =
    dealApi.useLazyDealPropertiesQuery();
  const [getDealParties, _] = dealApi.useLazyDealPartiesQuery();
  const { getBranchRepByDealIdHandler } = useRolodexBranchRepRelation();
  const { getInsurancePoliciesByDealId } = useInsurancePolicyHooks();

  useEffect(() => {
    if (auth.currentUser && !dealSlice.entities[props.dealId]) {
      getDeal(props.dealId, true);
    }
  }, [auth.currentUser, props.dealId]);
  useEffect(() => {
    if (auth.currentUser && props.dealId) {
      getDealProperties([props.dealId], true);
    }
  }, [auth.currentUser, props.dealId]);
  useEffect(() => {
    if (auth.currentUser && props.dealId) {
      getDealParties([props.dealId], true);
    }
  }, [auth.currentUser, props.dealId]);

  useEffect(() => {
    if (auth.currentUser && props.dealId) {
      getInsurancePoliciesByDealId(props.dealId, true);
    }
  }, [auth.currentUser, props.dealId]);

  useEffect(() => {
    if (auth.currentUser && props.dealId) {
      getBranchRepByDealIdHandler(props.dealId, true);
    }
  }, [auth.currentUser, props.dealId]);
  useEffect(() => {
    setSelectedProperty("");
  }, []);

  const partyOnChangeBatch = (v: OnChangeInput) => {
    highlightedParty &&
      partyOnChange({
        fieldType: v.fieldType,
        fieldKey: ["parties", highlightedParty.id, ...v.fieldKey],
        value: v.value
      });
  };

  const propertyOnChangeBatch = async (v) => {
    selectedProperty &&
      propertyOnChange({
        fieldType: v.fieldType,
        fieldKey: ["properties", selectedProperty.id, ...v.fieldKey],
        value: v.value
      });
  };
  const dealId = props.dealId || dealTableSelectedDealId || "";

  useEffect(() => {
    setHighlightedPartyTabParty("");
    setSelectedTreeNodeKey("");
    setHighlightedPartyTabPartyParent("");
  }, [dealId]);

  const dealResponseIsLoading =
    getDealResponse.isLoading || getDealResponse.isFetching;
  const propertiesIsLoading =
    dealPropertiesResponse.isLoading || dealPropertiesResponse.isFetching;
  return (
    <ApplicationPage
      {...props}
      dealState={dealState}
      dealId={dealId}
      selectedProperty={selectedProperty}
      partyOnChangeBatch={partyOnChangeBatch}
      partyState={partyState}
      propertiesIsLoading={propertiesIsLoading}
      dealResponseIsLoading={dealResponseIsLoading}
      propertyOnChangeBatch={propertyOnChangeBatch}
      propertyState={propertyState}
      dealOnChange={dealOnChange}
      highlightedParty={highlightedParty}
    />
  );
};
const ApplicationPage = (props: {
  dealId: string;
  snapshotId?: string;
  partyOnChangeBatch: ReturnType<typeof usePartyFormHandler>["partyOnChange"]; //(v: OnChangeInput) => void
  propertyOnChangeBatch: ReturnType<
    typeof usePropertyFormHandler
  >["propertyOnChange"]; //(v: any) => Promise<void>
  dealOnChange: ReturnType<typeof useDealFormHandler>["dealOnChange"]; //(v: OnChangeInput) => void
  highlightedParty: ReturnType<
    typeof usePartyHooks
  >["partyTabHighlightedParty"];
  dealResponseIsLoading: boolean;
  propertiesIsLoading: boolean;
  propertyState: PropertyStructureState;
  partyState: PartyStructureState;
  dealState: Deal;
  selectedProperty?: Property;
}) => {
  const {
    partyOnChangeBatch,
    propertyOnChangeBatch,
    dealOnChange,
    dealResponseIsLoading,
    propertiesIsLoading,
    propertyState,
    partyState,
    dealState,
    selectedProperty,
    highlightedParty
  } = props;
  const [expandSections, setExpandSections] = useState<ExpandSections>({});
  const { sections } = useSections();
  const losSettings = useLosSettingsContextHooks();
  const { value: isOrgManagementEnable } = useStatsigGate(
    DevFeatureFlag.ESD_2826_Organization_Management
  );
  const partySectionByTypeLogic = useMemo(() => {
    if (partyState && highlightedParty) {
      const currentPartyState = partyState.parties?.[highlightedParty.id];
      const { party: partySections } = sections || {};
      if (currentPartyState?.PartyType === PartyType.Individual) {
        return {
          sections: [
            partySections?.individualTaxIdentifierSection?.({
              state: currentPartyState,
              onChange: partyOnChangeBatch,
              options: {
                gates: {
                  [DevFeatureFlag.ESD_2826_Organization_Management]:
                    isOrgManagementEnable
                }
              }
            }),
            partySections?.individualInfoSection?.({
              state: currentPartyState,
              onChange: partyOnChangeBatch
            }),
            partySections?.addressSection?.({
              state: currentPartyState,
              title: "Individual Address",
              onChange: partyOnChangeBatch
            }),
            partySections?.partyShortTermExperienceSection?.({
              state: currentPartyState,
              onChange: partyOnChangeBatch
            }),
            partySections?.individualPartyDeclarationsSection?.({
              state: currentPartyState,
              onChange: partyOnChangeBatch
            })
          ],
          afterTableSections: [
            partySections?.concentrationReviewSection?.({
              state: currentPartyState,
              onChange: partyOnChangeBatch
            })
          ],
          expandSections: expandSections
        };
      } else if (currentPartyState?.PartyType === PartyType.Entity) {
        return {
          sections: [
            partySections?.partyMilestoneSection?.({
              state: currentPartyState,
              onChange: partyOnChangeBatch
            }),
            partySections?.entityTaxIdentifierSection?.({
              state: currentPartyState,
              onChange: partyOnChangeBatch,
              options: {
                gates: {
                  [DevFeatureFlag.ESD_2826_Organization_Management]:
                    isOrgManagementEnable
                }
              }
            }),
            partySections?.entityInfoSection?.({
              state: currentPartyState,
              onChange: partyOnChangeBatch
            }),
            partySections?.addressSection?.({
              state: currentPartyState,
              onChange: partyOnChangeBatch,
              title: "Entity Address"
            })
          ],
          expandSections: expandSections
        };
      }
    }
    return {} as {
      sections: Section[];
      expandSections: ExpandSections;
    };
  }, [partyState, dealState, highlightedParty]);

  const HEADER_HEIGHT = "80px";

  const {
    deal: dealSections,
    property: propertySections,
    integrations
  } = sections || {};

  const applicationDealUnderwritingSectionsMemo = useMemo(() => {
    return [
      dealSections?.limaUnderwritingDealApprovalSection,
      dealSections?.creditReviewCommitteeApprovalSection,
      dealSections?.pricingEngineOverridesAndLLPAsSection,
      dealSections?.limaExceptionSection,
      dealSections?.miscellaneousApplicationInformationSection
    ].map((section) =>
      section?.({
        state: dealState,
        onChange: dealOnChange
      })
    );
  }, [dealState, dealSections]);
  const applicationConstructionManagementSectionsMemo = useMemo(() => {
    return [
      propertySections?.generalSection?.({
        state:
          (selectedProperty && propertyState.properties[selectedProperty.id]) ||
          {},
        onChange: dealOnChange
      })
    ];
  }, [selectedProperty, propertyState, dealSections]);

  const applicationDealSectionsMemo = useMemo(() => {
    return [
      dealSections?.loanInformationSection,
      dealSections?.dealTotalsSection,
      dealSections?.loanTermsSection,
      dealSections?.loanTermsARMSection,
      dealSections?.loanTermsCustomSection,
      dealSections?.baseUnderwritingDealApprovalSection,
      dealSections?.baseExceptionSection,
      integrations?.deal?.[IntegrationType.Pricing]?.[
        PricingVendorType.Loanpass
      ]?.loanPassSection,
      dealSections?.propertyManagementQuestionnaireSection
    ].map((section) =>
      section?.({
        state: dealState,
        onChange: dealOnChange
      })
    );
  }, [dealState, dealSections]);

  const applicationThirdPartySections = [
    dealSections?.thirdPartiesTitleCompanyClosingAttorneySection,
    dealSections?.generalContractorSection,
    dealSections?.brokerSection,
    dealSections?.lenderFinanceSection
  ].map((section) =>
    section?.({
      state: dealState,
      onChange: dealOnChange
    })
  );

  const applicationClosingSections = [
    dealSections?.titleCompanyClosingAttorneySection,
    dealSections?.closingLoanTermsSection,
    dealSections?.closingLoanTermsARMSection,
    dealSections?.closingLoanTermsCustomSection,
    dealSections?.ledgerSection,
    dealSections?.escrowsImpoundsSection,
    dealSections?.titleCommitmentSection,
    dealSections?.settlementStatementSection,
    dealSections?.titleFormsSection,
    dealSections?.reservesSection,
    dealSections?.closingPreFundingSection
  ].map((section) =>
    section?.({
      state: dealState,
      onChange: dealOnChange
    })
  );

  const postClosingSections = [
    dealSections?.preFundingSection,
    dealSections?.promissoryNoteWarehouseTrackingSection,
    dealSections?.promissoryNoteMFASection,
    dealSections?.userAssignmentSection,
    dealSections?.assignmentSection,
    dealSections?.custodianCollateralTrackingSection,
    dealSections?.electronicExecutedLoanPackageSection,
    dealSections?.physicalExecutedLoanPackageSection,
    dealSections?.servicingSection,
    dealSections?.capitalMarketsSection,
    dealSections?.secondaryMarketSection
  ].map((section) =>
    section?.({ state: props.dealState || {}, onChange: dealOnChange })
  );

  const userTabs = useMemo(() => {
    const baseUserTabs = [
      {
        label: "LOS Users",
        page: (
          <UserTabContainer
            dealId={props.dealId}
            snapshotId={props.snapshotId}
            loanIdentifier={dealState?.LoanIdentifier}
          />
        )
      }
    ];
    return !losSettings?.enablePosUser
      ? baseUserTabs
      : baseUserTabs.concat([
          {
            label: "POS Users",
            page: (
              <PosUserTabContainer
                dealId={props.dealId}
                loanIdentifier={dealState?.LoanIdentifier}
              />
            )
          }
        ]);
  }, [losSettings?.enablePosUser, props.snapshotId, dealState?.LoanIdentifier]);

  const shouldShowThirdPartyTab =
    !!applicationThirdPartySections.filter(removeEmpty).length;
  const shouldShowConstructionManagementTab =
    !!applicationConstructionManagementSectionsMemo.filter(removeEmpty).length;
  const shouldShowDealUnderwritingTab =
    !!applicationDealUnderwritingSectionsMemo.filter(removeEmpty).length;
  return (
    <Box h="100%" w="100%" overflow="hidden">
      <Tabs
        isLazy
        lazyBehavior="unmount"
        h="100%"
        w="100%"
        position="sticky"
        top="0"
      >
        <TabList color={props.snapshotId ? "black" : "black"}>
          <Tab>Users</Tab>
          <Tab>Deal</Tab>
          <Tab hidden={!shouldShowDealUnderwritingTab}>Deal Underwriting</Tab>
          <Tab>Parties</Tab>
          <Tab>Properties</Tab>
          <Tab hidden={!shouldShowConstructionManagementTab}>
            Construction Management
          </Tab>
          <Tab hidden={!shouldShowThirdPartyTab}>Third Parties</Tab>
          <Tab>Closing</Tab>
          <Tab>Post-Closing</Tab>
          <Tab>Service Providers</Tab>
          <Tab>Insurance Policies</Tab>
        </TabList>
        <TabPanels h={`calc(100% - ${HEADER_HEIGHT})`} w="100%" p="0">
          <TabPanel h="100%" w="100%" p="0">
            <TabsContainer tabs={userTabs} />
          </TabPanel>
          <TabPanel h="100%" w="100%" p="0">
            <TabFormContainer
              customKey="applicationDealSectionsMemo"
              snapshotId={props.snapshotId}
              dealId={props.dealId}
              isLoading={dealResponseIsLoading}
              onChange={dealOnChange}
              sections={applicationDealSectionsMemo}
              enableExpandAll={true}
              navigationPath={navigationPaths[NavigationTabs.Deal]}
            />
          </TabPanel>
          <TabPanel h="100%" w="100%" p="0">
            <TabFormContainer
              customKey="applicationDealUnderwritingSectionsMemo"
              snapshotId={props.snapshotId}
              dealId={props.dealId}
              isLoading={dealResponseIsLoading}
              onChange={dealOnChange}
              sections={applicationDealUnderwritingSectionsMemo}
              enableExpandAll={true}
              navigationPath={navigationPaths[NavigationTabs.DealUnderwriting]}
            />
          </TabPanel>
          <TabPanel h="100%" w="100%" p="0">
            <Box h="100%" overflow="scroll">
              {!!highlightedParty && (
                <Box>
                  <ExpandAllButton
                    navigationPath={navigationPaths[NavigationTabs.Parties]!!}
                  />
                </Box>
              )}
              <Box bgColor={"white"} position="sticky" top="0" zIndex="5">
                <AccordionSections
                  lazyLoad={true}
                  customKey={"applicationPartyTree"}
                  defaultIndex={[0]}
                  sections={[
                    {
                      header: (
                        <SectionHeader
                          header={`Party Tree ${
                            (highlightedParty && partyName(highlightedParty)) ||
                            ""
                          }`}
                        />
                      ),
                      body: (
                        <Box
                          overflow="scroll"
                          resize="vertical"
                          bgColor={"white"}
                        >
                          <Box>
                            <DealPartyChartContainer
                              dealId={props.dealId}
                              snapshotId={props.snapshotId}
                              expandSections={expandSections}
                              setExpandSections={setExpandSections}
                            />
                          </Box>
                        </Box>
                      )
                    }
                  ]}
                  navigationPath={navigationPaths[NavigationTabs.Parties]}
                />
              </Box>
              <PartyTabFormContainer
                customKey="partySectionByTypeLogic"
                snapshotId={props.snapshotId}
                dealId={props.dealId}
                onChange={partyOnChangeBatch}
                isLoading={false}
                sections={partySectionByTypeLogic}
                navigationPath={navigationPaths[NavigationTabs.Parties]}
              />
            </Box>
          </TabPanel>
          <TabPanel h="100%" w="100%" p="0">
            <PropertyTabFormContainer
              snapshotId={props.snapshotId}
              dealId={props.dealId}
              isLoading={propertiesIsLoading}
              onChange={propertyOnChangeBatch}
              dealState={dealState}
              navigationPath={navigationPaths[NavigationTabs.Propersties]}
            />
          </TabPanel>
          <TabPanel h="100%" w="100%" p="0">
            <ConstructionTabForm
              snapshotId={props.snapshotId}
              isLoading={propertiesIsLoading}
              dealId={props.dealId}
              onChange={propertyOnChangeBatch}
              selectedProperty={selectedProperty}
              sections={applicationConstructionManagementSectionsMemo}
              navigationPath={
                navigationPaths[NavigationTabs.ConstructionManagement]
              }
            />
          </TabPanel>
          <TabPanel h="100%" w="100%" p="0">
            <TabFormContainer
              customKey="applicationThirdPartySections"
              snapshotId={props.snapshotId}
              dealId={props.dealId}
              isLoading={dealResponseIsLoading}
              onChange={dealOnChange}
              sections={applicationThirdPartySections}
              enableExpandAll={true}
              navigationPath={navigationPaths[NavigationTabs.ThirdParties]}
            />
          </TabPanel>
          <TabPanel h="100%" w="100%" p="0">
            <TabFormContainer
              customKey="applicationClosingSections"
              snapshotId={props.snapshotId}
              dealId={props.dealId}
              isLoading={dealResponseIsLoading}
              onChange={dealOnChange}
              sections={applicationClosingSections}
              enableExpandAll={true}
              navigationPath={navigationPaths[NavigationTabs.Closing]}
            />
          </TabPanel>
          <TabPanel h="100%" w="100%" p="0">
            <TabFormContainer
              customKey="applicationPostClosingSections"
              snapshotId={props.snapshotId}
              dealId={props.dealId}
              isLoading={dealResponseIsLoading}
              onChange={dealOnChange}
              sections={postClosingSections}
              enableExpandAll={true}
              navigationPath={navigationPaths[NavigationTabs.PostClosing]}
            />
          </TabPanel>
          <TabPanel h="100%" w="100%" p="0">
            <ServiceProviderTab dealId={props.dealId} />
          </TabPanel>
          <TabPanel h="100%" w="100%" p="0">
            <InsurancePolicyTab dealId={props.dealId} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
