import firebaseAdmin from "firebase-admin";
export enum StatusCode {
  OK = 200,
  Created = 201,
  BadRequest = 400,
  Forbidden = 403,
  UnAuthorized = 401,
  NotFound = 404,
  Failed = 500,
  ContinuesBatch = 600
}
export type ServiceResponse<TType extends StatusCode, TPayload> = {
  statusCode: TType;
  payload: TPayload;
};
export type WithCommonResponsePayload<T> = T | CommonResponse["payload"];

export type FirebaseFilter = {
  fieldPath: string | firebaseAdmin.firestore.FieldPath;
  opStr: firebaseAdmin.firestore.WhereFilterOp;
  value: any;
};

export type BasePaginationRequest = {
  cursor?: string;
  limit: number;
  options?: {
    order?: "desc" | "asc";
    filter?: FirebaseFilter | FirebaseFilter[];
    query?: firebaseAdmin.firestore.Query;
  };
};

export type BasePaginationResponse<T> = {
  nextCursor?: string;
  page: T[];
  hasMore?: boolean;
};

export type GetResponse<T> = ServiceResponse<StatusCode.OK, T> | CommonResponse;

export type CreateResponse<T> =
  | ServiceResponse<StatusCode.Created, T>
  | CommonResponse;
export type UpdateResponse<T> =
  | ServiceResponse<StatusCode.OK, T>
  | CommonResponse;
export type DeleteResponse<T> =
  | ServiceResponse<StatusCode.OK, T>
  | CommonResponse;
type NotFoundResponse = ServiceResponse<
  StatusCode.NotFound,
  { id: string; description: string }
>;
export type ServiceFailResponse = ServiceResponse<StatusCode.Failed, ErrorInfo>;
export type BadRequestResponse = ServiceResponse<
  StatusCode.BadRequest,
  ErrorInfo
>;

export type UnAuthorized = ServiceResponse<StatusCode.UnAuthorized, ErrorInfo>;

export type ErrorInfo = { error: Error | string; description: string };

export type CommonResponse =
  | NotFoundResponse
  | BadRequestResponse
  | ServiceFailResponse
  | UnAuthorized;
