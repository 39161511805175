import { RolodexConfiguration } from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { isString } from "lodash";
import { AppConfig } from "../../../../config/appConfig";
import { serviceBuilder } from "../../builders/api.builder";
import { serviceDomainConfigurationAdapter } from "./serviceDomainConfiguration.adapter";

export const baseDocumentConfigurationApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "serviceDomainConfigurationApi",
  tagTypes: ["ServiceDomainConfiguration", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "rolodex-configuration"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: serviceDomainConfigurationAdapter
  })
});

export const serviceDomainConfigurationApi =
  baseDocumentConfigurationApi.injectEndpoints({
    endpoints: (builder) => ({
      batchUpdate: builder.mutation<
        { batch: string[] },
        { configurations: ({ id: string } & Partial<RolodexConfiguration>)[] }
      >({
        query: (r) => {
          return {
            url: `/update-batch`,
            method: "PUT",
            body: r
          };
        }
      }),
      search: builder.query<
        {
          results: EntityState<RolodexConfiguration>;
          hasMore: boolean;
          nextCursor: string | null;
          query: string;
        },
        { query: string; cursor?: string | null } | string
      >({
        query: (r) => {
          return {
            url: `/search?q=${isString(r) ? r : r.query}${
              !isString(r) && r.cursor ? `&cursor=${r.cursor}` : ""
            }`,
            method: "GET"
          };
        },
        transformResponse: (
          response: {
            results: RolodexConfiguration[];
            hasMore: boolean;
            nextCursor: string | null;
          },
          _,
          arg
        ) => {
          const adapter = serviceDomainConfigurationAdapter.addMany(
            serviceDomainConfigurationAdapter.getInitialState(),
            response.results
          );
          return {
            query: isString(arg) ? arg : arg.query,
            results: adapter,
            hasMore: response.hasMore,
            nextCursor: response.nextCursor
          };
        }
      })
    })
  });
