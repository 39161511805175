import {
  Asset,
  Deal,
  Party,
  Property,
  RolodexServiceProvider,
  Statement,
  StatusCode,
  Task
} from "@elphi/types";
import { useEffect } from "react";
import { OnChangeInput } from "../components/form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../components/form-builder/InputBuilder";
import { useElphiToast } from "../components/toast/toast.hook";
import { AppConfig } from "../config/appConfig";
import { useTaskHooks } from "./task.hooks";

type TaskFormState = {
  tasks: {
    [id: string]: { id: string } & Partial<Task>;
  };
  deals?: { [id: string]: { id: string } & Partial<Deal> };
  parties?: { [id: string]: { id: string } & Partial<Party> };
  properties?: { [id: string]: { id: string } & Partial<Property> };
  assets?: { [id: string]: { id: string } & Partial<Asset> };
  statements?: { [id: string]: { id: string } & Partial<Statement> };
  serviceProviders?: {
    [id: string]: { id: string } & Partial<RolodexServiceProvider>;
  };
};

export const useTaskFormHooks = () => {
  const { selectedTask, updateBatch: updateTaskBatch } = useTaskHooks();
  const { errorToast, successToast } = useElphiToast();

  useEffect(() => {
    selectedTask &&
      syncState({
        shouldSync: !!selectedTask,
        state: selectedTask,
        statePath: () => {
          return ["tasks", selectedTask.id];
        }
      });
  }, [selectedTask]);

  const updateTaskFormHandler = async (v: Partial<TaskFormState>) => {
    if (!v.tasks || !updateTaskBatch) return null;

    const tasks = Object.keys(v.tasks)
      .map((id) => {
        if (v.tasks) {
          return {
            ...v.tasks[id],
            id
          };
        }
      })
      .filter((v) => v !== undefined);
    if (!tasks.length) return null;
    return await updateTaskBatch({ tasks } as {
      tasks: ({
        id: string;
      } & Partial<Task>)[];
    }).then((r) => {
      if (r.status === StatusCode.OK) {
        successToast({
          title: "Tasks Updated",
          description: `total tasks updated: ${tasks.length}`
        });
      }
      if (r.status === StatusCode.BadRequest) {
        errorToast({
          title: "Failed to update batch",
          description: r.data.description
        });
      }
      return r;
    });
  };

  const { onChange, state, syncState } = useFormBuilderStateHandler({
    initialState: {
      tasks: {},
      deals: {},
      parties: {},
      properties: {},
      assets: {},
      statements: {},
      serviceProviders: {}
    } as TaskFormState,
    callback: updateTaskFormHandler,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  const taskOnChange = (v: OnChangeInput, p: { taskId?: string }) => {
    const selectedTaskId: string = p?.taskId || (selectedTask as Task)?.id;

    if (selectedTaskId) {
      onChange({
        value: v.value,
        fieldType: v.fieldType,
        fieldKey: ["tasks", selectedTaskId, ...v.fieldKey]
      });
    }
  };

  return {
    onChange,
    state,
    syncState,
    taskOnChange
  };
};
