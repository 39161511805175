import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
  Box,
  chakra,
  Spinner
} from "@chakra-ui/react";
import React, { FC, lazy, useCallback, useEffect, useMemo } from "react";
import { elphiTheme } from "../assets/themes/elphi.theme.default";
import { useNavigationHooks } from "../hooks/navigation.hooks";
import {
  NavigationItemDictionary,
  NavigationPath
} from "../shared/types/navigation.types";
import navigationHandler from "./utils/navigationUtils";
const LazyLoadedContent = lazy(() => import("./LazyLoadedContent"));
const CollapsedRow = chakra(Box, {
  baseStyle: {
    padding: "5px",
    zIndex: 1
    //background: elphiTheme.colors.light.bg.main
  }
});
const StickyHeader = chakra(Box, {
  baseStyle: {
    position: "sticky",
    zIndex: 3,
    paddingTop: "10px",
    marginTop: 0,
    top: -3,
    background: elphiTheme.colors.light.bg.main
  }
});
const Section = chakra(Box, {
  baseStyle: {
    padding: "5px"
    // scrollBehavior: "auto",
    //background: elphiTheme.colors.light.bg.main
  }
});

export type SectionProps = {
  index?: number;
  header?: JSX.Element | string;
  body: JSX.Element | string | undefined;
  isHidden?: boolean;
  extraBody?: React.ReactElement;
};

type ScrollableSectionsProps = {
  customKey: string;
  top?: React.ComponentProps<typeof Box>["top"];
  sections: SectionProps[];
};
const ScrollableFormSections = (props: ScrollableSectionsProps) => {
  return (
    <Section>
      {props.sections
        .filter((s) => !s.isHidden)
        .sort((s) => s.index || 1)
        .map((c, i) => {
          return (
            <Box key={`${i}-${props.customKey}`}>
              {c.header ? (
                <StickyHeader zIndex={4}>{c.header}</StickyHeader>
              ) : null}
              <CollapsedRow>{c.body}</CollapsedRow>
            </Box>
          );
        })}
    </Section>
  );
};

type AccordionSectionProps = ScrollableSectionsProps & {
  lazyLoad?: boolean;
  index?: AccordionProps["index"];
  defaultIndex?: number[];
  elphiView?: "accordion" | "form";
  navigationPath?: NavigationPath;
  customKey: string;
  excludeCollapse?: number[];
};

export const AccordionSections: FC<AccordionSectionProps> = (props) => {
  const { navigationPath, customKey, lazyLoad = true } = props;
  const {
    setTabsSections,
    updateTabsSections,
    navigationState,
    setExpandAll,
    setCollapseAll
  } = useNavigationHooks();
  const { getObjectAsDictionary } = navigationHandler();
  const navigationPathDictionary: NavigationItemDictionary =
    getObjectAsDictionary(navigationPath!);
  const { page, tab } = navigationPathDictionary;
  const expandedSections =
    navigationState?.tabsSections?.[page]?.[tab]?.[customKey] || undefined;

  useEffect(() => {
    if (
      !expandedSections ||
      expandedSections.length < props?.sections?.length
    ) {
      setTabsSections({
        navigationPathDictionary,
        index: customKey,
        selectedArray: props.defaultIndex || [],
        sectionLength: props.sections?.length || 0,
        excludeCollapse: props?.excludeCollapse || []
      });
      const isAllExpanded = navigationState?.isAllExpanded?.[page]?.[tab];

      if (isAllExpanded !== undefined) {
        isAllExpanded
          ? setExpandAll({ navigationPathDictionary })
          : setCollapseAll({ navigationPathDictionary });
      }
    }
  }, [props.defaultIndex, props.sections, props.excludeCollapse]);

  const onSelectItem = useCallback(
    (_, i: number) => {
      updateTabsSections({
        navigationPathDictionary,
        index: customKey,
        item: i
      });
    },
    [updateTabsSections, navigationPathDictionary, customKey]
  );

  const sortedSections = useMemo(
    () =>
      props.sections
        .filter((s) => !s.isHidden)
        .sort((a, b) => (a.index || 1) - (b.index || 1)),
    [props.sections]
  );
  return (
    <Accordion
      allowMultiple
      key={`${customKey}-accordion`}
      index={expandedSections?.selectedArray}
      reduceMotion={lazyLoad}
    >
      {sortedSections.map((c, i) => (
        <AccordionItem
          key={`${i}-${customKey}-accordionItem`}
          bgColor={"white"}
        >
          {({ isExpanded }) => (
            <>
              <Box
                position="sticky"
                top={props.top || "0"}
                zIndex={"4"}
                bgColor="white"
                onClick={() => onSelectItem(c, i)}
              >
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      {c.header}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
              </Box>
              {lazyLoad ? (
                <AccordionPanel pb={4} h="100%">
                  {isExpanded && (
                    <LazyLoadedContent fallback={<Spinner />}>
                      <Box>{c.body}</Box>
                      {!!c.extraBody && <Box>{c.extraBody}</Box>}
                    </LazyLoadedContent>
                  )}
                </AccordionPanel>
              ) : (
                <AccordionPanel pb={4} h="100%">
                  {c.body}
                  {!!c.extraBody && <Box>{c.extraBody}</Box>}
                </AccordionPanel>
              )}
            </>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export const ScrollableSections = (
  props: AccordionSectionProps & ScrollableSectionsProps
) => {
  return props.elphiView === "form" ? (
    <ScrollableFormSections {...props} />
  ) : (
    <AccordionSections {...props} />
  );
};
