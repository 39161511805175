import { createApi } from "@reduxjs/toolkit/query/react";
import { ElphiLocationResponse } from "../../../../components/task/integrations/property-report/propertyReport.type";
import { AppConfig } from "../../../../config/appConfig";
import { RootState } from "../../../store";
import { serviceBuilder } from "../../builders/api.builder";
import { taskTemplateEntityAdapter } from "../../task-template/taskTemplate.adapter";
export const taskTemplateSelector =
  taskTemplateEntityAdapter.getSelectors<RootState>(
    (state) => state.taskTemplate
  );

export const siteXApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "siteXApi",
  tagTypes: ["SiteX", "Integration", "Task"],
  baseQuery: serviceBuilder.baseQuery({
    route: "integration/property-report/site-x"
  }),
  endpoints: (builder) => ({
    createReport: builder.mutation<
      { locations: ElphiLocationResponse[] },
      {
        propertyId: string;
        taskId: string;
        location?: ElphiLocationResponse;
      }
    >({
      query: (r) => {
        return {
          url: `/create`,
          method: "POST",
          body: r
        };
      }
    }),
    retryOrderReport: builder.mutation<{ taskId: string }, { taskId: string }>({
      query: (r) => {
        return {
          url: `/retry`,
          method: "POST",
          body: r
        };
      }
    })
  })
});
