import { Box, Heading } from "@chakra-ui/react";
import { Property } from "@elphi/types";
import { WritableDraft } from "immer/dist/internal";
import { MutableRefObject } from "react";
import { NavigationPath } from "../../shared/types/navigation.types";
import { ScrollableSections } from "../ScrollableSections";
import { PropertyTableContainer } from "./table/v2/PropertyTableContainer";

type PropertyScrollableProps = {
  elementRef: MutableRefObject<null>;
  height?: number;
  selectedProperty?: WritableDraft<Property>;
  navigationPath?: NavigationPath;
  maxHeight?: number;
  minHeight?: number;
  userId?: string;
  dimensions?: any;
  dealId?: string;
  isDisabled?: boolean;
  pageSize?: number;
  snapshotId?: string;
};

export const PropertyScrollableSections = ({
  elementRef,
  height,
  maxHeight = 600,
  minHeight = 150,
  userId,
  selectedProperty,
  navigationPath,
  dimensions,
  dealId,
  isDisabled = false,
  pageSize = 10,
  snapshotId
}: PropertyScrollableProps) => {
  return (
    <ScrollableSections
      customKey="properties"
      sections={[
        {
          index: 0,
          header: <Heading>Properties</Heading>,
          body: (
            <Box
              overflow="scroll"
              resize="vertical"
              ref={elementRef}
              h={`${height}px`}
              maxH={`${maxHeight}px`}
              minH={`${minHeight}px`}
            >
              <PropertyTableContainer
                isDisabled={isDisabled}
                h={`${dimensions?.contentBox.height || height}px`}
                pageSize={pageSize}
                propertyId={selectedProperty ? selectedProperty?.id : undefined}
                dealId={dealId}
                userId={userId}
                snapshotId={snapshotId}
              />
            </Box>
          )
        }
      ]}
      navigationPath={navigationPath}
    />
  );
};

