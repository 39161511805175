import { Type } from "@sinclair/typebox";
import { BaseEntitySchema } from "./baseEntity.typebox";
import { OmitUpdateSchema } from "./service.typebox";
import { StringField } from "./utils.typebox";

export const PartyAssetFieldsSchema = Type.Object({
  partyId: StringField,
  assetId: StringField
});

export const PartyAssetUpdate = Type.Intersect([
  OmitUpdateSchema(BaseEntitySchema),
  Type.Required(PartyAssetFieldsSchema)
]);
