import { BaseInsurancePolicy, InsurancePolicyEntityType } from "@elphi/types";
import { PartialWithId } from "@elphi/types/services/service.types";
import { EntityState } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { isString } from "lodash";
import { AppConfig } from "../../../config/appConfig";
import { serviceBuilder } from "../builders/api.builder";
import { Builder } from "../builders/crud.endpoints.builder";
import { insurancePolicyAdapter } from "./insurancePolicy.adapter";

const buildInsurancePolicyEndpoints =
  <T extends BaseInsurancePolicy<InsurancePolicyEntityType>>() =>
  (builder: Builder) => ({
    get: builder.query<
      T,
      { id: string; entityType?: InsurancePolicyEntityType }
    >({
      query: ({ entityType, id }) => {
        const baseUrl = `/${id}`;
        return {
          url: entityType ? `${baseUrl}?entityType=${entityType}` : baseUrl,
          method: "GET"
        };
      }
    }),
    search: builder.query<
      {
        results: EntityState<BaseInsurancePolicy<InsurancePolicyEntityType>>;
        hasMore: boolean;
        nextCursor: string | null;
        query: string;
      },
      {
        query: string;
        cursor?: string;
        params?: {
          domainConfigurationId?: string;
          entityType?: InsurancePolicyEntityType;
        };
      }
    >({
      query: (r) => {
        const urlParts = [`/search?`, `query=${r.query}`];
        r.params?.entityType &&
          urlParts.push(`entityType=${r.params.entityType}`);
        r.params?.domainConfigurationId &&
          urlParts.push(
            `domainConfigurationId=${r.params.domainConfigurationId}`
          );
        r.cursor && urlParts.push(`cursor=${r.cursor}`);
        return {
          url: urlParts.join("&"),
          method: "GET"
        };
      },
      transformResponse: (
        response: {
          page: T[];
          hasMore: boolean;
          nextCursor: string | null;
        },
        _,
        arg
      ) => {
        const adapter = insurancePolicyAdapter.addMany(
          insurancePolicyAdapter.getInitialState(),
          response.page
        );
        return {
          query: isString(arg) ? arg : arg.query,
          results: adapter,
          hasMore: response.hasMore,
          nextCursor: response.nextCursor
        };
      }
    }),
    getAll: builder.query<
      { results: T[] },
      { entityType?: InsurancePolicyEntityType; params?: { dealId?: string } }
    >({
      query: (r) => {
        const queries: string[] = [];
        r.params?.dealId && queries.push(`dealId=${r.params.dealId}`);
        r.entityType && queries.push(`entityType=${r.entityType}`);
        const baseUrl = "/get-all";
        return {
          url: queries.length ? `${baseUrl}?${queries.join("&")}` : baseUrl,
          method: "GET"
        };
      }
    }),
    getBatch: builder.query<
      { batch: EntityState<BaseInsurancePolicy<InsurancePolicyEntityType>> },
      { entityType?: InsurancePolicyEntityType; ids: string[] }
    >({
      query: (payload) => {
        return {
          url: `/get-batch`,
          method: "POST",
          body: payload
        };
      },
      transformResponse: (response: { results: T[] }) => {
        const adapter = insurancePolicyAdapter.addMany(
          insurancePolicyAdapter.getInitialState(),
          response.results
        );

        return { batch: adapter };
      }
    }),
    deleteDealFromInsurancePolicy: builder.mutation<
      { id: string },
      { dealId: string; policyId: string }
    >({
      query: (r) => {
        return {
          url: `${r.policyId}/deal/${r.dealId}`,
          method: "PUT"
        };
      }
    }),
    batchUpdate: builder.mutation<
      { batch: string[] },
      {
        insurancePolicies: PartialWithId<
          BaseInsurancePolicy<InsurancePolicyEntityType>
        >[];
      }
    >({
      query: (r) => {
        return {
          url: `/update-batch`,
          method: "PUT",
          body: r
        };
      }
    }),
    deleteEntityFromInsurancePolicy: builder.mutation<
      { id: string },
      { entityId: string; policyId: string }
    >({
      query: (r) => {
        return {
          url: `${r.policyId}/entity/${r.entityId}`,
          method: "PUT"
        };
      }
    }),
    delete: builder.mutation<{ id: string }, string>({
      query: (id) => {
        return {
          url: `/${id}`,
          method: "DELETE"
        };
      }
    })
  });

export const insurancePolicyApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "insurancePolicyApi",
  tagTypes: ["insurancePolicy", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "insurance-policy"
  }),
  endpoints: buildInsurancePolicyEndpoints()
});
