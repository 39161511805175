// type Status = "active" | "disabled";

import { Type } from "@sinclair/typebox";
import {
  RolodexConfigurationFieldType,
  ServiceProviderEntityType
} from "../../entities";
import { BaseEntitySchema } from "../baseEntity.typebox";
import { OmitCreateSchema, OmitUpdateSchema } from "../service.typebox";
import {
  BooleanField,
  EnumField,
  StringField,
  SwitchField
} from "../utils.typebox";

const RolodexEntityFields = Type.Record(
  Type.String(),
  Type.Object({
    fieldPath: StringField,
    isRequired: BooleanField
  })
);

const fieldType = Object.values(RolodexConfigurationFieldType);
const EntitiesFieldSelection = Type.Object(
  Object.fromEntries(fieldType.map((f) => [f, RolodexEntityFields]))
);

const RolodexConfigurationSchema = Type.Object({
  name: StringField,
  entityType: EnumField(ServiceProviderEntityType),
  fields: Type.Partial(EntitiesFieldSelection),
  status: SwitchField
});

export const RolodexConfigurationCreate = Type.Intersect([
  OmitCreateSchema(BaseEntitySchema),
  Type.Required(RolodexConfigurationSchema)
]);

export const RolodexConfigurationUpdate = Type.Intersect([
  OmitUpdateSchema(BaseEntitySchema),
  Type.Required(RolodexConfigurationSchema)
]);
