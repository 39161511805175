import { EntityState } from "@reduxjs/toolkit";
// Need to use the React-specific entry point to allow generating React hooks
import { PartyAsset } from "@elphi/types";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import { serviceBuilder } from "../builders/api.builder";
import { partyAssetEntityAdapter } from "./partyAssetRelation.adapter";
// Define a service using a base URL and expected endpoints
export const partyAssetBaseApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "partyAssetApi",
  tagTypes: ["partyAsset", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "party-asset"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: partyAssetEntityAdapter
  })
});

export const partyAssetApi = partyAssetBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    search: builder.query<EntityState<PartyAsset>, string>({
      query: (id) => {
        return {
          url: `/${id}`,
          method: "GET"
        };
      },
      transformResponse: (response: PartyAsset[]) => {
        const adapter = partyAssetEntityAdapter.addMany(
          partyAssetEntityAdapter.getInitialState(),
          response
        );
        return adapter;
      }
    }),
    batchDelete: builder.mutation<{ batch: string[] }, { relations: string[] }>(
      {
        query: (r) => {
          return {
            url: `/delete-batch`,
            method: "POST",
            body: r
          };
        }
      }
    ),
    createRelations: builder.mutation<
      PartyAsset[],
      { partyId: string; assetId: string }[]
    >({
      query: (r) => {
        return {
          url: "/create-relations",
          method: "POST",
          body: r
        };
      }
    })
  })
});
