import { Type } from "@sinclair/typebox";
import { StorageProvider } from "../storage";
import { BaseEntitySchema } from "./baseEntity.typebox";
import { OmitUpdateSchema } from "./service.typebox";
import { EnumField, StringField } from "./utils.typebox";

const StorageMetadataFieldsSchema = Type.Object({
  folderId: StringField,
  fileId: StringField
});

//TODO:
// create as union type for different vendors so we get destructive union when migrating to typebox static types
const DocumentMetadataFieldsSchema = Type.Object({
  provider: EnumField(StorageProvider),
  data: StorageMetadataFieldsSchema
});

export const DocumentMetadataUpdate = Type.Intersect([
  OmitUpdateSchema(BaseEntitySchema),
  Type.Required(DocumentMetadataFieldsSchema)
]);
