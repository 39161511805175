import { LabelValueWithStatus } from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { uniq, uniqBy, values } from "lodash";
import { useMemo } from "react";
import { useDealHooks } from "../../../hooks/deal.hooks";
import { useInsurancePolicyViewHooks } from "../../../hooks/insurance-policy/InsurancePolicyView.hooks";
import { coverageTypesOptiosMap } from "./dealInsurancePolicy.utils";

export const useInsurancePolicyContainerHooks = () => {
  const { insurancePolicyViewState, currentFilter, setCurrentFilter } =
    useInsurancePolicyViewHooks();
  const { selectedDeal } = useDealHooks();

  const coveragesOptions: LabelValueWithStatus[] = uniqBy(
    insurancePolicyViewState.ids.reduce((acc, id) => {
      const entity = insurancePolicyViewState.entities[id];

      if (!selectedDeal || !entity?.dealIds?.includes(selectedDeal.id)) {
        return acc;
      }

      const entityType = entity?.entityType;
      const coverageTypes = entity?.policy?.coverageTypes;

      if (entityType && coverageTypes) {
        const options = coverageTypes.map((c) => ({
          label: coverageTypesOptiosMap[entityType]?.[c] || c,
          value: c
        }));
        acc.push(...options);
      }

      return acc;
    }, [] as LabelValueWithStatus[]),
    (x) => x?.value
  );

  const insurancePolicyViews = useMemo(() => {
    return selectedDeal
      ? values(insurancePolicyViewState.entities)
          .filter((x) => x?.dealIds.includes(selectedDeal.id))
          .filter(removeEmpty)
      : [];
  }, [insurancePolicyViewState, selectedDeal]);

  const filteredItems = useMemo(() => {
    const configurations = new Set(currentFilter.domainConfigurations);
    const coverageTypes = new Set(currentFilter.coverageTypes);
    const statuses = new Set(currentFilter.statuses);

    if (!selectedDeal) {
      return [];
    }

    const filtered = insurancePolicyViews
      .map((entity) => {
        const { domainConfiguration, policy, keywords } = entity;
        const providersStatus = uniq(
          values(entity.providers).map((x) => x.status)
        ).filter(removeEmpty);
        const domainConfigurationId = domainConfiguration?.id;
        if (
          (!currentFilter.freeText ||
            keywords?.includes(currentFilter.freeText.toLowerCase())) &&
          (configurations.size === 0 ||
            (domainConfigurationId &&
              configurations.has(domainConfigurationId))) &&
          (statuses.size === 0 ||
            (providersStatus &&
              providersStatus.some((x) => statuses.has(x)))) &&
          (coverageTypes.size === 0 ||
            (policy.coverageTypes &&
              policy.coverageTypes.some((x) => coverageTypes.has(x))))
        ) {
          const domainConfigurationName = domainConfiguration?.name;
          return {
            ...entity,
            domainConfigurationName
          };
        }
        return undefined;
      })
      .filter(removeEmpty);

    return filtered;
  }, [
    currentFilter.freeText,
    currentFilter.domainConfigurations,
    currentFilter.statuses,
    insurancePolicyViews,
    selectedDeal
  ]);

  return {
    coveragesOptions,
    filteredItems,
    currentFilter,
    setCurrentFilter
  };
};
