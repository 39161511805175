import { ElphiSnapshotEntity } from "@elphi/types";
import { Dispatch, EntityId, Update } from "@reduxjs/toolkit";
import { buildAction } from "../builders/action.builder";
import { ActionDispatcher } from "../types/action.types";
import { snapshotSlice } from "./snapshot.slice";

export const snapshotAction: ActionDispatcher<ElphiSnapshotEntity> = (
  dispatch: Dispatch,
  callback?: {
    add?: (r: ElphiSnapshotEntity[]) => void;
    update?: (r: Update<ElphiSnapshotEntity>[]) => void;
    remove?: (r: EntityId[]) => void;
  }
) => {
  const slice = snapshotSlice;
  const actionDispatcher = buildAction(slice);
  return actionDispatcher(dispatch, callback);
};

