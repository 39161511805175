import { Box, Text } from "@chakra-ui/react";
import { EMPTY } from "../../../../../constants/common";
import { useRolodexConfiguration } from "../../../../../hooks/rolodexConfiguration.hooks";
import { useServiceProviderHooks } from "../../../../../hooks/rolodexServiceProvider.hooks";
import {
  DropDownIndicatorFirstControl,
  IndicatorsContainer,
  StyledInputBuilder
} from "../../../../form-builder/InputBuilder";
import { FieldType } from "../../../../form-builder/fieldFormat.types";

export const EntitiesStepContent = (props: {
  onChange: (v: string[]) => void;
  currentValue: string[];
}) => {
  const { optionsMap } = useServiceProviderHooks();
  const { selectedConfiguration } = useRolodexConfiguration();
  return (
    <Box>
      <Text pl={"3px"} pb={"3px"} fontWeight={"semibold"}>
        Entity Information
      </Text>
      <Box p="10px">
        <StyledInputBuilder
          label={"Search entities"}
          currentValue={props.currentValue}
          fieldType={FieldType.MultiSelect}
          options={
            optionsMap[
              selectedConfiguration ? selectedConfiguration.entityType : EMPTY
            ]
          }
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
          customComponent={{
            Control: DropDownIndicatorFirstControl,
            IndicatorsContainer: IndicatorsContainer
          }}
          chakraStyles={{
            valueContainer: (provided, _) => ({
              ...provided,
              p: 0,
              whiteSpace: "nowrap",
              fontSize: "12px",
              textOverflow: "ellipsis",
              maxHeight: "100px",
              overflowY: "auto"
            }),

            option: (p, _) => {
              return {
                ...p,
                borderWidth: "1px"
              };
            },
            indicatorSeparator: (provided, _) => ({
              ...provided,
              opacity: 0,
              p: 0
            }),
            dropdownIndicator: (prev, { selectProps: _ }) => ({
              ...prev,
              bgColor: "white"
            })
          }}
        />
      </Box>
    </Box>
  );
};
