import { AuditEventGroup, FirebaseFilter } from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import { serviceBuilder } from "../builders/api.builder";
import { auditEventGroupAdapter } from "./auditEventGroup.adapter";

export type AuditEventGroupPaginateV2Response = {
  nextCursor?: string;
  hasMore: boolean;
  page: AuditEventGroup[];
};

export const baseAuditEventGroupApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "auditEventGroupApi",
  tagTypes: ["auditEvent", "Notification", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "audit-event-group"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: auditEventGroupAdapter
  })
});

export const auditEventGroupApi = baseAuditEventGroupApi.injectEndpoints({
  endpoints: (builder) => ({
    updateBatch: builder.mutation<
      { batch: string[] },
      { groups: ({ id: string } & Partial<AuditEventGroup>)[] }
    >({
      query: (r) => {
        return {
          url: `/update-batch`,
          method: "PUT",
          body: r
        };
      }
    }),
    search: builder.query<
      {
        query: string;
        hasMore: boolean;
        nextCursor: string | null;
        results: EntityState<AuditEventGroup>;
      },
      { query: string; cursor?: string }
    >({
      query: (r) => {
        return {
          url: `/search?q=${r.query}${r.cursor ? `&cursor=${r.cursor}` : ""}`,
          method: "GET"
        };
      },
      transformResponse: (
        response: {
          results: AuditEventGroup[];
          nextCursor: string | null;
          hasMore: boolean;
        },
        _,
        arg
      ) => {
        const adapter = auditEventGroupAdapter.addMany(
          auditEventGroupAdapter.getInitialState(),
          response.results
        );
        return {
          query: arg.query,
          results: adapter,
          nextCursor: response.nextCursor,
          hasMore: response.hasMore
        };
      }
    }),
    paginateV2: builder.query<
      AuditEventGroupPaginateV2Response,
      {
        cursor?: string;
        options?: {
          order?: "desc" | "asc";
          filter?: FirebaseFilter | FirebaseFilter[];
        };
      }
    >({
      query: (r) => {
        return {
          url: `/paginate`,
          method: "POST",
          body: r
        };
      }
    }),
    getBatch: builder.query<{ batch: EntityState<AuditEventGroup> }, string[]>({
      query: (ids) => {
        return {
          url: `/get-batch`,
          method: "POST",
          body: { ids }
        };
      },
      transformResponse: (response: { results: AuditEventGroup[] }) => {
        const adapter = auditEventGroupAdapter.addMany(
          auditEventGroupAdapter.getInitialState(),
          response.results
        );

        return { batch: adapter };
      }
    })
  })
});
