import { EntityState } from "@reduxjs/toolkit";
// Need to use the React-specific entry point to allow generating React hooks
import { Asset, Party, PartyAsset, Statement } from "@elphi/types";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import { serviceBuilder } from "../builders/api.builder";
import { partyAssetEntityAdapter } from "../party-asset-relation/partyAssetRelation.adapter";
import { partyEntityAdapter } from "../party/party.adapter";
import { assetEntityAdapter } from "./asset.adapter";
// Define a service using a base URL and expected endpoints
export const assetBaseApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "assetApi",
  tagTypes: ["asset", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "asset"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: assetEntityAdapter
  })
});

export const assetApi = assetBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    search: builder.query<EntityState<Asset>, string>({
      query: (query) => {
        return {
          url: `/search?q=${query}`,
          method: "GET"
        };
      },
      transformResponse: (response: { results: Asset[] }) => {
        const adapter = assetEntityAdapter.addMany(
          assetEntityAdapter.getInitialState(),
          response.results
        );
        return adapter;
      }
    }),
    addAssetToParty: builder.mutation<
      {
        asset: Asset;
        partyId: string;
        relation: PartyAsset;
      },
      {
        partyId: string;
        asset: Asset;
      }
    >({
      query: (r) => {
        return {
          url: `add-asset`,
          method: "POST",
          body: { partyId: r.partyId, asset: r.asset }
        };
      }
    }),
    addStatementToAsset: builder.mutation<
      {
        statement: Statement;
        asset: Asset;
      },
      {
        statement: Statement;
        assetId: String;
      }
    >({
      query: (r) => {
        return {
          url: "add-statement",
          method: "POST",
          body: { assetId: r.assetId, statement: r.statement }
        };
      }
    }),
    getLatestStatements: builder.query<Statement[], { assetIds: string[] }>({
      query: (r) => {
        return {
          url: `get-latest-statements`,
          method: "POST",
          body: { assetIds: r.assetIds }
        };
      }
    }),
    getAssetStatements: builder.query<
      { statements: Statement[] },
      { assetIds: string[] }
    >({
      query: (r) => {
        return {
          url: `get-asset-statements`,
          method: "POST",
          body: { assetIds: r.assetIds }
        };
      }
    }),
    getBatchAssetParty: builder.query<
      { parties: EntityState<Party>; relations: EntityState<PartyAsset> },
      { assetIds: string[] }
    >({
      query: (query) => {
        return {
          url: `/get-asset-party-batch`,
          method: "POST",
          body: { assetIds: query.assetIds }
        };
      },
      transformResponse: (response: {
        results: { relations: PartyAsset[]; parties: Party[] };
      }) => {
        const adapter = partyEntityAdapter.addMany(
          partyEntityAdapter.getInitialState(),
          response.results.parties
        );
        const relationAdapter = partyAssetEntityAdapter.addMany(
          partyAssetEntityAdapter.getInitialState(),
          response.results.relations
        );
        return { parties: adapter, relations: relationAdapter };
      }
    }),
    batchUpdate: builder.mutation<
      { batch: string[] },
      { assets: ({ id: string } & Partial<Asset>)[] }
    >({
      query: (r) => {
        return {
          url: `/update-batch`,
          method: "PUT",
          body: r
        };
      }
    })
  })
});
