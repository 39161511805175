import { Box } from "@chakra-ui/react";
import {
  AggregationType,
  LeasedFinancedUnitKeys,
  Property
} from "@elphi/types";
import { values } from "lodash";
import { useEffect, useMemo } from "react";
import { AppConfig } from "../../config/appConfig";
import { getSpecs } from "../../forms/schemas/factories/specsFactory";
import { usePropertyFormHandler } from "../../hooks/propertyform.hooks";
import  {
  AggregationColumnTypes, AggregationFooter
} from "../application/AggregationFooter";
import { OnChangeInput } from "../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../form-builder/InputBuilder";
import { getFocusedAggregationSpecs } from "../form-builder/field-specs/utils/aggregation.utils";
import { ElphiTable } from "../table/ElphiTableComponent";
import { ElphiCellType, ElphiTableProps } from "../table/table.types";
import { TableInput } from "../utils/tableUtils";

export const LeasingTable = (props: {
  selectedProperty?: Property;
  isDisabled?: boolean;
  snapshotId?: string;
}) => {
  const cellMinWidth = "150px";
  const cellMaxWidth = "150px";
  const isLoading = false;

  const { selectedProperty } = props;
  const { updatePropertyHandler } = usePropertyFormHandler();
  const fieldsSpecs = getSpecs();
  const propertySpecFields = fieldsSpecs?.property?.entitySpecs;
  const { onChange, state, syncState } = useFormBuilderStateHandler({
    initialState: { properties: {} },
    callback: props.snapshotId ? undefined : updatePropertyHandler,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  useEffect(() => {
    if (selectedProperty) {
      syncState({
        shouldSync: !!selectedProperty,
        state: selectedProperty,
        statePath: () => {
          if (selectedProperty) {
            return ["properties", selectedProperty.id];
          }
        }
      });
    }
  }, [selectedProperty, props.snapshotId]);

  const propertyState =
    selectedProperty && state.properties[selectedProperty.id];

  const propertyOnChangeBatch = (v: OnChangeInput) => {
    !props.snapshotId &&
      selectedProperty &&
      !props.isDisabled &&
      onChange({
        fieldType: v.fieldType,
        fieldKey: ["properties", selectedProperty.id, ...v.fieldKey],
        value: v.value
      });
  };

  const leasingTableHeader: ElphiTableProps["header"] = useMemo(() => {
    const avaliableHeaders = new Set(
      values(propertySpecFields?.LeasedFinancedUnits?.unit0).map((v) => v.label)
    );

    const headers = [
      {
        index: 0,
        data: "Unit Name",
        type: ElphiCellType.String,
        maxWidth: cellMaxWidth,
        minWidth: cellMinWidth
      },
      {
        index: 1,
        data: "Contracted Monthly Rent",
        type: ElphiCellType.String,
        maxWidth: cellMaxWidth,
        minWidth: cellMinWidth
      },
      {
        index: 2,
        data: "Monthly Market Rent",
        type: ElphiCellType.String,
        maxWidth: cellMaxWidth,
        minWidth: cellMinWidth
      },
      {
        index: 3,
        data: "Unit Adjusted Rent Amount",
        type: ElphiCellType.String,
        maxWidth: cellMaxWidth,
        minWidth: cellMinWidth
      },
      {
        index: 3.5,
        data: "Lease Status",
        type: ElphiCellType.String,
        maxWidth: cellMaxWidth,
        minWidth: cellMinWidth
      },
      {
        index: 4,
        data: "Lease Expiration",
        type: ElphiCellType.String,
        maxWidth: cellMaxWidth,
        minWidth: cellMinWidth
      },
      {
        index: 5,
        data: "Rent Report Confidence Score",
        type: ElphiCellType.String,
        maxWidth: cellMaxWidth,
        minWidth: cellMinWidth
      },
      {
        index: 6,
        data: "Payment Verification",
        type: ElphiCellType.String,
        maxWidth: cellMaxWidth,
        minWidth: cellMinWidth
      },
      {
        index: 7,
        data: "Corporate Lease?",
        type: ElphiCellType.String,
        maxWidth: cellMaxWidth,
        minWidth: cellMinWidth
      }
    ].filter((header) => {
      if (!avaliableHeaders.has(header.data)) {
        console.log(`Header ${header.data} not found in available headers`);
      }
      return avaliableHeaders.has(header.data);
    }) as ElphiTableProps["header"];

    return headers;
  }, []);

  const leasingTableFullDataRows: ElphiTableProps["rows"] = useMemo(
    () =>
      (selectedProperty &&
        propertyState &&
        Object.keys(LeasedFinancedUnitKeys).map?.((unitKey) => {
          const cellsData: ElphiTableProps["rows"][0] = {
            index: unitKey,
            cells: [
              {
                index: 0,
                type: ElphiCellType.Element,
                data: (
                  <Box>
                    <TableInput
                      specFields={
                        propertySpecFields?.LeasedFinancedUnits?.[unitKey]
                          ?.UnitName
                      }
                      prefix={[]}
                      state={propertyState}
                      currentEntity={selectedProperty}
                      onChange={propertyOnChangeBatch}
                    />
                  </Box>
                ),
                maxWidth: "250px",
                minWidth: "250px"
              },
              {
                index: 1,
                type: ElphiCellType.Element,
                data: (
                  <Box>
                    <TableInput
                      specFields={
                        propertySpecFields?.LeasedFinancedUnits?.[unitKey]
                          ?.MonthlyLeaseRentAmount
                      }
                      prefix={[]}
                      state={propertyState}
                      currentEntity={selectedProperty}
                      onChange={propertyOnChangeBatch}
                    />
                  </Box>
                ),
                maxWidth: "400px",
                minWidth: "400px"
              },
              {
                index: 2,
                type: ElphiCellType.Element,
                data: (
                  <Box>
                    <TableInput
                      specFields={
                        propertySpecFields?.LeasedFinancedUnits?.[unitKey]
                          ?.MonthlyMarketRentAmount
                      }
                      prefix={[]}
                      state={propertyState}
                      currentEntity={selectedProperty}
                      onChange={propertyOnChangeBatch}
                    />
                  </Box>
                ),
                maxWidth: "400px",
                minWidth: "400px"
              },
              {
                index: 3,
                type: ElphiCellType.Element,
                data: (
                  <Box>
                    <TableInput
                      isAggregation={AggregationType.Aggregation}
                      specFields={getFocusedAggregationSpecs({
                        spec: propertySpecFields?.aggregations
                          ?.LeasedFinancedUnits?.[unitKey]?.AdjustedRentAmount,
                        focused:
                          propertyState?.aggregations?.LeasedFinancedUnits?.[
                            unitKey
                          ]?.AdjustedRentAmount?.focused
                      })}
                      prefix={[]}
                      state={propertyState}
                      currentEntity={selectedProperty}
                      onChange={propertyOnChangeBatch}
                    />
                  </Box>
                ),
                maxWidth: "400px",
                minWidth: "400px"
              },
              {
                index: 4,
                type: ElphiCellType.Element,
                data: (
                  <Box>
                    <TableInput
                      specFields={
                        propertySpecFields?.LeasedFinancedUnits?.[unitKey]
                          ?.LeaseStatusType
                      }
                      prefix={[]}
                      state={propertyState}
                      currentEntity={selectedProperty}
                      onChange={propertyOnChangeBatch}
                    />
                  </Box>
                ),
                maxWidth: "250px",
                minWidth: "250px"
              },
              {
                index: 5,
                type: ElphiCellType.Element,
                data: (
                  <Box>
                    <TableInput
                      specFields={
                        propertySpecFields?.LeasedFinancedUnits?.[unitKey]
                          ?.LeaseExpirationDate
                      }
                      prefix={[]}
                      state={propertyState}
                      currentEntity={selectedProperty}
                      onChange={propertyOnChangeBatch}
                    />
                  </Box>
                ),
                maxWidth: "250px",
                minWidth: "250px"
              },
              {
                index: 6,
                type: ElphiCellType.Element,
                data: (
                  <Box>
                    <TableInput
                      specFields={
                        propertySpecFields?.LeasedFinancedUnits?.[unitKey]
                          ?.RentReportConfidenceScore
                      }
                      prefix={[]}
                      state={propertyState}
                      currentEntity={selectedProperty}
                      onChange={propertyOnChangeBatch}
                    />
                  </Box>
                ),
                maxWidth: "250px",
                minWidth: "250px"
              },
              {
                index: 7,
                type: ElphiCellType.Element,
                data: (
                  <Box>
                    <TableInput
                      specFields={
                        propertySpecFields?.LeasedFinancedUnits?.[unitKey]
                          ?.PaymentVerificationIndicator
                      }
                      prefix={[]}
                      state={propertyState}
                      currentEntity={selectedProperty}
                      onChange={propertyOnChangeBatch}
                    />
                  </Box>
                ),
                maxWidth: "250px",
                minWidth: "250px"
              },
              {
                index: 8,
                type: ElphiCellType.Element,
                data: (
                  <Box>
                    <TableInput
                      specFields={
                        propertySpecFields?.LeasedFinancedUnits?.[unitKey]
                          ?.CorporateLeaseIndicator
                      }
                      prefix={[]}
                      state={propertyState}
                      currentEntity={selectedProperty}
                      onChange={propertyOnChangeBatch}
                    />
                  </Box>
                ),
                maxWidth: "250px",
                minWidth: "250px"
              }
            ]
          };
          return cellsData;
        })) ||
      [],
    [propertyState, propertyOnChangeBatch]
  );

  const leasingTableIsLoading = false;

  const leasingTableProps: ElphiTableProps = useMemo(() => {
    return {
      header: leasingTableHeader,
      rows: leasingTableFullDataRows,
      aggregationFooter: selectedProperty && propertyState && (
        <AggregationFooter
          columns={[
            {
              columnType: AggregationColumnTypes.Empty
            },
            {
              columnType: AggregationColumnTypes.Aggregation,
              input: (
                <Box>
                  <TableInput
                    labelPosition={"up"}
                    isAggregation={AggregationType.Aggregation}
                    specFields={getFocusedAggregationSpecs({
                      focused:
                        propertyState.aggregations?.MonthlyLeaseRentAmount
                          ?.focused || "calculated",
                      spec: propertySpecFields?.aggregations
                        ?.MonthlyLeaseRentAmount
                    })}
                    prefix={[]}
                    state={propertyState}
                    currentEntity={selectedProperty}
                    onChange={propertyOnChangeBatch}
                  />
                </Box>
              )
            },
            {
              columnType: AggregationColumnTypes.Aggregation,
              input: (
                <Box>
                  <TableInput
                    labelPosition={"up"}
                    isAggregation={AggregationType.Aggregation}
                    specFields={getFocusedAggregationSpecs({
                      focused:
                        propertyState.aggregations?.MonthlyMarketRentAmount
                          ?.focused || "calculated",
                      spec: propertySpecFields?.aggregations
                        ?.MonthlyMarketRentAmount
                    })}
                    prefix={[]}
                    state={propertyState}
                    currentEntity={selectedProperty}
                    onChange={propertyOnChangeBatch}
                  />
                </Box>
              )
            },
            {
              columnType: AggregationColumnTypes.Aggregation,
              input: (
                <Box>
                  <TableInput
                    labelPosition={"up"}
                    isAggregation={AggregationType.Aggregation}
                    specFields={getFocusedAggregationSpecs({
                      focused:
                        propertyState.aggregations?.AdjustedRentAmount
                          ?.focused || "calculated",
                      spec: propertySpecFields?.aggregations?.AdjustedRentAmount
                    })}
                    prefix={[]}
                    state={propertyState}
                    currentEntity={selectedProperty}
                    onChange={propertyOnChangeBatch}
                  />
                </Box>
              )
            },
            {
              columnType: AggregationColumnTypes.Empty
            },
            {
              columnType: AggregationColumnTypes.Empty
            },
            {
              columnType: AggregationColumnTypes.Empty
            },
            {
              columnType: AggregationColumnTypes.Empty
            },
            {
              columnType: AggregationColumnTypes.Empty
            }
          ]}
          width={"200px"}
        />
      ),
      isLoading: leasingTableIsLoading,
      rowsCount: 1
    };
  }, [leasingTableFullDataRows, isLoading]);

  return (
    <>
      <ElphiTable
        minHeight={"825px"}
        maxHeight={"825px"}
        header={leasingTableProps.header}
        rows={leasingTableProps.rows}
        aggregationFooter={leasingTableProps.aggregationFooter}
        rowsCount={1}
        isLoading={leasingTableProps.isLoading}
      />
    </>
  );
};

