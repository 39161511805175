import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";
import { DocumentPackageType } from "@elphi/types";
import { useEffect, useMemo, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { AddDocumentIcon, AddPackageIcon } from "../../assets/icons";
import { elphiTheme } from "../../assets/themes/elphi.theme.default";
import { auth } from "../../firebase/firebaseConfig";
import { useDealHooks } from "../../hooks/deal.hooks";
import { useDocumentPackageOrderHooks } from "../../hooks/documentPackageOrder.hooks";
import { RootState } from "../../redux/store";
import { usePrevious } from "../../utils/common";
import DocumentConfigurationSearch from "../document-management/document-configuration/DocumentConfigurationSearch";
import { StyledInputBuilder } from "../form-builder/InputBuilder";
import { FieldType } from "../form-builder/fieldFormat.types";
import { useElphiToast } from "../toast/toast.hook";

const ManualDocumentPackageOrderForm = (props: {
  dealId: string;
  options?: { label: string; value: string }[];
  onSuccess?: () => void;
}) => {
  const { createPackageOrder, createPackageOrderApiResponse } =
    useDocumentPackageOrderHooks();
  const { errorToast, successToast } = useElphiToast();
  const [documentConfigurations, setDocumentConfigurations] = useState<
    string[]
  >([]);

  useEffect(() => {
    setDocumentConfigurations([]);
  }, []);

  const createPackageOrderHandler = async (req: {
    type: DocumentPackageType;
    dealId: string;
    configurationIds: string[];
  }) => {
    const { type, dealId, configurationIds } = req;
    createPackageOrder({
      type,
      dealId: dealId,
      configurationIds: configurationIds
    })
      .then((r) => {
        if (r.status === 200) {
          successToast({
            title: "order created",
            description: "order created"
          });
          props?.onSuccess && props?.onSuccess();
        }
        r.status === 400 &&
          errorToast({
            title: "Failed to create order",
            description: r?.data?.description
          });
      })
      .catch((err) => {
        console.log("error document-package-order: ", err);
        errorToast({
          title: "Failed to generate documents",
          description: "something went wrong"
        });
      });
  };
  return (
    <>
      <DocumentConfigurationSearch
        filter={(v) => v.status === "active"}
        fieldType={FieldType.MultiSelect}
        currentValue={documentConfigurations}
        onSelect={setDocumentConfigurations}
        label={"select documents"}
      />
      <Button
        {...elphiTheme.components.light.button.primary}
        mt="10px"
        onClick={() =>
          createPackageOrderHandler({
            dealId: props.dealId,
            configurationIds: documentConfigurations,
            type: DocumentPackageType.Manual
          })
        }
        isDisabled={documentConfigurations.length === 0}
        isLoading={createPackageOrderApiResponse.isLoading || false}
      >
        Submit
      </Button>
    </>
  );
};

const DocumentPackageOrderForm = (props: {
  dealId: string;
  options?: { label: string; value: string }[];
  onSuccess?: () => void;
}) => {
  const { createPackageOrder, createPackageOrderApiResponse } =
    useDocumentPackageOrderHooks();
  const { errorToast, successToast } = useElphiToast();
  const [documentPackageOrderType, setDocumentPackageOrderType] = useState<
    DocumentPackageType | undefined
  >(undefined);

  useEffect(() => {
    setDocumentPackageOrderType(undefined);
  }, []);

  const createPackageOrderHandler = async (req: {
    type: DocumentPackageType;
    dealId: string;
  }) => {
    const { type, dealId } = req;
    createPackageOrder({
      type,
      dealId: dealId,
      configurationIds: []
    })
      .then((r) => {
        if (r.status === 200) {
          successToast({
            title: "order created",
            description: "order created"
          });
          setDocumentPackageOrderType(undefined);
          props?.onSuccess && props?.onSuccess();
        }
        r.status === 400 &&
          errorToast({
            title: "Failed to create order",
            description: r?.data?.description
          });
      })
      .catch((err) => {
        console.log("error document-package-order: ", err);
        errorToast({
          title: "Failed to generate documents",
          description: "something went wrong"
        });
      });
  };

  return (
    <>
      <StyledInputBuilder
        options={[
          {
            label: DocumentPackageType.LongTerm,
            value: DocumentPackageType.LongTerm
          },
          {
            label: DocumentPackageType.ShortTerm,
            value: DocumentPackageType.ShortTerm
          }
        ]}
        label="Package Type"
        onChange={(e) => {
          setDocumentPackageOrderType(e.target.value);
        }}
        isValid={true}
        fieldType={FieldType.SingleSelect}
        currentValue={documentPackageOrderType}
      />
      <Button
        {...elphiTheme.components.light.button.primary}
        mt="10px"
        onClick={() =>
          documentPackageOrderType &&
          createPackageOrderHandler({
            type: documentPackageOrderType,
            dealId: props.dealId
          })
        }
        isDisabled={!documentPackageOrderType}
        isLoading={createPackageOrderApiResponse.isLoading || false}
      >
        Submit
      </Button>
    </>
  );
};

export const ManualGenerateOrderModal = (props: { dealId: string }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    getDealResponse,
    getDealPropertiesApiResponse,
    getDealPartiesApiResponse,
    getDealRelations
  } = useDealHooks();

  const documentConfigurationState = useSelector(
    (state: RootState) => state.documentConfiguration,
    shallowEqual
  );
  const previousDealId = usePrevious(props.dealId);

  const documentOptions = useMemo(() => {
    return documentConfigurationState.ids.map((id) => {
      return {
        label: documentConfigurationState?.entities?.[id]?.name,
        value: documentConfigurationState?.entities?.[id]?.id
      };
    });
  }, [documentConfigurationState]);

  useEffect(() => {
    if (auth.currentUser && !!props.dealId && previousDealId !== props.dealId) {
      getDealResponse.isUninitialized &&
        getDealPropertiesApiResponse.isUninitialized &&
        getDealPartiesApiResponse.isUninitialized &&
        getDealRelations(props.dealId);
    }
  }, [auth.currentUser, props.dealId]);

  return (
    <Box>
      <Button
        leftIcon={<AddDocumentIcon />}
        {...elphiTheme.components.light.button.primary}
        onClick={onOpen}
      >
        Order Specific Documents
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minWidth={"600px"}>
          <ModalHeader>Create Manual Order</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ManualDocumentPackageOrderForm
              dealId={props.dealId}
              options={documentOptions}
              onSuccess={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export const AutoGenerateOrderModal = (props: { dealId: string }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box>
      <Button
        leftIcon={<AddPackageIcon />}
        {...elphiTheme.components.light.button.success}
        onClick={onOpen}
      >
        Order a Package
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minWidth={"600px"}>
          <ModalHeader>Select Package Type</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <DocumentPackageOrderForm
              dealId={props.dealId}
              options={[]}
              onSuccess={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
