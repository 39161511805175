import { Box } from "@chakra-ui/react";
import { PropertyCellData } from "@elphi/types";
import "react-datasheet/lib/react-datasheet.css";
import { elphiTheme } from "../../../assets/themes/elphi.theme.default";
import { DataSheetCellState } from "../../data-sheet/dataSheet.types";
import { OnChangeInput } from "../../form-builder/FormBuilder";
import { StyledInputBuilder } from "../../form-builder/InputBuilder";
import { FieldType } from "../../form-builder/fieldFormat.types";
import {
  longTermHeaderObject,
  portfolioTemplateToFieldTypes,
  shortTermHeaderObject,
  singleSelectFieldToOptions
} from "../utils/portfolio.utils";

// import { useRef } from "react";
// import ViewportList from "react-viewport-list";

const cellStateToColor: { [P in DataSheetCellState]: string } = {
  [DataSheetCellState.FollowUp]:
    elphiTheme.colors.light.portfolioAction.followup,
  [DataSheetCellState.Valid]: elphiTheme.colors.light.portfolioAction.valid,
  [DataSheetCellState.Denied]: elphiTheme.colors.light.portfolioAction.deny,
  [DataSheetCellState.None]: elphiTheme.colors.light.portfolioAction.none
};
export type SheetRendererProps = {
  data: PropertyCellData[][];
  children: React.ReactNode[];
  className: string;
  header: string[];
};
export const SheetRenderer = (props: SheetRendererProps) => {
  // const ref = useRef(null);

  return (
    <div className="data-grid-container">
      <table className="data-grid" style={{ padding: "10px" }}>
        <thead
          style={{
            position: "sticky",
            top: "-1px",
            padding: "10px",
            background: "#f5f5f5",
            zIndex: "3"
          }}
        >
          <tr>
            {props.header.map((v, k) => {
              return (
                <th
                  key={k}
                  className="cell"
                  style={{
                    padding: "10px",
                    background: "#f5f5f5",
                    color: "#999",
                    textAlign: "center",
                    whiteSpace: "nowrap"
                  }}
                >
                  {v}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {props.children}
          {/* <tr>
            <ViewportList ref={ref} items={props.children} itemMinSize={50}>
              {(item) => item}
            </ViewportList>
          </tr> */}
        </tbody>
      </table>
    </div>
  );
};

export type RowRendererProps = {
  children: React.ReactNode;
  row: number;
};
export const RowRenderer = (props: RowRendererProps) => {
  return (
    <tr>
      <td
        className="cell"
        style={{
          background: "#f5f5f5",
          color: "#999",
          padding: "10px",
          textAlign: "center"
          // cursor: "default"
        }}
      >
        {props.row + 1}
      </td>
      {props.children}
    </tr>
  );
};

export type CellRendererProps = {
  selected: boolean;
  cell: PropertyCellData;
  updated: boolean;
  isEditModeCell: boolean;
} & {
  onMouseDown: React.MouseEventHandler<Element>;
  onMouseOver: React.MouseEventHandler<Element>;
  onDoubleClick: React.MouseEventHandler<Element>;
  onContextMenu: React.MouseEventHandler<Element>;
} & {
  children: React.ReactNode;
} & {
  onChange: (v: OnChangeInput) => void;
};
export const CellRenderer = (props: CellRendererProps) => {
  const cellClassName = `cell ${props.selected ? "selected" : ""} ${
    props.updated ? "updated" : ""
  }`;
  return (
    <td
      className={cellClassName}
      onMouseDown={props.onMouseDown}
      onMouseOver={props.onMouseOver}
      //onDoubleClick={props.onDoubleClick} //double click reset the value which seems like a bad UX
      onContextMenu={props.onContextMenu}
      style={{
        background: cellStateToColor[props.cell.state],
        textAlign: "center",
        height: "100%",
        width: "100%",
        whiteSpace: "nowrap",
        padding: "5px"
      }}
    >
      {props.cell.override ? (
        <div
          style={{
            backgroundColor: "black",
            width: "100%",
            height: "10%",
            color: "white"
          }}
        >
          overridden
        </div>
      ) : null}

      {[
        FieldType.String,
        FieldType.Number,
        FieldType.Percentage,
        FieldType.Email,
        FieldType.Integer,
        FieldType.Money
      ].includes(portfolioTemplateToFieldTypes[props.cell.key]) && (
        <span className={"value-viewer"}>{props.children}</span>
      )}

      <Box w="250px">
        <Box w="200px" onMouseDown={(e) => e.stopPropagation()} float="right">
          {portfolioTemplateToFieldTypes[props.cell.key] === FieldType.Date && (
            <StyledInputBuilder
              fieldType={portfolioTemplateToFieldTypes[props.cell.key]}
              currentValue={
                props.cell.value
                  ? new Date(props.cell.value).toISOString().split("T")[0] // making sure we have the correct format...
                  : props.cell.value
              }
              isValid={props.cell.value > ""}
              onChange={(e) => {
                props.onChange({
                  fieldType: portfolioTemplateToFieldTypes[props.cell.key],
                  fieldKey: [props.cell.propertyId, ...props.cell.fieldPath],
                  value: e.target.value
                });
              }}
              label={
                longTermHeaderObject[props.cell.key].label ||
                shortTermHeaderObject[props.cell.key].label
              }
              isDisabled={!props.isEditModeCell}
            />
          )}
          {portfolioTemplateToFieldTypes[props.cell.key] ===
            FieldType.SingleSelect && (
            <StyledInputBuilder
              fieldType={portfolioTemplateToFieldTypes[props.cell.key]}
              currentValue={props.cell.value}
              isValid={props.cell.value > ""}
              onChange={(e) => {
                props.onChange({
                  fieldType: portfolioTemplateToFieldTypes[props.cell.key],
                  fieldKey: [props.cell.propertyId, ...props.cell.fieldPath],
                  value: e.target.value
                });
              }}
              label={
                longTermHeaderObject[props.cell.key].label ||
                shortTermHeaderObject[props.cell.key].label
              }
              options={singleSelectFieldToOptions[props.cell.key] || []}
              isDisabled={!props.isEditModeCell}
            />
          )}
        </Box>
      </Box>
    </td>
  );
};

export const valueRenderer = (cell: PropertyCellData) => {
  return cell.value;
};
