// Need to use the React-specific entry point to allow generating React hooks
import { Deal, DealUser, LOSUser, Party, PartyUser } from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../config/appConfig";
import { serviceBuilder } from "../builders/api.builder";
import { dealUserEntityAdapter } from "../deal-user-relation/dealUserRelation.adapter";
import { dealEntityAdapter } from "../deal/deal.adapter";
import { partyUserEntityAdapter } from "../party-user-relation/partyUserRelation.adapter";
import { partyEntityAdapter } from "../party/party.adapter";
import { losUserEntityAdapter } from "./losUser.adapter";

// Define a service using a base URL and expected endpoints
export const baseLOSUserApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "losUserApi",
  tagTypes: ["LOSUser", "Paginate"],
  baseQuery: serviceBuilder.baseQuery({
    route: "user"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: losUserEntityAdapter
  })
});

export const losUserApi = baseLOSUserApi.injectEndpoints({
  endpoints: (builder) => ({
    losStatus: builder.mutation<{ id: string }, LOSUser["losStatus"]>({
      query: (losStatus) => {
        return {
          url: `/los-status`,
          method: "PUT",
          body: { losStatus }
        };
      }
    }),
    stamp: builder.query<{ id: string }, void>({
      query: () => {
        return {
          url: `/stamp`,
          method: "GET"
        };
      }
    }),
    userDeals: builder.query<
      { relations: EntityState<DealUser>; deals: EntityState<Deal> },
      string[]
    >({
      query: (ids) => {
        return {
          url: `/user-deals`,
          method: "POST",
          body: { ids }
        };
      },
      transformResponse: (response: {
        results: { relations: DealUser[]; deals: Deal[] };
      }) => {
        const dealAdapter = dealEntityAdapter.addMany(
          dealEntityAdapter.getInitialState(),
          response.results.deals
        );
        const dealUserAdapter = dealUserEntityAdapter.addMany(
          dealUserEntityAdapter.getInitialState(),
          response.results.relations
        );
        return {
          relations: dealUserAdapter,
          deals: dealAdapter
        };
      }
    }),
    userParties: builder.query<
      { relations: EntityState<PartyUser>; parties: EntityState<Party> },
      string[]
    >({
      query: (ids) => {
        return {
          url: `/user-parties`,
          method: "POST",
          body: { ids }
        };
      },
      transformResponse: (response: {
        results: { relations: PartyUser[]; parties: Party[] };
      }) => {
        const partyAdapter = partyEntityAdapter.addMany(
          partyEntityAdapter.getInitialState(),
          response.results.parties
        );
        const partyUserAdapter = partyUserEntityAdapter.addMany(
          partyUserEntityAdapter.getInitialState(),
          response.results.relations
        );
        return {
          relations: partyUserAdapter,
          parties: partyAdapter
        };
      }
    }),
    getUserBatch: builder.query<{ users: EntityState<LOSUser> }, string[]>({
      query: (ids) => {
        return {
          url: `/get-batch`,
          method: "POST",
          body: { ids }
        };
      },
      transformResponse: (response: { results: LOSUser[] }) => {
        const adapter = losUserEntityAdapter.addMany(
          losUserEntityAdapter.getInitialState(),
          response.results
        );
        return { users: adapter };
      }
    }),
    search: builder.query<EntityState<LOSUser>, string>({
      query: (query) => {
        return {
          url: `/search?q=${query}`,
          method: "GET"
        };
      },
      transformResponse: (response: { results: LOSUser[] }) => {
        const adapter = losUserEntityAdapter.addMany(
          losUserEntityAdapter.getInitialState(),
          response.results
        );
        return { ...adapter };
      }
    })
  })
});
